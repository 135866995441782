import { Address } from '@frontastic-engbers/types/account/Address';
import { ShippingMethod } from '@frontastic-engbers/types/cart/ShippingMethod';
import { CheckoutData } from '@frontastic-engbers/types/engbers-custom';
import { CurrencyHelpers } from '../currencyHelpers';

export const getSelectedShippingAddress = (checkoutData: CheckoutData): Address | undefined => {
  let shippingAddress: Address | undefined;

  switch (checkoutData.shippingMethod) {
  case 'shippingAddress':
    shippingAddress = checkoutData.shippingAddress;
    break;
  case 'packStation':
    shippingAddress = checkoutData.packstationAddress;
    break;
  case 'store':
    shippingAddress = checkoutData.storeDeliveryAddress;
    break;
  default:
    shippingAddress = checkoutData.billingAddress;
    break;
  }

  return shippingAddress;
};

export const getShippingCostsByCountry = (shippingMethod?: ShippingMethod, country?: string) => {
  const shippingCosts = shippingMethod?.zoneRates?.find((rate) =>
    rate?.zone.obj.locations?.find((location) => location.country === country),
  );

  return shippingCosts?.shippingRates[0].price;
};

export const getFormattedShippingCostsByCountry = (shippingMethod: ShippingMethod, country?: string) => {
  const shippingCosts = getShippingCostsByCountry(shippingMethod, country);

  if (!shippingCosts) {
    return '';
  }

  return CurrencyHelpers.formatForCurrency(shippingCosts || {}, true);
};
