import { revalidateOptions, useAccount } from "../../";
import { fetchApiHub } from "../../lib/fetch-api-hub";
import useSWR from "swr";

export const useCardData = () => {
    const { account } = useAccount();
    const result = useSWR<{ barcodeImage: any }>(
        "/action/account/getCardBarcode",
        fetchApiHub,
        revalidateOptions
    );

    if (!result.data || !account) {
        return { loaded: false, cardData: null };
    }

    if (!account) {
        return null;
    }

    return {
        loaded: true,
        cardData: {
            cardId: account.cardId,
            barcodeImage: result.data.barcodeImage,
        },
    };
};
