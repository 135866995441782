export default {
  DE: 'Deutschland',
  AT: 'Österreich',
  NL: 'Niederlande',
  BE: 'Belgien',
  LU: 'Luxemburg',
  BG: 'Bulgarien',
  DK: 'Dänemark',
  EE: 'Estland',
  FI: 'Finnland',
  FR: 'Frankreich',
  GR: 'Griechenland',
  IE: 'Irland',
  IT: 'Italien',
  HR: 'Kroatien',
  LV: 'Lettland',
  LT: 'Litauen',
  MT: 'Malta',
  PL: 'Polen',
  PT: 'Portugal',
  RO: 'Rumänien',
  SE: 'Schweden',
  SK: 'Slowakei',
  SI: 'Slowenien',
  ES: 'Spanien',
  CZ: 'Tschechische Republik',
  HU: 'Ungarn',
  CY: 'Zypern',
};
