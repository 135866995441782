const LAST_SEEN_PRODUCTS_KEY = 'LAST-SEEN-PRODUCTS';

export const getLastSeenProductIds = (): string[] => {
  let productIds;

  try {
    const data = localStorage.getItem(LAST_SEEN_PRODUCTS_KEY) || '';
    productIds = JSON.parse(data);
  } catch {
    productIds = [];
  }

  return productIds;
};

export const addProductToLastSeen = (productId: string) => {
  let productIds = getLastSeenProductIds();

  productIds.unshift(productId);

  productIds = productIds.filter(
    (id, index) => productIds.findIndex(
      (value) => id === value,
    ) === index,
  ).slice(0, 8);

  localStorage.setItem(LAST_SEEN_PRODUCTS_KEY, JSON.stringify(productIds));
};
