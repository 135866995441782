import type { CurrentRefinementsConnectorParamsItem } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements';

const ATTRIBUTE_SIZING = 'variants.attributes.Sizing';
const ATTRIBUTE_ISXXL = 'attributes.IsXXL';

export const getRefinementCount = (items: CurrentRefinementsConnectorParamsItem[], attribute: string): number => {
  const refinementItem = items.find((item) => item.attribute === attribute);
  let refinementCount = refinementItem?.refinements?.length ?? 0;

  if (attribute === ATTRIBUTE_SIZING) {
    const onlyLargeSizesSelected = !!items.find((item) => item.attribute === ATTRIBUTE_ISXXL);

    if (onlyLargeSizesSelected) {
      ++refinementCount;
    }
  }

  return refinementCount;
};

export const getDistinctSelectedFacetCount = (items: CurrentRefinementsConnectorParamsItem[]) => {
  const sizeFilters = [ATTRIBUTE_SIZING, ATTRIBUTE_ISXXL];

  return new Set(items.map((item) => (sizeFilters.includes(item.attribute) ? 'attributes.Size' : item.attribute))).size;
};
