import React from 'react';
import { useRouter } from 'next/router';
import { IconCustom } from '@engbers/components';
export type BackButtonType = {
  classNames: string;
};
export const BackButton: React.FC<BackButtonType> = ({
  classNames
}) => {
  const router = useRouter();
  return <button className={classNames} onClick={() => router.back()} data-sentry-component="BackButton" data-sentry-source-file="index.tsx">
      <IconCustom width={20} icon="ArrowThinLeft" data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
    </button>;
};