import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { Address } from '@frontastic-engbers/types/account/Address';
import { LocationSearchState, PackstationInput, PackstationLocationResult } from '@frontastic-engbers/types/engbers-custom';
import { PackStationResult } from './packstation-result';
import styles from './packstation-results.module.scss';
interface IPackStationResults {
  usePackstation: {
    packstationState: LocationSearchState;
    setPackstationState: React.Dispatch<React.SetStateAction<LocationSearchState>>;
  };
  usePackstationInputs: {
    packstationInputs: {
      [key: string]: PackstationInput;
    };
    setPackstationInputs: React.Dispatch<React.SetStateAction<{
      [key: string]: PackstationInput;
    }>>;
  };
  shippingAddress: Address;
  select: (id: string, postNo: string, checked: boolean) => void;
  errorPostNo: string;
  labelPostNo: string;
  labelSelect: string;
}
export const PackStationResults: React.FC<IPackStationResults> = ({
  usePackstation,
  usePackstationInputs,
  shippingAddress,
  select,
  errorPostNo,
  labelPostNo,
  labelSelect
}) => {
  const {
    packstationState,
    setPackstationState
  } = usePackstation;
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const handleShowAll = () => {
    setPackstationState(prev => ({
      ...prev,
      showAll: true
    }));
  };
  return <>
      <div className={styles.locations}>
        {packstationState.locations && packstationState.locations.map((location: PackstationLocationResult, i: number) => {
        if (!packstationState.showAll && i >= 4) {
          return;
        }
        return <PackStationResult location={location} key={location.id} select={select} shippingAddress={shippingAddress} errorPostNo={errorPostNo} labelPostNo={labelPostNo} labelSelect={labelSelect} usePackstationInputs={usePackstationInputs} />;
      })}
      </div>
      {packstationState.locations.length > 4 && !packstationState.showAll && <span className={styles.showAll} onClick={handleShowAll}>{formatMessage({
        id: 'showMore'
      })}</span>}
    </>;
};