export const PinchHandIcon = () => <svg width="32" height="32" viewBox="0 0 903.9 1099.5" data-sentry-element="svg" data-sentry-component="PinchHandIcon" data-sentry-source-file="pinch-hand.tsx">
    <path style={{
    fill: '#112030'
  }} d="M819.4,397.9c-33.9,0-61.6,25.7-62.6,58.2l-2,66.3c-0.1,1.4-2.2,1.4-2.2,0l-5.3-106.1
	c-1.9-32.5-30.2-58.1-64.4-58.1c-33.9,0-61.6,25.7-62.6,58.1l-2,76.3c0,1.4-2.2,1.4-2.3,0l-5.2-96.1c-2-32.5-30.3-58.1-64.4-58.1
	c-33.9,0-61.6,25.7-62.6,58.2l-3,97.8c0,0.9-1.5,0.9-1.5,0l-13.5-406c-1-32.3-28.7-58.2-62.6-58.2c-33.8,0-61.2,25.9-61.7,58.2
	l-8.4,518.3c-23.4-34.1-51.3-65.2-83.3-92.3l-69-58.7c-27-23-66.7-26.6-97.9-9.1c-27.9,15.8-35,51.3-15.2,75.7l95,117.6
	c10.6,13.2,20,27.2,27.8,42.1c0,0,45.8,93.5,76.1,144.6c39.3,66.1,125.1,125.7,153.1,173.8c19.4,33.1,18,92.6,18,92.6H788
	c0,0,4.7-105.3,33-151c45.8-74,66.2-166.8,66.1-279.4c0-56.8-3.4-206.8-3.4-206.8C881.8,423.4,853.5,397.9,819.4,397.9z" data-sentry-element="path" data-sentry-source-file="pinch-hand.tsx" />
    <polygon style={{
    fill: '#112030'
  }} points="34.1,320.5 58.3,336.6 121.8,241.4 175.6,277.2 112.1,372.5 136.3,388.6 26.2,443 " data-sentry-element="polygon" data-sentry-source-file="pinch-hand.tsx" />
    <polygon points="290.4,153.8 266.2,137.7 202.6,232.9 148.8,197.1 212.4,101.8 188.2,85.7 298.3,31.3 " data-sentry-element="polygon" data-sentry-source-file="pinch-hand.tsx" />
  </svg>;