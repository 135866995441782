import React from 'react';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { ConfirmationBox } from '@engbers/components/confirmation-box';
import { useRouter } from 'next/router';
import { encode } from 'querystring';
interface IConfirmationBoxData {
  confirmationTitle?: string;
  textSize?: number;
  confirmationText?: string;
  buttons?: IConfirmationBoxButton[];
  isFullWidth?: boolean;
}
interface IConfirmationBoxButton {
  label: string;
  link: IFTLinkReference | IFTPageFolderReference;
}
interface IConfirmationBoxTasticComponent {
  data: IConfirmationBoxData;
}
export const ConfirmationBoxTastic: React.FC<IConfirmationBoxTasticComponent> = ({
  data
}) => {
  const {
    query
  } = useRouter();
  const parameters = new URLSearchParams(encode(query));
  const variables: Record<string, string> = {};
  for (const [parameter, value] of parameters) {
    variables[parameter] = value;
  }
  const forgotPasswordConfirm = <ConfirmationBox title={data.confirmationTitle} textSize={data.textSize} text={data.confirmationText} buttons={data.buttons} variables={variables} />;
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>{forgotPasswordConfirm}</FullPageWidthWrapper>;
  }
  return forgotPasswordConfirm;
};