import React from 'react';
import classnames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import styles from './voucher-card.module.scss';
type VoucherCardProps = {
  header?: React.ReactNode;
  voucherCode?: string;
  barcodeSvg?: string;
  onlinePin?: string;
  placeholder?: string;
  description?: string;
};
export const VoucherCard = ({
  header,
  barcodeSvg,
  voucherCode,
  onlinePin,
  placeholder,
  description
}: VoucherCardProps) => {
  const {
    formatMessage
  } = useFormat({
    name: 'cart'
  });
  return <div className={classnames(styles.card, {
    [styles.padding]: !voucherCode
  })} data-sentry-component="VoucherCard" data-sentry-source-file="index.tsx">
      {header}
      {barcodeSvg && <div className={styles.barcodeWrap} dangerouslySetInnerHTML={{
      __html: barcodeSvg
    }} />}
      {voucherCode && <div className={styles.voucherCode}>
          <div>{formatMessage({
          id: 'voucherCode'
        })}</div>
          <div>{voucherCode}</div>
        </div>}

      {placeholder && <div className={styles.voucherCode}>
          <div>{placeholder}</div>
        </div>}

      {onlinePin && <div className={styles.voucherCode}>
          <div>{formatMessage({
          id: 'onlinePin'
        })}</div>
          <div>{onlinePin}</div>
        </div>}

      {description && <div className={styles.description}>{description}</div>}

      <div className={styles.watermark}>engbers.com</div>
    </div>;
};