import { AccountBalance } from '@engbers/components';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
const EngbersAccountBalanceTastic = ({
  data
}) => {
  if (data?.isFullWidth) {
    return <FullPageWidthWrapper>
        <AccountBalance heading={data.headline} textSize={data.textSize} label={data.label} />
      </FullPageWidthWrapper>;
  }
  return <AccountBalance heading={data.headline} textSize={data.textSize} label={data.label} data-sentry-element="AccountBalance" data-sentry-component="EngbersAccountBalanceTastic" data-sentry-source-file="index.tsx" />;
};
export default EngbersAccountBalanceTastic;