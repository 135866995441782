import React, { useMemo } from 'react';
import classnames from 'classnames';
import { InfoWindow, Marker, useMarkerRef } from '@vis.gl/react-google-maps';
import { MELEVEN_BASE_URL } from '@frontastic-engbers/helpers/constants/meleven';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { getStoreLocationLink } from '@frontastic-engbers/helpers/utils/getStoreLocationLink';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import LocationResult from '@engbers/shop-backend/online-shop/models/LocationResult';
import { Button, Link } from '@engbers/components';
import styles from './store-marker.module.scss';
interface IInfoWindowMarker {
  store: LocationResult;
  active: boolean;
  setActive: (id: string, location?: {
    lat: number;
    lng: number;
  }) => void;
  assortments: Array<{
    assortmentId: string;
    icon: MediaType;
    name: string;
  }>;
  fallbackImage: MediaType;
  appointmentLabel: string;
  appointmentBtnColor: string;
  locationLabel: string;
  storeClosedHint: string;
}
export const StoreMarker: React.FC<IInfoWindowMarker> = ({
  store,
  active,
  setActive,
  assortments,
  fallbackImage,
  appointmentLabel,
  appointmentBtnColor,
  locationLabel,
  storeClosedHint
}) => {
  const {
    getTitle
  } = useImageSEO();
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const [markerRef, marker] = useMarkerRef();
  const position = {
    lat: store.latitude,
    lng: store.longitude
  };
  const mappedAssortments = useMemo(() => {
    const assortmentMap = assortments.reduce((acc, assortmentData) => {
      acc[assortmentData.assortmentId] = assortmentData;
      return acc;
    }, {});
    return store.assortment.split(',').map(assortment => {
      const additionalData = assortmentMap[assortment];
      return {
        name: additionalData?.name ?? assortment,
        icon: additionalData?.icon
      };
    });
  }, [assortments, store.assortment]);
  return <>
      <Marker title={store.name} ref={markerRef} position={position} icon={MELEVEN_BASE_URL + `w_20,h_20,o_resize${store.isClosed ? ',o_grayscale' : ''}/` + store.icon} onClick={() => setActive(store.id, position)} data-sentry-element="Marker" data-sentry-source-file="index.tsx" />
      {active && <InfoWindow anchor={marker} onCloseClick={() => setActive('')}>
          {store.image.length > 0 && <img src={MELEVEN_BASE_URL + store.image} alt={store.name} title={store.name} className={styles.storeImage} />}
          {!store.image.length && fallbackImage?.media?.file && <img src={fallbackImage?.media?.file} alt={getTitle(fallbackImage)} title={getTitle(fallbackImage)} />}
          <div className={styles.assortments}>
            {mappedAssortments.map(assortment => {
          if (assortment.icon?.media?.file) {
            return <img key={assortment.name} src={assortment.icon?.media?.file} title={assortment.name} alt={getTitle(assortment.icon)} className={styles.assortment} width={30} height={30} />;
          }
        })}
          </div>
          {store.bookingLink?.length > 0 && <span style={{
        '--storeBookingLinkBgColo': appointmentBtnColor
      } as React.CSSProperties}>
              {store.isClosed ? <Button href={store.bookingLink} target="_blank" label={appointmentLabel} color={appointmentBtnColor} size="large" customStyle={{
          marginBottom: '16px'
        }} disabled={store.isClosed} hasIcon={false} /> : <Link href={store.bookingLink} openInNewWindow className={classnames('btn', styles.storeBookingLink)}>
                  {appointmentLabel}
                </Link>}
            </span>}
          <div className={styles.storeInfo}>
            <div className={styles.mainStoreInfo}>
              <span>{store.name}</span>
              {store.addInfo?.length > 0 && <span>{store.addInfo}</span>}
              <span>
                {store.street} {store.streetNumber}
              </span>
              <strong>
                {store.zip} {store.city}
              </strong>
              <span className={styles.phone}>
                {formatMessage({
              id: 'phoneShort',
              defaultMessage: 'Telefon'
            })}
                : {store.phone}
              </span>
            </div>
            {store.isClosed && <span className={classnames(styles.openingHours, styles.isClosed)}>{storeClosedHint}</span>}
            {!store.isClosed && store.openingHours && <div className={styles.openingHours}>
                <span>
                  {formatMessage({
              id: 'openingHours',
              defaultMessage: 'Öffnungszeiten'
            })}
                  :{' '}
                </span>
                {store.openingHours.split('<br>').map((text: string, i: number) => <div key={`opening-hours-${i}`}>{text}</div>)}
              </div>}
          </div>
          <div className={styles.showLocation}>
            <a href={getStoreLocationLink(store)} target="_blank" rel="noreferrer">
              {locationLabel}
            </a>
          </div>
        </InfoWindow>}
    </>;
};