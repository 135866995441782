import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { Address } from '@frontastic-engbers/types/account/Address';
import { LocationSearchState } from '@frontastic-engbers/types/engbers-custom';
import { StoreResult } from './store-result';
import styles from './store-results.module.scss';
interface IStoreResults {
  useStoreDelivery: {
    storeDeliveryState: LocationSearchState;
    setStoreDeliveryState: React.Dispatch<React.SetStateAction<LocationSearchState>>;
  };
  shippingAddress: Address;
  select: (id: string, checked: boolean) => void;
  labelOpeningHours: string;
  labelSelect: string;
  storeClosedText: string;
}
export const StoreResults: React.FC<IStoreResults> = ({
  useStoreDelivery,
  shippingAddress,
  select,
  labelOpeningHours,
  labelSelect,
  storeClosedText
}) => {
  const {
    storeDeliveryState,
    setStoreDeliveryState
  } = useStoreDelivery;
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const handleShowAll = () => {
    setStoreDeliveryState(prev => ({
      ...prev,
      showAll: true
    }));
  };
  return <>
      <div className={styles.locations}>
        {storeDeliveryState.locations.length && storeDeliveryState.locations.map((location, i: number) => {
        if (!storeDeliveryState.showAll && i >= 4) {
          return;
        }
        return <StoreResult key={`${location.id}-${i}`} location={location} select={select} shippingAddress={shippingAddress} labelOpeningHours={labelOpeningHours} labelSelect={labelSelect} storeClosedText={storeClosedText} />;
      })}
      </div>
      {storeDeliveryState.locations.length > 4 && !storeDeliveryState.showAll && <span className={styles.showAll} onClick={handleShowAll}>
          {formatMessage({
        id: 'showMore'
      })}
        </span>}
    </>;
};