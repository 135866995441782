import React, { useEffect, useState } from 'react';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getLastSeenProductIds } from '@frontastic-engbers/helpers/utils/lastSeenProducts';
import { queryProductsByIds } from '@frontastic-engbers/lib/actions/product';
import { Product } from '@frontastic-engbers/types/product/Product';
import ProductSlider from '@engbers/components/online-shops/product-slider';
export type Props = {
  className?: string;
  title: string;
  textSize?: number;
  customTitleStyle?: object;
};
export const PdpLastSeenProducts: React.FC<Props> = ({
  className,
  title,
  textSize,
  customTitleStyle
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'product'
  });
  const lastSeenProductIds = getLastSeenProductIds();
  const [lastSeenProducts, setLastSeenProducts] = useState<Product[]>([]);
  useEffect(() => {
    if (lastSeenProductIds.length > 0) {
      queryProductsByIds(lastSeenProductIds).then(items => {
        setLastSeenProducts(items);
      });
    }
    // stringify with sorted ids to prevent unnecessary requests with identical ids
  }, [JSON.stringify([...lastSeenProductIds].sort())]);
  if (lastSeenProducts.length === 0) {
    return null;
  }
  return <div className={className} data-sentry-component="PdpLastSeenProducts" data-sentry-source-file="index.tsx">
      <ProductSlider products={lastSeenProducts} customTitleStyle={customTitleStyle} textSize={textSize} title={title || formatMessage({
      id: 'lastSeenHeadline',
      defaultMessage: 'Zuletzt angesehene Produkte'
    })} data-sentry-element="ProductSlider" data-sentry-source-file="index.tsx" />
    </div>;
};