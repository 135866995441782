import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useState as useGlobalState } from 'state-pool';
import { GlobalStateContext, useCart } from '@frontastic-engbers/lib';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { EcondaWidgetConfiguration, MediaType } from '@frontastic-engbers/types/engbers-custom';
import { handlePopState } from '@frontastic-engbers/helpers/utils/handlePopStateChange';
import { Image, Markdown, ProductFlags } from '@engbers/components';
import { TIconId } from '@engbers/components/icon-custom';
import Price from '@engbers/components/online-shops/price';
import { ITable } from '@engbers/components/online-shops/table';
import { TrustedShopsStars } from '@engbers/components/online-shops/trusted-shops/stars';
import { IAvailabilityModalContent } from '../index';
import { PdpSizes } from './pdp-sizes';
import { PdpColors } from './pdp-colors';
import { PdpAddToCart } from './pdp-add-to-cart';
import { SizeAdvisorModal } from './size-advisor-modal';
import { StoreAvailabilityModal } from './store-availability-modal';
import styles from './pdp-info.module.scss';
import imageStyles from '../pdp-images/pdp-images.module.scss';
import dynamic from 'next/dynamic';
export interface IPdpInfo {
  product: Product;
  variant: Variant;
  className?: string;
  sizeSection: {
    sizesLabel: string;
    sizesUnavailableMessage: string;
    sizesModalHeadline: string;
  };
  addToCartSection: {
    addToCartBtnLabel: string;
    shippingCostsNote: string;
    notAvailableModalText: string;
  };
  econdaWidgetConfiguration?: EcondaWidgetConfiguration;
  availabilityModalContent: IAvailabilityModalContent;
  categoryUrl?: string;
  sizeAdvisorLabel: string;
  sizeAdvisorTables: {
    sizeAdvisorTableProductGroup: string;
    sizeAdvisorImage: MediaType;
    sizeTables: ITable[];
  }[];
  sizeDeviationLabel: string;
  sizeDeviationIcon: TIconId;
  sizeDeviationTexts: {
    sizeDeviationProductGroup: string;
    sizeDeviationText: string;
  }[];
  outfit?: Outfit | any;
  notBuyableText: string;
  isEmilio?: boolean;
  fallbackImage?: MediaType;
}
const isProductOnStock = (product: Product, variant: Variant = undefined): boolean => {
  if (variant) {
    return (variant.isInStock && variant.quantity > 0) ?? false;
  }
  return product.variants.some(variant => {
    return (variant.isInStock && variant.quantity > 0) ?? false;
  });
};
const MediaQuery = dynamic(() => import('react-responsive'), {
  ssr: false
});
export const PdpInfo: React.FC<IPdpInfo> = ({
  product,
  variant,
  className,
  sizeSection,
  addToCartSection,
  econdaWidgetConfiguration,
  availabilityModalContent,
  categoryUrl,
  sizeAdvisorLabel,
  sizeAdvisorTables,
  sizeDeviationLabel,
  sizeDeviationIcon,
  sizeDeviationTexts,
  outfit,
  notBuyableText,
  isEmilio,
  fallbackImage
}) => {
  const [, setGlobalState] = useGlobalState(GlobalStateContext);
  const {
    pushModal
  } = useModalActions();
  const {
    data: cart,
    addItem
  } = useCart();
  const {
    pushNotification
  } = useToastNotificationsActions();
  const [loading, setLoading] = useState<boolean>(false);
  const variantSelected = useRef(false);
  const [selectedVariant, setSelectedVariant] = useState<Variant>(variant ?? null);
  const {
    formatMessage
  } = useFormat({
    name: 'product'
  });
  const {
    getTitle
  } = useImageSEO();
  const isVariantsOnStock = !!product.variants?.filter(item => item.isInStock && item.quantity > 0)?.length;
  const variantProductGroup: string = product.attributes?.ProductGroupWithoutPurchGroup;
  const variantGoodsGroup: string = product.attributes?.GoodsGroupWithoutPurchGroup;
  const hasAdvisorModal = sizeAdvisorTables?.find(sizeTables => sizeTables.sizeAdvisorTableProductGroup === variantGoodsGroup || sizeTables.sizeAdvisorTableProductGroup === variantProductGroup);
  const preventGiftCardBuy = product.attributes?.IsGiftcard && cart?.customLineItems?.length > 0;
  useEffect(() => {
    const inStockVariants = product.variants.filter(variant => variant.isInStock);
    if (inStockVariants.length === 1) {
      setSelectedVariant(inStockVariants[0]);
    }
  }, []);
  useEffect(() => {
    if (selectedVariant) {
      if (!variantSelected.current && variant.sku !== selectedVariant.sku) {
        variantSelected.current = true;
        setSelectedVariant(variant);
      } else {
        const correctVariant = product.variants.find(correctVariant => correctVariant?.sku === selectedVariant?.sku);
        setSelectedVariant(correctVariant ?? null);
      }
    }
  }, [selectedVariant, variant]);
  useEffect(() => {
    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);
  const handleAddToCart = async (sku: string = null) => {
    const breadcrumb = JSON.parse(localStorage.getItem('breadcrumb'));
    const currentVariant = selectedVariant || product.variants?.find((prodVariant: Variant) => sku === prodVariant.sku);
    const productCategoryUrl = categoryUrl ?? breadcrumb ? breadcrumb?.[breadcrumb?.length - 1]?.link : undefined;
    if (!currentVariant) {
      return;
    }
    setSelectedVariant(currentVariant);
    const cartItem = cart.lineItems.find(item => item.variant.sku === currentVariant.sku);
    if (!currentVariant.isInStock || cartItem && cartItem.count >= cartItem.variant.quantity) {
      return notAvailableSizesModal();
    }
    const addToCartSum = ItemMapper.convertCentAmount({
      fractionDigits: currentVariant?.price.fractionDigits,
      centAmount: currentVariant.discountedPrice ? currentVariant.discountedPrice.centAmount : currentVariant.price.centAmount,
      currencyCode: currentVariant?.price.currencyCode
    });
    new TagManager().addToCartEvent([ItemMapper.commercetoolsProductToItem(product, currentVariant, 1, true)], addToCartSum, 1).econdaCartEvent(product, currentVariant, 'econdaAddToCart', 'c_add').executePush();
    setLoading(true);
    addItem(currentVariant, 1).then(async response => {
      if (typeof response === 'string') {
        pushNotification(response, 'error');
        return;
      }
      setGlobalState({
        isFlyoutCartOpen: true,
        flyoutAlternativeProductsConfig: outfit?.productId ? outfit : econdaWidgetConfiguration ? {
          ...econdaWidgetConfiguration,
          sku: currentVariant.sku,
          categoryUrl: productCategoryUrl
        } : undefined
      });
    });
    setLoading(false);
  };
  const sizesModal = () => {
    if (!isProductOnStock(product, selectedVariant)) {
      return notAvailableSizesModal();
    }
    pushModal({
      title: <div className={styles.pushModalTitle}>
          {product.attributes?.IsGiftcard ? formatMessage({
          id: 'giftCardValueModalHeadline',
          defaultMessage: 'BITTE WÄHLEN SIE EINEN BETRAG AUS:'
        }) : sizeSection.sizesModalHeadline || formatMessage({
          id: 'sizesModalHeadline',
          defaultMessage: 'BITTE WÄHLEN SIE EINE GRÖSSE AUS:'
        })}
        </div>,
      id: 'sizes-modal',
      content: <PdpSizes sizeSection={sizeSection} product={product} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} isModal handleAddToCart={handleAddToCart} sizeDeviationLabel={sizeDeviationLabel} sizeDeviationIcon={sizeDeviationIcon} sizeDeviationTexts={sizeDeviationTexts} isGiftCard={product.attributes?.IsGiftcard} />,
      canCloseByBackdropClick: true
    });
  };
  const notAvailableSizesModal = () => {
    pushModal({
      title: '',
      id: 'not-available-sizes-modal',
      content: <Markdown text={addToCartSection.notAvailableModalText} className={styles.pushModalMarkdown} />,
      canCloseByBackdropClick: true
    });
  };
  const createSizeAdvisorModal = () => {
    pushModal({
      title: '',
      id: 'size_advisor_modal',
      content: <SizeAdvisorModal productGroup={variantProductGroup} goodsGroup={variantGoodsGroup} sizeAdvisorTables={sizeAdvisorTables} />,
      canCloseByBackdropClick: true,
      isLarge: true,
      disableBgScroll: true
    });
  };
  const createStoreAvailabilityModal = () => {
    pushModal({
      title: <div className={styles.availabilityModalTitle}>{availabilityModalContent.title}</div>,
      id: 'store_availability_modal',
      content: <StoreAvailabilityModal product={product} availabilityModalContent={availabilityModalContent} />,
      canCloseByBackdropClick: true,
      isLarge: true,
      disableBgScroll: true
    });
  };
  return <div className={classnames(className, 'relative')} data-sentry-component="PdpInfo" data-sentry-source-file="index.tsx">
      <div id="magnifyContainer" className={imageStyles.magnifyContainer} />

      {!product.attributes?.IsGiftcard && <PdpColors product={product} colorVariants={product.attributes.ColorVariants} className="md:hidden" fallbackImage={fallbackImage} />}

      <div className={styles.productInfo}>
        <MediaQuery minWidth={1024} data-sentry-element="MediaQuery" data-sentry-source-file="index.tsx">
          {product.flags && <ProductFlags flags={product.flags} className={styles.infoFlags} flagOrder={['sale', 'new']} />}
        </MediaQuery>
        <div className={styles.infoProductHeader}>
          <h1 className={styles.infoProductName}>
            {product.attributes?.DesignerName && !isEmilio && <span className={styles.infoManufacturer}>{product.attributes.DesignerName}</span>}
            <span className={styles.productName}>
              {product.name} {product.attributes.ColorName}
            </span>
          </h1>
          <MediaQuery minWidth={1024} data-sentry-element="MediaQuery" data-sentry-source-file="index.tsx">
            {product.flags && <ProductFlags flags={product.flags} flagOrder={['campaign', 'engbersGermany']} />}
          </MediaQuery>
        </div>

        <MediaQuery minWidth={768} data-sentry-element="MediaQuery" data-sentry-source-file="index.tsx">
          <TrustedShopsStars sku={product.productId} data-sentry-element="TrustedShopsStars" data-sentry-source-file="index.tsx" />
        </MediaQuery>

        {variant?.price && <div className="text-2xl-bold flex items-center gap-2">
            {!selectedVariant && product.attributes?.IsGiftcard ? <>
                {formatMessage({
            id: 'price.from',
            defaultMessage: 'ab'
          })}
                <Price price={variant.price} isBold />
              </> : <Price price={selectedVariant ? selectedVariant.price : variant.price} discountedPrice={selectedVariant ? selectedVariant.discountedPrice : variant.discountedPrice} isBold />}
          </div>}
      </div>

      <MediaQuery maxWidth={767} data-sentry-element="MediaQuery" data-sentry-source-file="index.tsx">
        <div className="mb-3">
          <TrustedShopsStars sku={product.productId} data-sentry-element="TrustedShopsStars" data-sentry-source-file="index.tsx" />
        </div>
      </MediaQuery>

      {!product.attributes?.IsGiftcard && <PdpColors product={product} colorVariants={product.attributes.ColorVariants} className="hidden md:block" fallbackImage={fallbackImage} />}

      <div className={styles.sizesWrapper}>
        <PdpSizes sizeSection={sizeSection} product={product} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} handleAddToCart={handleAddToCart} sizeDeviationLabel={sizeDeviationLabel} sizeDeviationIcon={sizeDeviationIcon} sizeDeviationTexts={sizeDeviationTexts} isGiftCard={product.attributes?.IsGiftcard} data-sentry-element="PdpSizes" data-sentry-source-file="index.tsx" />

        {isVariantsOnStock && !product.attributes?.IsGiftcard && <div className={styles.sizesInfo}>
            {availabilityModalContent.label && <button className={styles.storeAvailabilityBtn} onClick={createStoreAvailabilityModal}>
                {availabilityModalContent.icon?.media?.format === 'svg' && <Image width="10" src={availabilityModalContent.icon.media.file} alt={getTitle(availabilityModalContent.icon)} title={getTitle(availabilityModalContent.icon)} />}
                <span>{availabilityModalContent.label}</span>
              </button>}

            {sizeAdvisorLabel && hasAdvisorModal && <button className={styles.sizeAdvisorBtn} onClick={createSizeAdvisorModal}>
                {sizeAdvisorLabel}
              </button>}
          </div>}
      </div>
      <PdpAddToCart handleAddToCart={handleAddToCart} sizesModal={sizesModal} selectedVariant={selectedVariant} loading={loading} addToCartBtnLabel={addToCartSection.addToCartBtnLabel} shippingCostsNote={addToCartSection.shippingCostsNote} sku={variant.sku} product={product} preventGiftCardBuy={preventGiftCardBuy} notBuyableText={notBuyableText} data-sentry-element="PdpAddToCart" data-sentry-source-file="index.tsx" />
    </div>;
};