import React, { useCallback, useEffect, useState } from 'react';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { Block, Button, InputCheckbox } from '@engbers/components';
import { useNewsletter } from '@frontastic-engbers/lib/actions/account/useNewsletter';
import { Newsletter, NewsletterSubscription } from '@frontastic-engbers/types/newsletter/types';
import styles from './my-newsletter-subscription.module.css';
type AccountMyNewsletterSubscriptionProps = {
  emailAddress: string;
  newsletters: Newsletter[];
  subscriptionCtaText?: string;
  subscriptionInfoText?: string;
  subscriptionSuccessMessage?: string;
  subscriptionFailureMessage?: string;
};
export const AccountMyNewsletterSubscription: React.FC<AccountMyNewsletterSubscriptionProps> = ({
  emailAddress,
  newsletters,
  subscriptionInfoText,
  subscriptionSuccessMessage,
  subscriptionFailureMessage,
  subscriptionCtaText
}) => {
  const {
    pushNotification,
    removeNotification
  } = useToastNotificationsActions();
  const {
    updateSubscriptions,
    getSubscriptions
  } = useNewsletter();
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const {
    formatMessage: formatAccountMessage
  } = useFormat({
    name: 'account'
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationId, setNotificationId] = useState<string>();
  const [subscriptions, setSubscriptions] = useState<NewsletterSubscription[]>([]);
  const [checkedInputs, setCheckedInputs] = useState<Record<string, boolean>>({});
  const updateSuccessMessage = !subscriptionSuccessMessage || subscriptionSuccessMessage.trim().length === 0 ? formatAccountMessage({
    id: 'account.data.change',
    defaultMessage: 'Your data has been successfully updated.'
  }) : subscriptionSuccessMessage;
  const updateFailureMessage = !subscriptionFailureMessage || subscriptionFailureMessage.trim().length === 0 ? formatAccountMessage({
    id: 'account.data.change.failed',
    defaultMessage: 'Failed to update your data.'
  }) : subscriptionFailureMessage;
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedInputs({
      ...checkedInputs,
      [e.target.id]: e.target.checked
    });
  };
  const handleSubmit = useCallback(async () => {
    if (notificationId) {
      removeNotification(notificationId);
    }
    setLoading(true);
    let isSuccess = true;
    try {
      const result = await updateSubscriptions(Object.keys(checkedInputs).map(id => ({
        newsletterId: +id,
        isSubscribed: !!checkedInputs[id]
      })));
      isSuccess = result.success ?? false;
    } catch (error) {
      isSuccess = false;
    } finally {
      const subscriptionStatus = await getSubscriptions(newsletters);
      setSubscriptions(subscriptionStatus);
      setLoading(false);
      const newNotificationId = pushNotification(isSuccess ? updateSuccessMessage : updateFailureMessage, isSuccess ? 'success' : 'error', false);
      setNotificationId(newNotificationId);
    }
  }, [setLoading, checkedInputs, setNotificationId, notificationId]);
  useEffect(() => {
    return () => {
      if (notificationId) {
        removeNotification(notificationId);
      }
    };
  }, [notificationId, removeNotification]);
  useEffect(() => {
    if (subscriptions.length > 0) {
      const checked: Record<string, boolean> = {};
      for (const subscription of subscriptions) {
        checked[subscription.newsletterId] = subscription.isSubscribed;
      }
      setCheckedInputs(checked);
    }
  }, [subscriptions]);
  useEffect(() => {
    async function getSubscriptionStatus() {
      const subscriptionStatus = await getSubscriptions(newsletters);
      setSubscriptions(subscriptionStatus);
    }
    getSubscriptionStatus();
  }, []);
  if (subscriptions.length === 0) {
    return null;
  }
  return <form data-sentry-component="AccountMyNewsletterSubscription" data-sentry-source-file="index.tsx">
      <Block marginBottom={8} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <div className={styles.newsletterEmailWrap}>
          <span>{subscriptionInfoText ?? formatMessage({
            id: 'emailAddress'
          })}</span>
          <strong>{emailAddress}</strong>
        </div>
      </Block>

      <Block marginBottom={10} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <ul>
          {subscriptions.map(subscription => <li key={`nl-${subscription.newsletterId}`} className={styles.newsletterInputWrap}>
              <InputCheckbox id={`${subscription.newsletterId}`} name={subscription.newsletterName} label={newsletters.find(newsletter => newsletter.id === subscription.newsletterId).description ?? subscription.newsletterName} onChange={handleCheckboxChange} checked={checkedInputs[subscription.newsletterId] ?? false} hasHoverEffect={true} style="white" />
            </li>)}
        </ul>
      </Block>

      <Block data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <Button size="default" label={subscriptionCtaText ?? formatMessage({
        id: 'save.bold'
      })} isLoading={loading} customStyle={{
        textTransform: 'uppercase'
      }} onClick={handleSubmit} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
      </Block>
    </form>;
};