import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { CustomerSetCustomFieldAction } from '@commercetools/platform-sdk';
import { PAYONE_PAYMENTS } from '@frontastic-engbers/helpers/constants/payone';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useAccount, useCart } from '@frontastic-engbers/lib';
import { getCustomerPaymentMethods, setCustomerCustomFields } from '@frontastic-engbers/lib/actions/account';
import { getPaymentMethods as getPayonePaymentMethods } from '@frontastic-engbers/lib/actions/payone';
import { isCheckoutSubmittedState } from '@frontastic-engbers/lib/state/checkoutErrors/atoms';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { getLastUsedPaymentMethod } from '@frontastic-engbers/lib/actions/cart';
import { CheckoutData, CheckoutErrorsType, CustomerData, CustomerOrigin, IPaymentMethod, PaymentErrors, PaymentMethod as PaymentMethodType, PaymentType, PayonePaymentProductAvailability, Solvency } from '@frontastic-engbers/types/engbers-custom';
import { Address } from '@frontastic-engbers/types/account/Address';
import { IconCustom } from '@engbers/components';
import { PaymentMethod } from './payment-method';
import { PaymentInputs } from './payment-inputs';
import { SolvencyCheckModal } from './solvency-check-modal';
import { filterPaymentsWithSecurePayments, getPaymentMethods, mapPaymentMethods } from '../../utils/PaymentDecisionMaker';
import { useValidateAndRegisterUserCallback } from '../../hooks/useValidateUserDataCallback';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import styles from './payment.module.scss';
interface IPaymentSection {
  updateFormInput: (propName: string, newValue: string | Address | CustomerData | Solvency) => void;
  updateCartData: (validate: boolean, checkoutDataWithPayment?: CheckoutData) => Promise<void>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  shippingLoading: boolean;
  paymentSection: {
    headline: string;
    paymentMethods: IPaymentMethod[];
    paymentHintForGuest?: string;
    paymentHintLockedProducts?: string;
    paymentError: string;
    paymentDeniedError: string;
    paymentCreditCardAuthorized: string;
    paymentRedirectHeadline: string;
    paymentRedirectInfoText: string;
    paymentRedirectButtonLabel: string;
    solvencyPositiveMessage: string;
    solvencyFailedMessage: string;
    solvencyInvoiceMessage: string;
    solvencyDebitMessage: string;
    solvencyModalTitle: string;
    solvencyModalTitleProgess: string;
    solvencyModalCancelLabel: string;
    solvencyModalSubmitLabel: string;
  };
  checkoutData: CheckoutData;
  customerOrigin: CustomerOrigin;
  billingAddressSectionRef: HTMLDivElement;
  paymentSectionRef: HTMLDivElement;
  overviewSectionRef: HTMLDivElement;
  migrationVerifyInformation: string;
  migrationVerifyBirthdate: string;
  customerOriginDetermined: boolean;
  billingAddressPrefilled: boolean;
  checkoutErrors: CheckoutErrorsType;
  paymentErrors: Partial<PaymentErrors>;
  setPaymentErrors: React.Dispatch<React.SetStateAction<PaymentErrors>>;
  setTokenizationId: React.Dispatch<React.SetStateAction<string>>;
  setPaymentProductId: React.Dispatch<React.SetStateAction<number>>;
  resetPayment: () => void;
  isIframePayment: boolean;
  setIsIframePayment: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPaymentMethod: PaymentMethodType;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethodType>>;
  hasLockedProducts: boolean;
  setHasLockedProducts: React.Dispatch<React.SetStateAction<boolean>>;
}
export const Payment: React.FC<IPaymentSection> = ({
  updateFormInput,
  updateCartData,
  setLoading,
  loading,
  shippingLoading,
  paymentSection,
  checkoutData,
  customerOrigin,
  billingAddressSectionRef,
  paymentSectionRef,
  overviewSectionRef,
  migrationVerifyInformation,
  migrationVerifyBirthdate,
  customerOriginDetermined,
  billingAddressPrefilled,
  checkoutErrors,
  paymentErrors,
  setPaymentErrors,
  setTokenizationId,
  setPaymentProductId,
  resetPayment,
  isIframePayment,
  setIsIframePayment,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  hasLockedProducts,
  setHasLockedProducts
}) => {
  const {
    paymentMethods,
    paymentHintForGuest,
    paymentHintLockedProducts,
    solvencyPositiveMessage,
    solvencyFailedMessage,
    solvencyInvoiceMessage,
    solvencyDebitMessage,
    solvencyModalTitle,
    solvencyModalTitleProgess,
    solvencyModalCancelLabel,
    solvencyModalSubmitLabel
  } = paymentSection;
  const [isCheckoutSubmitted, setIsCheckoutSubmitted] = useRecoilState(isCheckoutSubmittedState);
  const [availablePayonePayments, setAvailablePayonePayments] = useState<PayonePaymentProductAvailability>();
  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState<PaymentMethodType[]>();
  const [solvencyStatus, setSolvencyStatus] = useState<number>(0);
  const [readyToPreselect, setReadyToPreselect] = useState<boolean>(false);
  const paymentInfoRef = useRef<HTMLDivElement>(null);
  const paymentInputsRef = useRef<HTMLDivElement>(null);
  const validateAndRegisterUser = useValidateAndRegisterUserCallback();
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    data: cart
  } = useCart();
  const {
    account,
    loggedIn
  } = useAccount();
  const {
    pushModal,
    removeModal
  } = useModalActions();
  const updateEcondaDataLayer = (ecContent: string, ecOrderProcess: string) => {
    const econdaDataLayer = JSON.parse(sessionStorage.getItem('econda_data_layer'));
    if (econdaDataLayer) {
      econdaDataLayer.ecContent = ecContent;
      econdaDataLayer.ecOrderProcess = ecOrderProcess;
      sessionStorage.setItem('econda_data_layer', JSON.stringify(econdaDataLayer));
      new TagManager().updateEcondaDataEvent(econdaDataLayer).executePush();
    }
  };
  const choosePayment = async (e: ChangeEvent<HTMLInputElement>, paymentType: string, canUsePayment: boolean) => {
    if (!e.target.checked) {
      return;
    }
    setPaymentErrors(null);
    updateEcondaDataLayer('Shop/Kaufprozess/Zahlungsoptionen', '3_Zahlungsoptionen');
    const selectedPaymentMethod = filteredPaymentMethods.find(paymentMethod => paymentMethod.type === paymentType);
    const solvencyCheckResult = checkoutData.solvency.result;
    if (selectedPaymentMethod.canUse === false && solvencyCheckResult !== 2) {
      if (canUsePayment) {
        setIsCheckoutSubmitted(true);
        const isValid = await validateAndRegisterUser(checkoutData, billingAddressSectionRef, updateFormInput, migrationVerifyInformation, migrationVerifyBirthdate, true, loading, checkoutErrors, customerOrigin, true);
        if (isValid) {
          pushSolvencyCheckModal(selectedPaymentMethod, 0, solvencyCheckResult);
        }
      }
      highlightInfoMessage();
      return;
    }
    setLoading(true);
    setSelectedPaymentMethod(selectedPaymentMethod);
    setIsCheckoutSubmitted(true);
    setIsIframePayment(false);
    setTokenizationId('');
    if (!(await validateAndRegisterUser(checkoutData, billingAddressSectionRef, updateFormInput, migrationVerifyInformation, migrationVerifyBirthdate, true, loading, checkoutErrors, customerOrigin))) {
      setLoading(false);
      if (checkoutData.shippingMethod === 'billingAddress') {
        updateEcondaDataLayer(`Shop/Kaufprozess/Kundendaten/${loggedIn ? 'BereitsKunde' : customerOrigin.isGuest ? 'OhneReg' : 'NeuesKonto'}`, `2_Kundendaten/${loggedIn ? 'BereitsKunde' : customerOrigin.isGuest ? 'OhneReg' : 'NeuesKonto'}`);
      }
      return;
    }
    await updateCartData(true, {
      ...checkoutData,
      paymentMethod: paymentType as PaymentType
    });
    if (PAYONE_PAYMENTS[paymentType]?.isIframePayment) {
      setIsIframePayment(true);
      paymentInputsRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
    updateEcondaDataLayer('Shop/Kaufprozess/Bestelluebersicht', '4_Bestelluebersicht');
    updateFormInput('paymentMethod', paymentType);
    setPaymentErrors(errors => ({
      ...errors,
      noPaymentSelected: false
    }));
    setIsCheckoutSubmitted(false);
    setLoading(false);
  };
  const solvencyCheck = async (selectedPaymentMethod: PaymentMethodType) => {
    setLoading(true);
    setIsCheckoutSubmitted(true);
    if (!(await validateAndRegisterUser(checkoutData, billingAddressSectionRef, updateFormInput, migrationVerifyInformation, migrationVerifyBirthdate, true, loading, checkoutErrors, customerOrigin))) {
      setSolvencyStatus(0);
      setLoading(false);
      return;
    }
    if (loggedIn) {
      await setCustomerCustomFields([{
        action: 'setCustomField',
        name: 'SolvencyQueryAccepted',
        value: true
      }]);
    }
    updateFormInput('solvency', {
      ...checkoutData.solvency,
      accepted: true
    });
    const date = new Date();
    const paymentMethodsTmp = await fetchPaymentMethods(paymentMethods, true);
    pushSolvencyCheckModal(selectedPaymentMethod, 1, 0, date);
    const paymentMethod = paymentMethodsTmp?.find(paymentMethod => paymentMethod.type === selectedPaymentMethod.type);
    let result = 1;
    if (paymentMethod?.canUse) {
      result = 2;
      setSelectedPaymentMethod(selectedPaymentMethod);
      pushSolvencyCheckModal(selectedPaymentMethod, 202, result, date);
      removeModal();
    } else {
      pushSolvencyCheckModal(selectedPaymentMethod, 405, result, date);
    }
    if (loggedIn) {
      const solvencyQueryUpdateActions: CustomerSetCustomFieldAction[] = [];
      solvencyQueryUpdateActions.push({
        action: 'setCustomField',
        name: 'SolvencyQuery',
        value: result
      });
      solvencyQueryUpdateActions.push({
        action: 'setCustomField',
        name: 'SolvencyQueryDate',
        value: date
      });
      await setCustomerCustomFields(solvencyQueryUpdateActions);
    }
    setIsCheckoutSubmitted(false);
    setLoading(false);
  };
  const pushSolvencyCheckModal = (selectedPaymentMethod: PaymentMethodType, status: number, result = 0, date?: Date) => {
    setSolvencyStatus(status);
    updateFormInput('solvency', {
      result,
      accepted: true,
      date
    });
    const checkInProgress = status === 1;
    pushModal({
      id: 'solvency-check-modal',
      title: result !== 1 ? checkInProgress ? solvencyModalTitleProgess : solvencyModalTitle : '',
      content: <SolvencyCheckModal solvencyCheck={solvencyCheck} solvencyStatus={status} solvencyCheckResult={result} cancelLabel={solvencyModalCancelLabel} submitLabel={solvencyModalSubmitLabel} selectedPaymentMethod={selectedPaymentMethod} solvencyFailedMessage={solvencyFailedMessage} solvencyInvoiceMessage={solvencyInvoiceMessage} solvencyDebitMessage={solvencyDebitMessage} />,
      isMedium: true
    });
  };
  const highlightInfoMessage = () => {
    if (!paymentInfoRef?.current || paymentInfoRef?.current?.classList.contains(styles.paymentInfoMessageHighlighted)) {
      return;
    }
    paymentInfoRef.current.classList.add(styles.paymentInfoMessageHighlighted);
    const timeOut = setTimeout(() => {
      paymentInfoRef.current.classList.remove(styles.paymentInfoMessageHighlighted);
      clearTimeout(timeOut);
    }, 1500);
  };
  const closePaymentInputs = () => {
    resetPayment();
    paymentSectionRef.scrollIntoView({
      behavior: 'smooth'
    });
  };
  const fetchPaymentMethods = async (paymentMethods: IPaymentMethod[], solvencyCheckAccepted = false): Promise<PaymentMethodType[]> => {
    let availablePayonePaymentsNew = availablePayonePayments;
    if (!availablePayonePayments) {
      availablePayonePaymentsNew = await getPayonePaymentMethods(cart?.sum?.currencyCode);
      setAvailablePayonePayments(availablePayonePaymentsNew);
    }
    const availablePaymentMethods = paymentMethods.filter(method => {
      return !(Object.keys(availablePayonePaymentsNew).includes(method.paymentMethodType) && !availablePayonePaymentsNew[method.paymentMethodType]);
    });
    const mappedPaymentMethods = mapPaymentMethods(availablePaymentMethods);
    try {
      const {
        filteredPaymentMethods,
        filterByWebservice,
        hasLockedProducts
      } = getPaymentMethods(mappedPaymentMethods, checkoutData, account, cart);
      setHasLockedProducts(hasLockedProducts);
      if (!filterByWebservice) {
        setFilteredPaymentMethods(filteredPaymentMethods);
        return;
      }
      const result = await getCustomerPaymentMethods(filteredPaymentMethods, checkoutData, account, checkoutData.solvency.accepted || solvencyCheckAccepted, customerOrigin, cart);
      setFilteredPaymentMethods(result.filteredPaymentMethods);
      if (checkoutData.solvency.accepted) {
        const paymentMethod = result.filteredPaymentMethods?.find((paymentMethod: PaymentMethodType) => paymentMethod.type === 'invoice');
        updateFormInput('solvency', {
          ...checkoutData.solvency,
          result: paymentMethod?.canUse ? 2 : 1
        });
      }
      return result.filteredPaymentMethods;
    } catch (e) {
      const filteredPaymentMethods = filterPaymentsWithSecurePayments(mappedPaymentMethods);
      setFilteredPaymentMethods(filteredPaymentMethods);
      return filteredPaymentMethods;
    }
  };
  const lineItemDependency = cart?.lineItems?.map(item => `${item.variant.sku}${item.count}`).join('');
  useEffect(() => {
    if (shippingLoading || !customerOriginDetermined || !cart?.lineItems || !cart?.sum || checkoutData.billingAddress.country === '' && checkoutData.shippingAddress.country === '' || loggedIn && !billingAddressPrefilled) {
      return;
    }
    const currentPaymentMethod = selectedPaymentMethod;
    setLoading(true);
    resetPayment();
    fetchPaymentMethods(paymentMethods).finally(async () => {
      const useCurrentPayment = !!currentPaymentMethod && filteredPaymentMethods.some(paymentMethod => paymentMethod.type === currentPaymentMethod.type);
      if (useCurrentPayment) {
        setSelectedPaymentMethod(currentPaymentMethod);
        setIsIframePayment(PAYONE_PAYMENTS[currentPaymentMethod.type]?.isIframePayment ?? false);
        updateFormInput('paymentMethod', currentPaymentMethod.type);
      }
      setLoading(false);
      setReadyToPreselect(true);
    });
  }, [shippingLoading, lineItemDependency, cart?.sum?.centAmount, checkoutData.shippingMethod, checkoutData.billingAddress.country, checkoutData.shippingAddress.country, customerOriginDetermined, billingAddressPrefilled]);
  useEffect(() => {
    if (!readyToPreselect || !filteredPaymentMethods) {
      return;
    }
    const getLastUsedFromUser = async (accountId: string) => {
      return await getLastUsedPaymentMethod(accountId);
    };
    const canUseLastPayment = (lastPayment: string) => {
      return filteredPaymentMethods.find(paymentMethod => paymentMethod.type === lastPayment && lastPayment !== 'creditCard' && paymentMethod.canUse);
    };
    const lastPayment = cart.payments?.at(-1);
    if (lastPayment && canUseLastPayment(lastPayment.paymentMethod)) {
      updateFormInput('paymentMethod', lastPayment.paymentMethod);
      return;
    }
    if (account?.accountId) {
      getLastUsedFromUser(account.accountId).then((lastPayment: string) => {
        if (lastPayment !== '' && canUseLastPayment(lastPayment)) {
          updateFormInput('paymentMethod', lastPayment);
        }
      });
    }
  }, [readyToPreselect]);
  useEffect(() => {
    if (isCheckoutSubmitted) {
      if (paymentErrors && Object.values(paymentErrors).some(error => error)) {
        resetPayment();
        paymentSectionRef.scrollIntoView({
          behavior: 'smooth'
        });
      }
      setIsCheckoutSubmitted(false);
    }
  }, [isCheckoutSubmitted]);
  if (!filteredPaymentMethods?.length) {
    return null;
  }
  return <>
      {checkoutData.isMigrationLogin && <div className="my-4 text-sm">
          {formatErrorMessage({
        id: 'migrationLoginPaymentHint',
        defaultMessage: `Ihre Daten wurden für die Bestellung in unserem System hinterlegt. - Ihre Kundennummer lautet: ${checkoutData.customerData.cardId}.  Bitte wählen Sie erneut eine Zahlart.`,
        values: {
          cardId: checkoutData.customerData.cardId
        }
      })}
        </div>}
      {solvencyStatus === 202 && <div className={styles.successMessage}>{solvencyPositiveMessage}</div>}
      {paymentErrors?.generalError && <div className={styles.paymentError}>{paymentSection.paymentError}</div>}
      {paymentErrors?.paymentDenied && <div className={styles.paymentError}>{paymentSection.paymentDeniedError}</div>}
      {paymentErrors?.noPaymentSelected && <div className={styles.paymentError}>
          {formatErrorMessage({
        id: 'selectedPaymentError',
        defaultMessage: 'Bitte wählen Sie eine Zahlart aus!'
      })}
        </div>}
      <div className={styles.paymentMethodsWrap}>
        {filteredPaymentMethods?.map((payment, index) => {
        if (!payment.isVisible) {
          return null;
        }
        return <PaymentMethod key={`payment--${index}`} payment={payment} solvency={checkoutData.solvency} customerOrigin={customerOrigin} checkoutData={checkoutData} onChoosePayment={choosePayment} />;
      })}
      </div>
      {isIframePayment && <PaymentInputs setLoading={setLoading} onConfirm={() => overviewSectionRef.scrollIntoView({
      behavior: 'smooth'
    })} onClose={closePaymentInputs} setErrors={setPaymentErrors} paymentInputsRef={paymentInputsRef} selectedPaymentMethod={selectedPaymentMethod} setTokenizationId={setTokenizationId} setPaymentProductId={setPaymentProductId} creditCardAuthorizedInfo={paymentSection.paymentCreditCardAuthorized} />}
      {customerOrigin.isGuest && paymentHintForGuest ? <div ref={paymentInfoRef} className={styles.paymentInfoMessage}>
          <IconCustom width={35} color="custom" icon="Info" customColor="#23527c" />
          {paymentHintForGuest}
        </div> : null}
      {hasLockedProducts && paymentHintLockedProducts ? <div ref={paymentInfoRef} className={styles.paymentInfoMessage}>
          <IconCustom width={35} color="custom" icon="Info" customColor="#23527c" />
          {paymentHintLockedProducts}
        </div> : null}
    </>;
};