import dynamic from 'next/dynamic';
import { useRecoilState } from 'recoil';
import { modalState } from '@frontastic-engbers/lib/state/modal/atoms';
import { Modal } from '../modal';
const AnimatePresence = dynamic(() => import('framer-motion').then(module => module.AnimatePresence));
export const ModalWrap = () => {
  const [modal, setModal] = useRecoilState(modalState);
  return <>
      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="index.tsx">
        {modal && <Modal hasOkayButton={modal.hasOkayButton} id={modal.id} content={modal.content} title={modal.title} onClose={modal.onClose} hasCloseButton={modal.hasCloseButton} canCloseByBackdropClick={modal.canCloseByBackdropClick} isLarge={modal.isLarge} isMedium={modal.isMedium} />}
      </AnimatePresence>
    </>;
};