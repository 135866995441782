import { useRouter } from 'next/router';
import classNames from 'classnames';
import styles from './pop-up.module.scss';
import { IconCustom } from '@engbers/components';
interface IPopUp {
  headlinePopUp: string;
  toCartBtn: string;
  continueShoppingBtn: string;
  removeModal: () => void;
}
export const PopUp: React.FC<IPopUp> = ({
  headlinePopUp,
  toCartBtn,
  continueShoppingBtn,
  removeModal
}) => {
  const router = useRouter();
  return <>
      <div className={styles.popUpWrap}>
        <div className={classNames(styles.checkMarkWrap)}>
          <IconCustom width={26} color={'white'} icon="CheckMarkThin" data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
        </div>
        <span className={styles.labelWrap}>{headlinePopUp}</span>
        <div className={styles.btnWrap}>
          <button onClick={() => {
          router.push('/cart').then(() => removeModal());
        }} className="cta w-full">
            {toCartBtn}
          </button>
          <button onClick={removeModal} className="primary w-full">
            {continueShoppingBtn}
          </button>
        </div>
      </div>
    </>;
};