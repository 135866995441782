import { Navigation } from '@engbers/components/navigation';
import { setNavigationTree } from '@frontastic-engbers/lib';
import { EcondaWidgetCredentials } from '@frontastic-engbers/types/engbers-custom';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
export const EmilioNavigationTastic = ({
  data
}) => {
  setNavigationTree(data.emilioTree);
  const econdaWidgetConfiguration: EcondaWidgetCredentials = data?.topSellerWidgetConfiguration?.dataSource?.credentials;
  return <FullPageWidthWrapper data-sentry-element="FullPageWidthWrapper" data-sentry-component="EmilioNavigationTastic" data-sentry-source-file="index.tsx">
      <Navigation categories={data.emilioTree} popularCategoriesLabel={data.popularCategoriesLabel} popularCategories={data.popularCategories} topSellerLabel={data.topSellerLabel} topSellerWidgetId={data.topSellerWidgetId} topSellerWidgetConfiguration={econdaWidgetConfiguration} suggestedProductsLabel={data.suggestedProductsLabel} suggestedProductsLimit={data.suggestedProductsLimit} suggestedCategoriesLabel={data.suggestedCategoriesLabel} allResultsLabel={data.allResultsLabel} algoliaConfig={data.config} isEmilio data-sentry-element="Navigation" data-sentry-source-file="index.tsx" />
    </FullPageWidthWrapper>;
};