import Head from 'next/head';
import Cart from '@engbers/components/online-shops/cart';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
const CartTastic = ({
  data
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'cart'
  });
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
        <title>
          {formatMessage({
          id: 'checkout',
          defaultMessage: 'checkout'
        })}
        </title>
        <meta name="description" content={formatMessage({
        id: 'checkout',
        defaultMessage: 'checkout'
      })} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      </Head>
      <Cart emptyCartText={data.emptyCart} continueShoppingLabel={data.continueShoppingLabel} ctaLabel={data.ctaLabel} ctaBgColor={data.ctaBgColor} ctaTextColor={data.ctaTextColor} cartTitle={data.cartTitle} productOverviewArticle={data.productOverviewArticle} productOverviewDesc={data.productOverviewDesc} productOverviewAmount={data.productOverviewAmount} productOverviewSum={data.productOverviewSum} voucherSection={{
      voucherInstruction: data.voucherInstruction,
      voucherHeadline: data.voucherHeadline,
      voucherInfo: data.voucherInfo,
      voucherRedeemBtn: data.voucherRedeemBtn,
      voucherRedeemedLabel: data.snippets?.dataSource?.snippetVoucherRedeemed,
      voucherInvalidLabel: data.snippets?.dataSource?.snippetVoucherErrorShort
    }} shippingTime={data.shippingTime} shippingInfo={data.shippingInfo} serviceShippingCostsNote={data.serviceShippingCostsNote} fallbackShippingCostsDe={data.shippingCostFallback.dataSource.shippingCostsDe} fallbackShippingCostsEu={data.shippingCostFallback.dataSource.shippingCostsEu} shippingCostsNote={data.shippingCostsNote} TSIcon={data.TSIcon} TSText={data.TSText} SSLIcon={data.SSLIcon} SSLText={data.SSLText} pathSizeSuggestion={data.pathSizeSuggestion} data-sentry-element="Cart" data-sentry-source-file="index.tsx" />
    </>;
};
export default CartTastic;