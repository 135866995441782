import { useCallback } from 'react';
import Toast from 'react-hot-toast';
import { useRouter } from 'next/router';
import { getProjectInfo, useAccount } from '@frontastic-engbers/lib';
import { userExists } from '@frontastic-engbers/lib/actions/account';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { AccountMigrationLoginResponse, NewsletterIdentifier } from '@frontastic-engbers/types/account/Account';
import { MigrationStepTwoModal } from '@engbers/components/migration-login-form/step-two-modal';
export const useValidateAndRegisterUserCallback = () => {
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    formatMessage: formatAccountMessage
  } = useFormat({
    name: 'account'
  });
  const {
    loggedIn,
    register
  } = useAccount();
  const {
    pushModal,
    removeModal
  } = useModalActions();
  const router = useRouter();
  const projectInfo = getProjectInfo();
  return useCallback(async (data, billingAddressSectionRef, updateFormInput, migrationVerifyInformation, migrationVerifyBirthdate, isDuplicateCheck, loading, checkoutErrors, customerOrigin, validationOnly = false) => {
    const onMigrationLoginSecondStep = async (response: AccountMigrationLoginResponse) => {
      if (response.status === 'SUCCESS') {
        updateFormInput('billingAddress', {
          ...data.billingAddress,
          salutation: response.registerFormState.salutation,
          firstName: response.registerFormState.firstName,
          lastName: response.registerFormState.lastName,
          streetName: response.registerFormState.addressStreetName,
          streetNumber: response.registerFormState.addressStreetNumber,
          city: response.registerFormState.addressCity,
          postalCode: response.registerFormState.addressPostalCode,
          country: response.registerFormState.addressCountry,
          ...(response.registerFormState.phoneNumber && {
            phone: response.registerFormState.phoneNumber
          }),
          ...(response.registerFormState.mobilePhoneNumber && {
            mobile: response.registerFormState.mobilePhoneNumber
          })
        });
        const birthDayDay = response.registerFormState.birthdayDay.toString();
        const birthDayMonth = response.registerFormState.birthdayMonth.toString();
        updateFormInput('customerData', {
          ...data.customerData,
          cardId: response.registerFormState.cardId,
          birthdayDay: birthDayDay.length == 1 ? `0${birthDayDay}` : birthDayDay,
          birthdayMonth: birthDayMonth.length == 1 ? `0${birthDayMonth}` : birthDayMonth,
          birthdayYear: response.registerFormState.birthdayYear,
          email: data.customerData.email,
          emailRepeat: response.registerFormState.email,
          agbAccepted: false,
          isMigrationUser: true,
          ...(response.registerFormState.title && {
            title: response.registerFormState.title
          })
        });
        updateFormInput('isMigrationLogin', true);
        updateFormInput('paymentMethod', null);
        billingAddressSectionRef.scrollIntoView({
          behavior: 'smooth'
        });
      }
    };
    if (!loggedIn) {
      if (!checkoutErrors) {
        removeModal();
        return false;
      }
      if (!checkoutErrors['billingAddress']?.isValid) {
        removeModal();
        checkoutErrors['billingAddress'].section.scrollIntoView({
          behavior: 'smooth'
        });
        return false;
      }
      if (!checkoutErrors['shippingAddress']?.isValid) {
        removeModal();
        updateFormInput('isUnselectedShippingAddress', true);
        checkoutErrors['shippingAddress'].section.scrollIntoView({
          behavior: 'smooth'
        });
        return false;
      }
      if (validationOnly) {
        return true;
      }
      if (!(await userExists(data.customerData.email))) {
        const newsletterSubscriptions: NewsletterIdentifier[] = [data.newsletter.enNewsletterAccepted ? 'Engbers_NL_Anmeldungen' : null, data.newsletter.emilioNewsletterAccepted ? 'Emilio_NL_Anmeldungen' : null, data.newsletter.xxlNewsletterAccepted ? 'XXL_NL_Anmeldungen' : null].filter((x): x is NewsletterIdentifier => x !== null);
        const response = await register({
          cardId: data.customerData.cardId,
          email: data.customerData.email,
          password: data.customerData.password,
          salutation: data.billingAddress.salutation,
          ...(data.customerData.title && {
            title: data.customerData.title
          }),
          firstName: data.billingAddress.firstName,
          lastName: data.billingAddress.lastName,
          birthdayYear: data.customerData.birthdayYear,
          birthdayMonth: data.customerData.birthdayMonth,
          birthdayDay: data.customerData.birthdayDay,
          streetName: data.billingAddress.streetName,
          streetNumber: data.billingAddress.streetNumber,
          postalCode: data.billingAddress.postalCode,
          city: data.billingAddress.city,
          country: data.billingAddress.country,
          additionalAddressInfo: '',
          additionalStreetInfo: '',
          state: '',
          ...(data.billingAddress.mobile && {
            mobilePhoneNumber: data.billingAddress.mobile
          }),
          ...(data.billingAddress.phone && {
            phoneNumber: data.billingAddress.phone
          }),
          acceptPhysicalMail: data.customerData.postSubmit,
          newsletterSubscriptions: newsletterSubscriptions,
          isMigrationUser: data.customerData.isMigrationUser,
          isGuest: customerOrigin?.isGuest,
          isCheckout: true,
          opcCustomer: true,
          isDuplicateCheck: isDuplicateCheck,
          solvency: data.solvency,
          shopId: projectInfo.projectId === 'en' ? '2' : '3'
        });
        if (response.status === 'USER_ALREADY_EXISTS') {
          removeModal();
          router.push({
            pathname: '/checkout/login',
            query: {
              cardId: response.cardId
            }
          }).then(() => Toast.error(formatAccountMessage({
            id: 'account.haveAlready',
            defaultMessage: 'You already have an account!'
          })));
          return false;
        }
        if (response.status === 'MIGRATION_LOGIN') {
          pushModal({
            id: 'migration-login-step-2',
            title: formatErrorMessage({
              id: 'securityQuery'
            }),
            content: <MigrationStepTwoModal loading={loading} onMigrationLoginSecondStepCallback={onMigrationLoginSecondStep} cardId={response.cardId ?? ''} migrationVerifyInformation={migrationVerifyInformation} migrationVerifyBirthdate={migrationVerifyBirthdate} />
          });
          return false;
        }
        if (response.status === 'NO_DUPLICATE_FOUND' || response.status === 'CREATED') {
          removeModal();
          return true;
        }
        if (response.status === 'UNKNOWN_ERROR') {
          removeModal();
          Toast.error(formatErrorMessage({
            id: 'wentWrong',
            defaultMessage: 'Sorry. Something went wrong..'
          }));
          return false;
        }
      }
    }
    if (!checkoutErrors['shippingAddress']?.isValid) {
      updateFormInput('isUnselectedShippingAddress', true);
      checkoutErrors['shippingAddress'].section.scrollIntoView({
        behavior: 'smooth'
      });
      return false;
    }
    return true;
  }, [loggedIn]);
};