import React from 'react';
import Image from 'next/image';
import classnames from 'classnames';
import { Link } from '@engbers/components';
import { CampaignFlag as Flag } from '@frontastic-engbers/types/product/Flag';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import styles from './campaign-flag.module.scss';
type Props = {
  flag: Flag;
  className?: string;
  width?: number;
  height?: number;
};
export const CampaignFlag: React.FC<Props> = ({
  flag,
  className,
  width = 37,
  height = 20
}) => {
  const {
    getTitle
  } = useImageSEO();
  if (!flag.flagImage?.media?.file) {
    return null;
  }
  const imageComponent = <div className={classnames(className, styles.productFlag, styles.campaignFlagImage)} title={flag?.description}>
      <Image loader={({
      src
    }) => src} src={flag.flagImage.media.file} alt={getTitle(flag.flagImage)} title={getTitle(flag.flagImage)} width={flag.flagImage.media.width ?? width} height={flag.flagImage.media.height ?? height} />
    </div>;
  return flag.link ? <Link reference={flag.link}>{imageComponent}</Link> : imageComponent;
};