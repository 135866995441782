import React, { useEffect, useState } from 'react';
import { useState as useGlobalState } from 'state-pool';
import md5 from 'crypto-js/md5';
import { GlobalStateContext, useCart } from '@frontastic-engbers/lib';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { handlePopState } from '@frontastic-engbers/helpers/utils/handlePopStateChange';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { Breadcrumb, Button, ShippingCostsTooltip, WishlistButton } from '@engbers/components';
import { MediaType, TBreadcrumb } from '@frontastic-engbers/types/engbers-custom';
import Price from '../price';
import { PdpImages } from '../product-details-page/pdp-images';
import { OutfitProduct } from './outfit-product';
import styles from './outfit-details-page.module.scss';
import { getEcommProductsIds, getEcommProductsTotalValue } from '@frontastic-engbers/helpers/dataLayerHelper/ecommProductsHelper';
import { MetaODP } from '@frontastic-engbers/helpers/metaTags';
import { useMediaQuery } from 'react-responsive';
export interface IOutfitDP {
  outfit: Outfit;
  goToProductLabel: string;
  sizesLabel: string;
  sizesUnselectedMessage: string;
  addToCartBtnLabel: string;
  shippingCostsNote: string;
  fallbackImage?: MediaType;
}
export const OutfitDetailsPage: React.FC<IOutfitDP> = ({
  outfit,
  goToProductLabel,
  sizesLabel,
  sizesUnselectedMessage,
  addToCartBtnLabel,
  shippingCostsNote,
  fallbackImage
}) => {
  const {
    addItem
  } = useCart();
  const [{}, setGlobalState] = useGlobalState(GlobalStateContext);
  const [breadcrumb, setBreadcrumb] = useState<TBreadcrumb[]>(null);
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  const [itemsToCart, setItemsToCart] = useState<{
    id?: string;
    name?: string;
    variant?: Variant & {
      prices: any;
    };
    validPurchase: boolean;
  }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastTrackedItems, setLastTrackedItems] = useState<string>('');
  useEffect(() => {
    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);
  useEffect(() => {
    if (outfit.products?.length > 0) {
      let outfitSum = 0;
      const datalayerItems = outfit.products?.map((outfitProduct: Product, index): any => {
        const variant = outfitProduct.variants.find(variant => variant.discountedPrice?.centAmount !== undefined || variant.price?.centAmount !== undefined);
        outfitSum += variant?.discountedPrice?.centAmount ?? variant?.price?.centAmount ?? 0;
        return ItemMapper.productToItem(outfitProduct, 1, index + 1, variant, false);
      });
      const currentTrackedItems = md5(JSON.stringify(datalayerItems)).toString();
      if (currentTrackedItems !== lastTrackedItems && datalayerItems.length > 0) {
        new TagManager().customEvent('ecomm', {
          ecomm_pagetype: 'product',
          ecomm_prodid: getEcommProductsIds(outfit.products),
          ecomm_totalvalue: getEcommProductsTotalValue(outfit.products)
        }).viewItemOutfitEvent(outfit.variants[0], outfitSum, datalayerItems, true).executePush();
        setLastTrackedItems(currentTrackedItems);
      }
    }
  }, [outfit.products]);
  useEffect(() => {
    let breadcrumbArray: TBreadcrumb[] = [];
    if (localStorage.getItem('breadcrumb')) {
      const localBreadcrumb = localStorage.getItem('breadcrumb');
      if (typeof localBreadcrumb !== 'undefined' && localBreadcrumb !== null) {
        breadcrumbArray = JSON.parse(localBreadcrumb);
        const index = breadcrumbArray.findIndex(item => item.active === true);
        breadcrumbArray[index].active = false;
        breadcrumbArray.push({
          pageFolderId: 'ODP',
          active: true,
          name: outfit.name,
          link: '/'
        });
        setBreadcrumb(breadcrumbArray);
      }
    }
  }, []);
  const handleAddToCart = async () => {
    if (!itemsToCart.length) {
      return;
    }
    let addToCartSum = 0;
    const datalayerItems = itemsToCart?.map((itemToCart: any, index): any => {
      const variant = itemToCart.variant;
      const product = outfit.products.find(product => product.productId === itemToCart.id);
      addToCartSum += variant.discountedPrice?.centAmount ?? variant.price?.centAmount ?? 0;
      return ItemMapper.productToItem(product, 1, index + 1, variant, false);
    });
    new TagManager().addToCartEvent(datalayerItems, ItemMapper.convertCentAmount({
      fractionDigits: 2,
      centAmount: addToCartSum,
      currencyCode: 'EUR'
    })).executePush();
    setLoading(true);
    for (const element of itemsToCart) {
      await addItem(element.variant, 1);
    }
    setGlobalState({
      isFlyoutCartOpen: true,
      // @ts-ignore
      flyoutAlternativeProductsConfig: outfit
    });
    setLoading(false);
  };
  const getPriceSum = () => {
    return itemsToCart.reduce((a, b) => {
      const priceValue = b.variant?.discountedPrice?.centAmount ?? b.variant?.price?.centAmount ?? 0;
      return a + priceValue;
    }, 0);
  };
  const addToCartSection = <div className="my-5 flex flex-col gap-1 sm:flex-row sm:gap-0">
      <Price price={{
      centAmount: getPriceSum(),
      currencyCode: 'EUR',
      fractionDigits: 2
    }} className={styles.odpAddToCartPrice} showCurrencySymbol />
      <div className="flex w-full">
        <Button type="cta" label={addToCartBtnLabel} className={styles.odpAddToCartBtn} disabled={!itemsToCart.length || !!itemsToCart.filter(item => !item.validPurchase).length} isLoading={loading} onClick={handleAddToCart} />
        <WishlistButton sku={outfit.productId} isCtaButton wrapperClassName={styles.odpWishlistWrapper} product={outfit} />
      </div>
    </div>;
  return <div className="py-5" data-sentry-component="OutfitDetailsPage" data-sentry-source-file="index.tsx">
      {breadcrumb && !isMobile && <Breadcrumb breadCrumbs={breadcrumb} paddingBottomInPix={10} />}
      <div className={styles.odpWrap}>
        <MetaODP outfit={outfit} data-sentry-element="MetaODP" data-sentry-source-file="index.tsx" />
        <PdpImages className={styles.odpImagesWrap} product={outfit} variant={outfit.variants[0]} isOutfit fallbackImage={fallbackImage} data-sentry-element="PdpImages" data-sentry-source-file="index.tsx" />
        <div className={styles.odpInfoWrap}>
          <div className={styles.odpHeading}>
            <h1>{outfit.name}</h1>
            <p>{outfit.description}</p>
          </div>

          {addToCartSection}

          <div style={{
          pointerEvents: loading ? 'none' : undefined
        }}>
            {outfit.products?.map((outfitProduct, index) => <OutfitProduct key={`outfit-product-${index}`} product={outfitProduct} goToProductLabel={goToProductLabel} sizesLabel={sizesLabel} sizesUnselectedMessage={sizesUnselectedMessage} itemsToCart={itemsToCart} setItemsToCart={setItemsToCart} fallbackImage={fallbackImage} />)}
          </div>

          <ShippingCostsTooltip className={styles.odpShippingCostsWrap} shippingCostsHint={shippingCostsNote} tooltipId="odp" data-sentry-element="ShippingCostsTooltip" data-sentry-source-file="index.tsx" />

          {addToCartSection}
        </div>
      </div>
    </div>;
};