import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { AccordionItem, Image, Markdown } from '@engbers/components/index';
import Column from './column';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { TrustedShopsBadge } from '@engbers/components/online-shops/trusted-shops/badge';
import { LinksCol2Type, LinksCol3Type, LinksCol4Type, LinksCol5Type, PartnersLinksType, PaymentsType } from '.';
import styles from './footer.module.scss';
export interface Props {
  headerCol1: string;
  textCol1: string;
  imagesCol1: {
    imageCol1: MediaType;
  }[];
  headerCol2: string;
  iconCol2: MediaType;
  linksCol2: LinksCol2Type[];
  headerCol3: string;
  iconCol3: MediaType;
  linksCol3: LinksCol3Type[];
  headerCol4: string;
  iconCol4: MediaType;
  linksCol4: LinksCol4Type[];
  partners: {
    partnersHeader: string;
    partnersIcon: MediaType;
    partnersLinks: PartnersLinksType[];
  }[];
  headerCol5: string;
  iconCol5: MediaType;
  linksCol5: LinksCol5Type[];
  TrustedShopImage: MediaType;
  TrustedShopText: string;
  paymentsHeader: string;
  paymentsIcon: MediaType;
  payments: PaymentsType[];
  copyRightsText: string;
  columns: number;
  isEmilio: boolean;
}
export const FooterSecondSection: React.FC<Props> = ({
  headerCol1,
  textCol1,
  imagesCol1,
  headerCol2,
  iconCol2,
  linksCol2,
  headerCol3,
  iconCol3,
  linksCol3,
  headerCol4,
  iconCol4,
  linksCol4,
  partners,
  headerCol5,
  iconCol5,
  linksCol5,
  TrustedShopImage,
  TrustedShopText,
  paymentsHeader,
  paymentsIcon,
  payments,
  copyRightsText,
  columns,
  isEmilio
}) => {
  const {
    getTitle
  } = useImageSEO();
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    maxWidth: 1024
  });
  const partnersFirstItems = partners.length ? partners[0] : undefined;
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  if (isMinWidthMedium) {
    return <div>
        {headerCol2 || linksCol2?.length ? <AccordionItem headerPadding={16} headerFontSize={16} borderColor="#E2E7EB" icon={iconCol2} label={headerCol2}>
            <Column className={classnames(styles.footerColumn, {
          [styles.emilioFooterColumn]: isEmilio
        })} header={headerCol2} links={linksCol2} columnCount={2} />
          </AccordionItem> : null}
        {headerCol3 || linksCol3?.length ? <AccordionItem headerPadding={16} headerFontSize={16} borderColor="#E2E7EB" icon={iconCol3} label={headerCol3}>
            <Column className={classnames(styles.footerColumn, {
          [styles.emilioFooterColumn]: isEmilio
        })} header={headerCol3} links={linksCol3} columnCount={3} />
          </AccordionItem> : null}
        {headerCol4 || linksCol4?.length ? <AccordionItem headerPadding={16} headerFontSize={16} borderColor="#E2E7EB" icon={iconCol4} label={headerCol4}>
            <Column className={classnames(styles.footerColumn, {
          [styles.emilioFooterColumn]: isEmilio
        })} partnerClassName={styles.footerPartner} header={headerCol4} links={linksCol4} columnCount={4} />
          </AccordionItem> : null}
        {headerCol5 || linksCol5?.length ? <AccordionItem headerPadding={16} headerFontSize={16} borderColor="#E2E7EB" icon={iconCol5} label={headerCol5}>
            <Column className={classnames(styles.footerColumn, {
          [styles.emilioFooterColumn]: isEmilio
        })} header={headerCol5} links={linksCol5} columnCount={5} />
          </AccordionItem> : null}
        {partnersFirstItems?.partnersHeader && partnersFirstItems?.partnersLinks.length ? <AccordionItem headerPadding={16} headerFontSize={16} borderColor="#E2E7EB" icon={partnersFirstItems.partnersIcon} label={partnersFirstItems.partnersHeader}>
            <Column className={classnames(styles.footerColumn, {
          [styles.emilioFooterColumn]: isEmilio
        })} header={partnersFirstItems.partnersHeader} links={partnersFirstItems.partnersLinks} columnCount={6} />
          </AccordionItem> : null}
        {payments && payments.length || paymentsHeader ? <AccordionItem headerPadding={16} headerFontSize={16} borderColor="#E2E7EB" icon={paymentsIcon} label={paymentsHeader}>
            <div className={styles.footerPaymentMethodsWrap}>
              <h5>{paymentsHeader}</h5>
              <ul role="list" className={styles.footerPaymentMethods}>
                {payments.map((payment, i) => <li key={`column-6-${i}`}>
                    {payment.paymentImage ? payment.paymentImage?.media?.format === 'svg' ? <Image src={payment.paymentImage?.media?.file} alt={getTitle(payment.paymentImage)} title={getTitle(payment.paymentImage)} width={26} /> : '?' : <strong className={styles.footerPaymentName}>{payment.paymentName}</strong>}
                  </li>)}
              </ul>
            </div>
          </AccordionItem> : null}
        <div className={classnames(styles.footerTrustedShop, styles.footerTrustedShopMobile)}>
          <TrustedShopsBadge trustedText={TrustedShopText} />
        </div>
        {copyRightsText ? <div className={styles.footerCopyRight}>
            <Markdown text={copyRightsText} disableMargin />
          </div> : null}
      </div>;
  }
  return <div className={styles.footerSecondSectionWrap} data-sentry-component="FooterSecondSection" data-sentry-source-file="footerSecondSection.tsx">
      <div className={styles.footerTopColumnsWrap}>
        {headerCol1 || imagesCol1?.length ? <div className={classnames(styles.footerFirstColumnWrap, styles.footerColumn, {
        [styles.emilioFooterColumn]: isEmilio
      })} style={{
        width: `${100 / columns}%`
      }}>
            <h5>{headerCol1}</h5>
            {textCol1 && <Markdown text={textCol1} disableMargin />}
            <ul role="list">
              {imagesCol1?.map((image, i) => <li key={`column-1-${i}`}>
                  <Image src={image.imageCol1?.media?.file} alt={getTitle(image.imageCol1)} title={getTitle(image.imageCol1)} width={60} />
                </li>)}
            </ul>
          </div> : null}
        <div className={styles.footerColumnsWrap} style={{
        width: headerCol1 || imagesCol1?.length ? `${100 - 100 / columns}%` : '100%'
      }}>
          {headerCol2 || linksCol2?.length ? <Column className={classnames(styles.footerColumn, {
          [styles.emilioFooterColumn]: isEmilio
        })} header={headerCol2} links={linksCol2} columnCount={2} /> : null}
          {headerCol3 || linksCol3?.length ? <Column className={classnames(styles.footerColumn, {
          [styles.emilioFooterColumn]: isEmilio
        })} header={headerCol3} links={linksCol3} columnCount={3} /> : null}
          {headerCol4 || linksCol4?.length ? <Column className={classnames(styles.footerColumn, {
          [styles.emilioFooterColumn]: isEmilio
        })} partnerClassName={styles.footerPartner} header={headerCol4} links={linksCol4} columnCount={4} partners={partners} /> : null}
          {headerCol5 || linksCol5?.length ? <Column className={classnames(styles.footerColumn, {
          [styles.emilioFooterColumn]: isEmilio
        })} header={headerCol5} links={linksCol5} columnCount={5} /> : null}
        </div>
      </div>
      <div className={styles.footerFullWidthColumnWrap}>
        <div className={styles.footerTrustedShop}>
          <TrustedShopsBadge trustedText={TrustedShopText} data-sentry-element="TrustedShopsBadge" data-sentry-source-file="footerSecondSection.tsx" />
        </div>
        <div className={styles.footerPaymentMethodsWrap}>
          <h5>{paymentsHeader}</h5>
          <ul role="list" className={styles.footerPaymentMethods}>
            {payments?.map((payment, i) => <li key={`column-6-${i}`}>
                {payment.paymentImage ? payment.paymentImage?.media?.format === 'svg' ? <Image src={payment.paymentImage?.media?.file} alt={getTitle(payment.paymentImage)} title={getTitle(payment.paymentImage)} width={26} /> : '?' : <strong className={styles.footerPaymentName}>{payment.paymentName}</strong>}
              </li>)}
          </ul>
        </div>
      </div>
    </div>;
};