import React from 'react';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { Typography } from '@engbers/components';
type Props = {
  condition: boolean;
  /**
   * message can either be the message that should be displayed
   * or an error translation (from public/locales/[lang]/error.json)
   */
  message: string;
};
export const InputError: React.FC<Props> = ({
  condition,
  message
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'error'
  });
  if (!condition) {
    return null;
  }
  return <Typography color="danger" variant="text-xs" data-sentry-element="Typography" data-sentry-component="InputError" data-sentry-source-file="index.tsx">
      {formatMessage({
      id: message
    })}
    </Typography>;
};