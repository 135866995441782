export const getSKUParts = (sku: string) => {
  if (!sku) {
    return;
  }

  const [key, size] = sku.split(/[.-]/);

  return {
    key,
    size,
  };
};
