import { Block, MyDataForm } from '@engbers/components';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
export const MyDataFormTastic = ({
  data
}) => {
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>
        <MyDataForm phoneTooltipHeadline={data.phoneTooltipHeadline} phoneTooltipContent={data.phoneTooltipContent} phoneUseInfoModal={data.phoneUseInfoModal} emailTooltipHeadline={data.emailTooltipHeadline} emailTooltipContent={data.emailTooltipContent} emailUseInfoModal={data.emailUseInfoModal} billingAddressHeadline={data.billingAddressHeadline} birthdateTooltipContent={data.birthdateTooltipContent} birthdateUseInfoModal={data.birthdateUseInfoModal} />
      </FullPageWidthWrapper>;
  }
  return <Block data-sentry-element="Block" data-sentry-component="MyDataFormTastic" data-sentry-source-file="index.tsx">
      <MyDataForm phoneTooltipHeadline={data.phoneTooltipHeadline} phoneTooltipContent={data.phoneTooltipContent} phoneUseInfoModal={data.phoneUseInfoModal} emailTooltipHeadline={data.emailTooltipHeadline} emailTooltipContent={data.emailTooltipContent} emailUseInfoModal={data.emailUseInfoModal} billingAddressHeadline={data.billingAddressHeadline} birthdateTooltipContent={data.birthdateTooltipContent} birthdateUseInfoModal={data.birthdateUseInfoModal} data-sentry-element="MyDataForm" data-sentry-source-file="index.tsx" />
    </Block>;
};