import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { IFTLinkReference, IFTPageFolderReference, MediaType } from '@frontastic-engbers/types/engbers-custom';
import { getReferenceTarget, ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { CustomMarkdown, IconCustom, Image, InputText } from '@engbers/components/index';
import styles from './footer.module.scss';
import classnames from 'classnames';
export interface Props {
  newsletterText: string;
  newsletterHeadline: string;
  newsletterPlaceholder: string;
  enGermanyNewsletterButtonText: string;
  newsletterLink: IFTLinkReference | IFTPageFolderReference;
  socialMediaHeadline: string;
  socialMediaIcons: SocialMediaType[];
  emilioFirstSectionBgColor: string;
  enGermanyFirstSectionBgColor: string;
  firstSectionFontColor: string;
  isEmilio: boolean;
  isEnGermany: boolean;
}
export interface SocialMediaType {
  socialMediaIcon: MediaType;
  socialMediaLink: IFTLinkReference | IFTPageFolderReference;
}
export const FooterFirstSection: React.FC<Props> = ({
  newsletterText,
  newsletterHeadline,
  newsletterPlaceholder,
  enGermanyNewsletterButtonText,
  newsletterLink,
  socialMediaHeadline,
  socialMediaIcons,
  emilioFirstSectionBgColor,
  enGermanyFirstSectionBgColor,
  firstSectionFontColor,
  isEmilio,
  isEnGermany
}) => {
  const {
    getTitle
  } = useImageSEO();
  const router = useRouter();
  const [email, setEmail] = useState('');
  const redirectToNewsletter = async () => {
    if (newsletterLink) {
      await router.push({
        pathname: getReferenceTarget(newsletterLink),
        query: {
          ...(email && {
            email
          })
        }
      });
      setEmail('');
    }
  };
  return <div className={classnames(styles.footerFirstSectionWrap, {
    [styles.enGermanyFooterFirstSectionWrap]: isEnGermany
  })} style={{
    backgroundColor: (isEmilio && emilioFirstSectionBgColor || isEnGermany && enGermanyFirstSectionBgColor) ?? undefined
  }} data-sentry-component="FooterFirstSection" data-sentry-source-file="footerFirstSection.tsx">
      <div className={styles.sectionWrap}>
        <div className={classnames(!isEnGermany && 'h3')} style={{
        color: firstSectionFontColor ?? undefined
      }}>
          <CustomMarkdown text={newsletterText} data-sentry-element="CustomMarkdown" data-sentry-source-file="footerFirstSection.tsx" />
        </div>
      </div>
      <div className={styles.sectionWrap}>
        <h5 style={{
        color: firstSectionFontColor ?? undefined
      }}>{newsletterHeadline}</h5>
        <div className={styles.newsletterEmailWrapper}>
          <div className={styles.newsletterEmailInput}>
            <InputText name="email" type="email" value={email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} onEnter={redirectToNewsletter} placeholder={newsletterPlaceholder} data-sentry-element="InputText" data-sentry-source-file="footerFirstSection.tsx" />
          </div>
          <button onClick={redirectToNewsletter} className={styles.newsletterEmailButton}>
            {isEnGermany && enGermanyNewsletterButtonText ? enGermanyNewsletterButtonText : <IconCustom width={20} color="white" icon="ChevronRightIcon" />}
          </button>
        </div>
      </div>
      <div className={styles.sectionWrap}>
        <h5 style={{
        color: firstSectionFontColor ?? undefined
      }}>{socialMediaHeadline}</h5>

        {socialMediaIcons?.length ? <ul className={styles.socialMediaIconsWrap}>
            {socialMediaIcons.map((socialMedia, index) => <li key={`footer-first-section-${index}`}>
                <ReferenceLink target={socialMedia.socialMediaLink}>
                  <Image src={socialMedia.socialMediaIcon?.media?.file} alt={getTitle(socialMedia.socialMediaIcon)} title={getTitle(socialMedia.socialMediaIcon)} width={29} />
                </ReferenceLink>
              </li>)}
          </ul> : null}
      </div>
    </div>;
};