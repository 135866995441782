import { useRefinementList } from 'react-instantsearch';
import { InputCheckbox } from '@engbers/components';
import { IFacetProps } from './types';
import { handleFilterDataLayer } from '@frontastic-engbers/helpers/dataLayerHelper';
import React from 'react';
const BooleanFacet: React.FC<IFacetProps> = ({
  attribute,
  label,
  wrapper: Wrapper = React.Fragment,
  showItemCount = false
}) => {
  const {
    items,
    refine
  } = useRefinementList({
    attribute
  });
  return <>
      {items.filter(term => term.value === '1').map(term => <Wrapper key={term.value + 'wrapper'}>
            <div>
              <InputCheckbox key={term.value} id={attribute} name={attribute} checked={term.isRefined} onChange={() => {
          refine(term.value);
          handleFilterDataLayer(items, label, term.value);
        }} label={`${label}${showItemCount ? ` (${term.count.toString()})` : ``}`} />
            </div>
          </Wrapper>)}
    </>;
};
export default BooleanFacet;