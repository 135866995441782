import { useState } from 'react';
import { Block, Button } from '@engbers/components';
import { InputDate } from '@engbers/components/shopmacher-ui/input-date';
import { AccountMigrationLoginResponse } from '@frontastic-engbers/types/account/Account';
import { useRouter } from 'next/router';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { migrationLogin } from '@frontastic-engbers/lib/actions/account';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
export type MigrationLoginSecondStepFormData = {
  email: string;
  birthdate: string;
};
type MigrationStepTwoModalType = {
  loading: boolean;
  onMigrationLoginSecondStepCallback?: (response: AccountMigrationLoginResponse) => Promise<void>;
  cardId: string;
  migrationVerifyInformation?: string;
  migrationVerifyBirthdate?: string;
};
export const MigrationStepTwoModal: React.FC<MigrationStepTwoModalType> = ({
  loading,
  onMigrationLoginSecondStepCallback,
  cardId,
  migrationVerifyInformation,
  migrationVerifyBirthdate
}) => {
  const router = useRouter();
  const {
    removeModal
  } = useModalActions();
  const {
    pushNotification
  } = useToastNotificationsActions();
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    formatMessage: formatAccountMessage
  } = useFormat({
    name: 'account'
  });
  const [error, setError] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);
  const [birthday, setBirthday] = useState<{
    birthdayDay: string;
    birthdayMonth: string;
    birthdayYear: string;
  }>({
    birthdayDay: '',
    birthdayMonth: '',
    birthdayYear: ''
  });
  const onSubmit = async (birthday: {
    birthdayYear: string;
    birthdayMonth: string;
    birthdayDay: string;
  }) => {
    if (!isValid) {
      return;
    }
    const birthdate = `${birthday.birthdayDay.toString().padStart(2, '0')}.${birthday.birthdayMonth.toString().padStart(2, '0')}.${birthday.birthdayYear}`;
    const response = await migrationLogin(cardId, birthdate);
    if (response.status === 'SUCCESS') {
      removeModal();
      if (typeof onMigrationLoginSecondStepCallback === 'function') {
        await onMigrationLoginSecondStepCallback(response);
        return;
      }
      sessionStorage.setItem('registerFormState', JSON.stringify(response.registerFormState));
      new TagManager().customEvent('login', {
        value: 'Migration'
      }).executePush();
      if (router.asPath === '/checkout/login') {
        router.push('/checkout');
        return;
      }
      await router.push({
        pathname: '/register',
        query: {
          migrationslogin: true
        }
      });
      pushNotification(migrationVerifyInformation, 'success');
      return;
    }
    setError(formatErrorMessage({
      id: 'birthdayCardId.wrong',
      defaultMessage: 'Geburtsdatum oder Card-Id ist falsch!'
    }));
    setIsValid(false);
  };
  return <>
      <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">{migrationVerifyBirthdate}</Block>
      <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <InputDate label={formatMessage({
        id: 'birthDate'
      })} onChange={value => setBirthday(prev => ({
        ...prev,
        ...value
      }))} required isFormatValid={valid => setIsValid(valid)} errorMessage={error} data-sentry-element="InputDate" data-sentry-source-file="index.tsx" />
      </Block>
      <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <Button size="large" isLoading={loading} onClick={() => onSubmit(birthday)} label={formatAccountMessage({
        id: 'sign.activate'
      })} className="migration-login-btn" data-sentry-element="Button" data-sentry-source-file="index.tsx" />
      </Block>
    </>;
};