const sizes = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '3XL',
  '4XL',
  '5XL',
  '6XL',
  '7XL',
  '8XL',
] as const;

type Size = typeof sizes[number];
type SizeOrder = Record<Size, number>;

type ExtractedValue = {
  type: 'number' | 'size';
  value: number;
} | {
  type: 'slash';
  value: {
    first: number;
    second: number;
  };
};

type SizeItem = {
  value: string;
};

export const sizeOrder = sizes.reduce((obj, size, index) => ({
  ...obj,
  [size]: index,
}), {} as SizeOrder);

const extractValue = (value: string): ExtractedValue => {
  if (/^\d+$/.test(value)) {
    return { type: 'number', value: parseInt(value, 10) };
  }

  if (/^\d+\/\d+$/.test(value)) {
    const [first, second] = value.split('/');

    return { type: 'slash', value: { first: parseInt(first, 10), second: parseInt(second, 10) } };
  }

  return { type: 'size', value: sizeOrder[value as Size] };
};

export const sortSizes = (a: SizeItem, b: SizeItem) => {
  const aValue = extractValue(a.value);
  const bValue = extractValue(b.value);

  if (aValue.type !== bValue.type) {
    if (aValue.type === 'number') return -1;
    if (bValue.type === 'number') return 1;
    if (aValue.type === 'size') return -1;
    if (bValue.type === 'size') return 1;
  }

  if (aValue.type === 'number' && bValue.type === 'number') {
    return aValue.value - bValue.value;
  }

  if (aValue.type === 'size' && bValue.type === 'size') {
    return aValue.value - bValue.value;
  }

  if (aValue.type === 'slash' && bValue.type === 'slash') {
    if (aValue.value.first !== bValue.value.first) {
      return aValue.value.first - bValue.value.first;
    }

    return aValue.value.second - bValue.value.second;
  }

  return 0;
};
