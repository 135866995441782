import React from 'react';
import StyleGuideButtons from './style-guide-buttons';
import StyleGuideGrid from './style-guide-grid';
import StyleGuideIcons from './style-guide-icons';
import StyleGuideInputs from './style-guide-inputs';
import StyleGuideTypography from './style-guide-typography';
import StyleGuideColors from './style-guide-colors';
import StyleGuideModal from './style-guide-modal';
const StyleGuide: React.FC = () => <div data-sentry-component="StyleGuide" data-sentry-source-file="style-guide.tsx">
    <StyleGuideColors data-sentry-element="StyleGuideColors" data-sentry-source-file="style-guide.tsx" />
    <StyleGuideTypography data-sentry-element="StyleGuideTypography" data-sentry-source-file="style-guide.tsx" />
    <StyleGuideButtons data-sentry-element="StyleGuideButtons" data-sentry-source-file="style-guide.tsx" />
    <StyleGuideInputs data-sentry-element="StyleGuideInputs" data-sentry-source-file="style-guide.tsx" />
    <StyleGuideModal data-sentry-element="StyleGuideModal" data-sentry-source-file="style-guide.tsx" />
    <StyleGuideIcons data-sentry-element="StyleGuideIcons" data-sentry-source-file="style-guide.tsx" />
    <StyleGuideGrid data-sentry-element="StyleGuideGrid" data-sentry-source-file="style-guide.tsx" />
  </div>;
export default StyleGuide;