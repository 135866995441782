import { Product } from '@frontastic-engbers/types/product/Product';

export const getEcommProductsIds = (products: Product[]): (string | undefined)[] => {
  return products?.map((product) => product.productId);
};

export const getEcommProductsTotalValue = (products: Product[]): number => {
  return (
    products.reduce(
      (price, product) =>
        price + (product.variants[0]?.discountedPrice?.centAmount ?? product.variants[0]?.price?.centAmount ?? 0),
      0,
    ) / 100
  );
};
