import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Flag, SaleFlag, NewFlag, CampaignFlag } from '@frontastic-engbers/types/product/Flag';
import { SaleFlag as SaleFlagComponent } from './components/sale-flag';
import { NewFlag as NewFlagComponent } from './components/new-flag';
import { CampaignFlag as CampaignFlagComponent } from './components/campaign-flag';
import { IconCustom } from '@engbers/components';
import styles from './product-flags.module.scss';
type Props = {
  flags: Flag[];
  className?: string;
  maxFlags?: number;
  flagOrder?: string[];
  isEnGermany?: boolean;
};
export const ProductFlags: React.FC<Props> = ({
  flags,
  className,
  isEnGermany,
  maxFlags = 1,
  flagOrder = ['sale', 'new', 'campaign', 'engbersGermany']
}) => {
  const productFlags = useMemo(() => {
    const flagsList: Flag[] = [];
    for (const type of flagOrder) {
      if (type === 'engbersGermany' && isEnGermany) {
        flagsList.push({
          type: 'engbersGermany'
        });
      } else {
        flagsList.push(...(flags.filter(flag => flag.type === type) ?? []));
      }
      if (flagsList.length >= maxFlags) {
        return flagsList.slice(0, maxFlags);
      }
    }
    return flagsList;
  }, [flags, flagOrder, maxFlags]);
  if (productFlags.length === 0) {
    return null;
  }
  return <div className={classnames(className, styles.productFlags)} data-sentry-component="ProductFlags" data-sentry-source-file="index.tsx">
      {productFlags.map((flag, index) => {
      switch (flag.type) {
        case 'sale':
          return <SaleFlagComponent flag={flag as SaleFlag} key={`flag-${index}`} />;
        case 'new':
          return <NewFlagComponent flag={flag as NewFlag} key={`flag-${index}`} />;
        case 'campaign':
          return <CampaignFlagComponent flag={flag as CampaignFlag} key={`flag-${index}`} />;
        case 'engbersGermany':
          return <IconCustom icon="EngbersGermanyLogoFlag" width={50} key={`flag-${index}`} />;
        default:
          return null;
      }
    })}
    </div>;
};