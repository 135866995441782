import { OrderHistory } from '@engbers/components';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
export const MyAccountOrderHistoryTastic = ({
  data
}) => {
  if (data?.isFullWidth) {
    return <FullPageWidthWrapper>
        <OrderHistory orderStatusLabels={{
        1: data.orderStatus1,
        2: data.orderStatus2,
        3: data.orderStatus3,
        4: data.orderStatus4
      }} isOS headline={data.headline} textSize={data.textSize} icon={data.icon} colors={{
        accordeonColor: data.accordeonColor,
        accordeonTextColor: data.accordeonTextColor,
        accordeonContentColor: data.accordeonContentColor,
        accordonContentTextColor: data.accordonContentTextColor,
        statusColor: data.statusColor,
        statusTextColor: data.statusTextColor
      }} trackingLink={data.trackingLink} />
      </FullPageWidthWrapper>;
  }
  return <div data-sentry-component="MyAccountOrderHistoryTastic" data-sentry-source-file="index.tsx">
      <OrderHistory orderStatusLabels={{
      1: data.orderStatus1,
      2: data.orderStatus2,
      3: data.orderStatus3,
      4: data.orderStatus4
    }} isOS headline={data.headline} textSize={data.textSize} subHeadline={data.subHeadline} noOrdersInfo={data.noOrders} icon={data.icon} colors={{
      accordeonColor: data.accordeonColor,
      accordeonTextColor: data.accordeonTextColor,
      accordeonContentColor: data.accordeonContentColor,
      accordonContentTextColor: data.accordonContentTextColor,
      statusColor: data.statusColor,
      statusTextColor: data.statusTextColor
    }} trackingLink={data.trackingLink} data-sentry-element="OrderHistory" data-sentry-source-file="index.tsx" />
    </div>;
};