import { useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useHits } from 'react-instantsearch';
import { useCurrentRefinements } from 'react-instantsearch-core';
import classnames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import useOnClickOutside from '@frontastic-engbers/helpers/hooks/useOnClickOutside';
import { disableScrollOnBody } from '@frontastic-engbers/helpers/utils/disableScrollOnBody';
import { getDistinctSelectedFacetCount, getRefinementCount } from '@frontastic-engbers/helpers/utils/getRefinementCount';
import { Button, IconCustom } from '@engbers/components';
import { Sorting } from '../sorting';
import { useProductList } from '../../context';
import useDynamicFacets from '../../hooks/useDynamicFacets';
import styles from './mobile-facets.module.scss';
interface IMobileFacets {
  indexName: string;
  sortingLabel?: string;
  filterButtonLabel?: string;
  showProductsLabel?: string;
}
const MobileFacets: React.FC<IMobileFacets> = ({
  indexName,
  sortingLabel,
  filterButtonLabel,
  showProductsLabel
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    formatMessage: formatProductMessage
  } = useFormat({
    name: 'product'
  });
  const {
    facetsConfiguration
  } = useProductList();
  const {
    results
  } = useHits();
  const {
    items
  } = useCurrentRefinements();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showFacetValues, setShowFacetValues] = useState<boolean>(false);
  const [activeAttribute, setActiveAttribute] = useState<string>(null);
  useOnClickOutside(ref, () => {
    !!isOpen && closeFilterModal();
  });
  const showFilterModal = () => {
    setIsOpen(true);
    disableScrollOnBody(true);
  };
  const showSecondLevel = (attribute: string) => {
    setActiveAttribute(attribute);
    setShowFacetValues(true);
  };
  const closeFilterModal = () => {
    setShowFacetValues(false);
    setIsOpen(false);
    disableScrollOnBody(false);
  };
  const closeButton = <button className={styles.closeButton} onClick={closeFilterModal}>
      <div className={styles.closeIcon} />
    </button>;
  const showResultsButton = <Button onClick={closeFilterModal} type="primary" textColor="#0f202f" hasIcon={false} label={showProductsLabel.replace('%currentProducts%', results?.nbSortedHits?.toString())} />;
  const facets = Object.keys(facetsConfiguration).map(attribute => {
    const isEmptyFacet = !results?.disjunctiveFacets?.find(f => f.name === attribute && f?.stats?.max !== 0);
    if (isEmptyFacet) {
      return;
    }
    const facet = facetsConfiguration[attribute];
    const refinementCount = getRefinementCount(items, attribute);
    return <button key={`filter--${attribute}`} className={styles.facet} onClick={() => showSecondLevel(attribute)}>
        {facet.label} {refinementCount > 0 && `(${refinementCount})`}
        <IconCustom width={12} color={'primary'} icon="ChevronRightIcon" />
      </button>;
  });
  const facetValues = useDynamicFacets({
    configuration: facetsConfiguration,
    ordering: results?.renderingContent?.facetOrdering?.facets?.order,
    isMobile: true,
    render: ({
      attribute,
      Component
    }) => {
      return <div key={`filterValues--${attribute}`} className={classnames(styles.facetValuesWrap, {
        [styles.isActive]: showFacetValues && activeAttribute === attribute
      })}>
          <div>
            <div className={styles.facetHeading}>
              <button className={styles.facetBackBtn} onClick={() => setShowFacetValues(false)}>
                <IconCustom width={20} icon="ArrowThinLeft" />
              </button>
              <span className={styles.facetAttribute}>{facetsConfiguration[attribute]?.label}</span>
              {closeButton}
            </div>
            {Component}
          </div>
          {results && showResultsButton}
        </div>;
    }
  });
  const sortFacet = <button key={`filter--sortBy`} className={styles.facet} onClick={() => showSecondLevel('sortBy')}>
      {sortingLabel || formatProductMessage({
      id: 'sortBy',
      defaultMessage: 'Sortieren nach'
    })}
      <IconCustom width={12} color={'primary'} icon="ChevronRightIcon" />
    </button>;
  const sortFacetValues = useMemo(() => {
    return <div key={`filterValues--sortBy`} className={classnames(styles.facetValuesWrap, {
      [styles.isActive]: showFacetValues && activeAttribute === 'sortBy'
    })}>
        <div>
          <div className={styles.facetHeading}>
            <button className={styles.facetBackBtn} onClick={() => setShowFacetValues(false)}>
              <IconCustom width={20} icon="ArrowThinLeft" />
            </button>
            <span className={styles.facetAttribute}>
              {sortingLabel || formatProductMessage({
              id: 'sortBy',
              defaultMessage: 'Sortieren nach'
            })}
            </span>
            {closeButton}
          </div>
          <Sorting resultsIndexName={results.index} sortingLabel={sortingLabel} closeFilterModal={closeFilterModal} indexName={indexName} isMobile={true} />
        </div>
      </div>;
  }, [showFacetValues, activeAttribute]);
  const buttonLabel = filterButtonLabel ?? formatProductMessage({
    id: 'sortAndFilter',
    defaultMessage: 'Filtern und Sortieren'
  });
  const distinctFacetsCount = getDistinctSelectedFacetCount(items);
  const distinctFacetsCountLabel = distinctFacetsCount > 0 ? ` (${distinctFacetsCount})` : '';
  return <div className={styles.filterWrap} data-sentry-component="MobileFacets" data-sentry-source-file="index.tsx">
      <Button onClick={showFilterModal} type="primary" textColor="#0f202f" hasIcon={false} label={buttonLabel + distinctFacetsCountLabel} size={'large'} customStyle={{
      fontSize: '16px'
    }} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
      {isOpen && createPortal(<div className={styles.filterModalBackdrop}></div>, document.body)}
      <div ref={ref} className={classnames(styles.filterModal, {
      [styles.isOpen]: isOpen
    })}>
        {!showFacetValues && <div className={classnames(styles.facetsWrap, {
        [styles.isHidden]: showFacetValues
      })}>
            <div>
              <div className={styles.facetHeading}>{closeButton}</div>
              {sortFacet}
              {facets}
            </div>
            {results && showResultsButton}
          </div>}
        {sortFacetValues}
        {facetValues}
      </div>
    </div>;
};
export default MobileFacets;