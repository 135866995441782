import classNames from 'classnames';
interface ISpacer {
  spacing: number;
}
export const Spacer: React.FC<ISpacer> = ({
  spacing
}) => {
  return <div className={classNames({
    [`h-${spacing}`]: !!spacing
  })} data-sentry-component="Spacer" data-sentry-source-file="index.tsx" />;
};