import { LocaleStorage } from '@frontastic-engbers/lib';
import { DeviceParams } from '@frontastic-engbers/types/engbers-custom';

export const get3dsInformation = (): DeviceParams => {
  return {
    acceptHeader: LocaleStorage.accept,
    locale: navigator.language,
    timezoneOffsetUtcMinutes: new Date().getTimezoneOffset().toString(),
    browserData: {
      colorDepth: window.screen.colorDepth,
      javaScriptEnabled: true,
      javaEnabled: navigator.javaEnabled(),
      screenHeight: window.screen.height.toString(),
      screenWidth: window.screen.width.toString(),
    },
  };
};
