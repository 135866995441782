import { Cart } from '@frontastic-engbers/types/cart/Cart';
import { CustomLineItem } from '@frontastic-engbers/types/cart/CustomLineItem';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import { getShippingCostsByCountry } from './getShippingInfo';

export const mapCosts = (cart: Cart, shippingCountry?: string) => {
  const currencyCode = cart?.sum?.currencyCode ?? 'EUR';
  const fractionDigits = cart?.sum?.fractionDigits ?? 2;

  if (!cart?.lineItems?.length) {
    return {
      subtotal: {
        currencyCode,
        fractionDigits,
      },
      savedPrice: {
        centAmount: 0,
        currencyCode,
        fractionDigits,
      },
      shipping: {
        currencyCode,
        fractionDigits,
      },
      total: {
        centAmount: 0,
        currencyCode,
        fractionDigits,
      },
    };
  }

  const totalAmount = cart.sum?.centAmount as number;

  const subTotalAmount = cart?.lineItems?.reduce(
    (acc: number, curr: LineItem) => acc + (curr.price?.centAmount ?? 0) * (curr.count as number),
    0,
  );

  const discountedSubTotalAmount = cart?.lineItems?.reduce(
    (acc: number, curr: LineItem) => acc + (curr.totalPrice?.centAmount ?? 0),
    0,
  );

  const customDiscountsSubTotalAmount =
    cart?.customLineItems?.reduce((acc: number, curr: CustomLineItem) => acc + (curr.totalPrice?.centAmount ?? 0), 0) ??
    0;

  const savedPrice = subTotalAmount - discountedSubTotalAmount + Math.abs(customDiscountsSubTotalAmount);

  const estimatedShipping = getShippingCostsByCountry(cart.shippingInfo, shippingCountry)?.centAmount;
  const totalShipping =
    cart.shippingInfo?.price?.centAmount != null ? cart.shippingInfo?.price?.centAmount : estimatedShipping;
  const isEstimatedShipping = cart.shippingInfo?.price?.centAmount == null;

  return {
    subtotal: {
      centAmount: discountedSubTotalAmount,
      currencyCode,
      fractionDigits,
    },
    savedPrice: {
      centAmount: savedPrice <= subTotalAmount ? savedPrice : subTotalAmount,
      currencyCode,
      fractionDigits,
    },
    shipping: {
      centAmount: totalShipping,
      currencyCode,
      fractionDigits,
    },
    total: {
      centAmount: totalAmount + (isEstimatedShipping ? estimatedShipping ?? 0 : 0),
      currencyCode,
      fractionDigits,
    },
  };
};
