import { Storefinder } from '@engbers/components';
import { APIProvider } from '@vis.gl/react-google-maps';
export const StorefinderTastic = ({
  data
}) => {
  const {
    mapId,
    mapsKey
  } = data.config.dataSource;
  if (!mapId || !mapsKey) {
    return null;
  }
  return <APIProvider apiKey={mapsKey} libraries={['marker']} language="de" data-sentry-element="APIProvider" data-sentry-component="StorefinderTastic" data-sentry-source-file="index.tsx">
      <Storefinder mapId={mapId} labels={{
      search: data.searchLabel,
      select: data.selectLabel,
      assortment: data.assortmentLabel
    }} placeholder={{
      search: data.searchPlaceholder,
      select: data.selectPlaceholder,
      assortment: data.assortmentPlaceholder
    }} assortments={data.assortments} noResultsMessage={data.noResultsMessage} headline={data.headline} fallbackImage={data.fallbackImage} appointmentLabel={data.appointmentLabel} appointmentBtnColor={data.appointmentBtnColor} locationLabel={data.showLocationLabel} storeClosedHint={data.storeClosedHint} data-sentry-element="Storefinder" data-sentry-source-file="index.tsx" />
    </APIProvider>;
};