import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { EcondaWidgetCredentials, IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { Autocomplete } from '../autocomplete';
import { IconCustom } from '@engbers/components/icon-custom';
import styles from './mobile-search.module.scss';
interface Props {
  searchPlaceholder?: string;
  popularCategoriesLabel: string;
  popularCategories: {
    categoryLabel: string;
    categoryLink: IFTLinkReference | IFTPageFolderReference;
  }[];
  topSellerLabel: string;
  topSellerWidgetId: string;
  topSellerWidgetConfiguration: EcondaWidgetCredentials;
  suggestedProductsLabel: string;
  suggestedProductsLimit: number;
  suggestedCategoriesLabel: string;
  suggestedCategoriesLimit: number;
  allResultsLabel: string;
  algoliaConfig: any;
  showSearchModal: boolean;
  setShowSearchModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export const MobileSearch: React.FC<Props> = ({
  searchPlaceholder,
  popularCategoriesLabel,
  popularCategories,
  topSellerLabel,
  topSellerWidgetId,
  topSellerWidgetConfiguration,
  suggestedProductsLabel,
  suggestedProductsLimit,
  suggestedCategoriesLabel,
  suggestedCategoriesLimit,
  allResultsLabel,
  algoliaConfig,
  showSearchModal,
  setShowSearchModal,
  setShowModal
}) => {
  const flyoutWrapperRef = useRef<HTMLDivElement>(null);
  const searchFormWrapperRef = useRef<HTMLDivElement>(null);
  const [showClearValBtn, setShowClearValBtn] = useState<boolean>(false);
  const handleBackButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowSearchModal(false);
    e.stopPropagation();
  };
  const onChange = (query: string) => {
    setShowClearValBtn(!!query.length);
  };
  const onClick = () => {
    setShowModal(false);
    setShowSearchModal(false);
  };
  const onReset = () => {
    setShowClearValBtn(false);
  };
  return <div className={styles.searchWrapper} data-sentry-component="MobileSearch" data-sentry-source-file="index.tsx">
      <div className={styles.searchFormWrapper} ref={searchFormWrapperRef}>
        {showSearchModal && <button type="button" className={styles.searchBackBtn} onClick={handleBackButtonClick}>
            <IconCustom width={20} icon="ArrowThinLeft" />
          </button>}
        {!showClearValBtn && showSearchModal && <button type="submit" className={classnames(styles.searchIcon, styles.searchIconAligned)}>
            <IconCustom width={20} icon="Search" />
          </button>}
      </div>
      <div className={classnames(styles.searchFlyout, showSearchModal ? styles.showSearchFlyout : undefined)} ref={flyoutWrapperRef}>
        <Autocomplete searchPlaceholder={searchPlaceholder} popularCategoriesLabel={popularCategoriesLabel} popularCategories={popularCategories} topSellerLabel={topSellerLabel} topSellerWidgetId={topSellerWidgetId} topSellerWidgetConfiguration={topSellerWidgetConfiguration} suggestedProductsLabel={suggestedProductsLabel} suggestedProductsLimit={suggestedProductsLimit} suggestedCategoriesLabel={suggestedCategoriesLabel} suggestedCategoriesLimit={suggestedCategoriesLimit} allResultsLabel={allResultsLabel} algoliaConfig={algoliaConfig} isMobile={true} flyoutWrapperRef={flyoutWrapperRef} searchFormWrapperRef={searchFormWrapperRef} onClick={onClick} onChange={onChange} onReset={onReset} setOpen={setShowSearchModal} data-sentry-element="Autocomplete" data-sentry-source-file="index.tsx" />
      </div>
    </div>;
};