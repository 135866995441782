import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';
import Spinner from '@engbers/components/online-shops/commercetools-ui/spinner';
import styles from './full-page-loading-indicator.module.scss';
export const FullPageLoadingIndicator = () => {
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  useEffect(() => {
    const handleStart = (url: string) => url !== router.asPath && setLoading(true);
    const handleComplete = () => setLoading(false);
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router.asPath, router.events]);
  return loading && <div className={styles.spinnerWrap}>
        <Spinner size={isMinWidthMedium ? 'small' : 'large'} />
      </div>;
};