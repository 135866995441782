import React from 'react';
import dynamic from 'next/dynamic';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { getProjectInfo } from '@frontastic-engbers/lib';
import LoadingPlaceholder from '@frontastic-engbers/lib/lib/loading-placeholder';
const FooterTastic = ({
  data
}) => {
  const projectInfo = getProjectInfo();
  const isEmilio = projectInfo?.projectId === 'ea';
  const Footer = dynamic(() => import('@engbers/components/online-shops/footer').then(module => module.Footer), {
    loading: () => <LoadingPlaceholder height="800px" />
  });
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>
        <Footer data={data} isFullWidth={data.isFullWidth} isEmilio={isEmilio} />
      </FullPageWidthWrapper>;
  }
  return <div data-sentry-component="FooterTastic" data-sentry-source-file="index.tsx">
      <Footer data={data} isEmilio={isEmilio} data-sentry-element="Footer" data-sentry-source-file="index.tsx" />
    </div>;
};
export default FooterTastic;