export class PasswordCheckListHelper {
  private static readonly CHAR_TYPE_UPPERCASE = 1;
  private static readonly CHAR_TYPE_LOWERCASE = 2;

  static ruleDefinitions = (value: string, minLength: number) => {
    const specialCharRegex = new RegExp(/[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/, 'g');

    return {
      minLength: {
        valid: value.length >= minLength,
        message: `Mindestens ${minLength} Zeichen`,
      },
      specialChar: {
        valid: specialCharRegex.test(value),
        message: 'Mindestens ein Sonderzeichen',
      },
      number: {
        valid: /\d/g.test(value),
        message: 'Mindestens eine Zahl',
      },
      capital: {
        valid: PasswordCheckListHelper.containsCharacterType(value, PasswordCheckListHelper.CHAR_TYPE_UPPERCASE),
        message: 'Mindestens einen Buchstaben in Groß',
      },
      lowercase: {
        valid: PasswordCheckListHelper.containsCharacterType(value, PasswordCheckListHelper.CHAR_TYPE_LOWERCASE),
        message: 'Mindestens einen Buchstaben in Klein',
      },
      notEmpty: {
        valid: Boolean(value.length > 0),
        message: 'Passwort muss angegeben werden',
      },
    };
  };

  private static containsCharacterType(value: string, type: number): boolean {
    if (value.length === 0) {
      return false;
    }

    const specialCharRegex = new RegExp(/[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/, 'g');
    const digitCharRegex = new RegExp(/\d/, 'g');

    let i = 0;
    while (i < value.length) {
      const char = value.charAt(i);
      const typedChar = type === PasswordCheckListHelper.CHAR_TYPE_LOWERCASE ? char.toLowerCase() : char.toUpperCase();

      specialCharRegex.lastIndex = 0;
      digitCharRegex.lastIndex = 0;

      if (char === typedChar && !specialCharRegex.test(char) && !digitCharRegex.test(char)) {
        return true;
      }
      i++;
    }
    return false;
  }
}
