import classNames from "classnames";
import styles from "./block.module.scss";
export interface IBlock {
  children: React.ReactNode;
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
  maxWidth?: string;
  customStyle?: object;
  className?: string;
}
export const Block: React.FC<IBlock> = ({
  children,
  paddingTop,
  className,
  paddingRight,
  paddingBottom,
  paddingLeft,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  maxWidth,
  customStyle
}) => {
  return <div className={classNames(styles.block, {
    [`mt-${marginTop}`]: !!marginTop,
    [`mr-${marginRight}`]: !!marginRight,
    [`mb-${marginBottom}`]: !!marginBottom,
    [`ml-${marginLeft}`]: !!marginLeft,
    [`pt-${paddingTop}`]: !!paddingTop,
    [`pr-${paddingRight}`]: !!paddingRight,
    [`pb-${paddingBottom}`]: !!paddingBottom,
    [`pl-${paddingLeft}`]: !!paddingLeft
  }, className)} style={{
    ...customStyle,
    maxWidth: maxWidth
  }} data-sentry-component="Block" data-sentry-source-file="index.tsx">
            {children}
        </div>;
};