import { Discount } from '@frontastic-engbers/types/cart/Discount';
import { CustomLineItem } from '@frontastic-engbers/types/cart/CustomLineItem';
import { DataLayerFactory } from '@frontastic-engbers/lib/lib/tracking/datalayerFactory';

export const joinDiscounts = (discounts: Discount[], customDiscounts: CustomLineItem[]) => {
  if (!discounts || !customDiscounts) {
    return;
  }

  const discountCodes = discounts?.map((obj) => obj.name);
  const customDiscountCodes = customDiscounts?.map((obj) => obj.discountCode);

  return [...discountCodes, ...customDiscountCodes].join('|');
};

export const joinDiscountsValue = (discounts: Discount[], customDiscounts: CustomLineItem[]) => {
  const discountValues = discounts?.reduce(
    (value, discount) => value + DataLayerFactory.convertCentAmount(discount.discountedAmount),
    0,
  );
  const customDiscountValues = customDiscounts?.reduce(
    (value, discount) => value + DataLayerFactory.convertCentAmount(discount.discountValue),
    0,
  );

  return discountValues + customDiscountValues;
};
