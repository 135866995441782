import React from 'react';
import { AccordionItem, Block } from '@engbers/components';
import { ChangeEmailForm } from '@engbers/components/change-email-form';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
interface IChangeEmailFormTasticComponent {
  data: IChangeEmailFormData;
}
interface IChangeEmailFormData {
  isFullWidth?: boolean;
  changeEmailHeadline?: string;
  doiNotificationLink?: IFTLinkReference | IFTPageFolderReference;
}
export const ChangeEmailFormTastic: React.FC<IChangeEmailFormTasticComponent> = ({
  data
}) => {
  const {
    formatMessage: formatAccountMessage
  } = useFormat({
    name: 'account'
  });
  const accordionHeading = data.changeEmailHeadline || formatAccountMessage({
    id: 'change.email.accordion.heading',
    defaultMessage: 'E-Mail-Adresse ändern'
  });
  const accordionItem = <AccordionItem label={accordionHeading}>
      <ChangeEmailForm doiNotificationLink={data.doiNotificationLink} />
    </AccordionItem>;
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>{accordionItem}</FullPageWidthWrapper>;
  }
  return <Block data-sentry-element="Block" data-sentry-component="ChangeEmailFormTastic" data-sentry-source-file="index.tsx">{accordionItem}</Block>;
};