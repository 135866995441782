import { LocationSearch } from '../location-search';
import { StoreResults } from './store-results';
import { getStoresByLocation } from '@frontastic-engbers/lib/actions/stores';
import { Address as AddressType, Address } from '@frontastic-engbers/types/account/Address';
import { LocationSearchState } from '@frontastic-engbers/types/engbers-custom';
import styles from '../packstation/packstation.module.scss';
interface IStoreDelivery {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  errorMessage: string;
  labelOpeningHours: string;
  labelSelect: string;
  storeClosedText: string;
  shippingAddress: Address;
  useStoreDelivery: {
    storeDeliveryState: LocationSearchState;
    setStoreDeliveryState: React.Dispatch<React.SetStateAction<LocationSearchState>>;
  };
  labels: {
    [name: string]: string;
  };
  updateFormInput: (propName: string, newValue: string | AddressType | boolean) => void;
}
export const StoreDelivery: React.FC<IStoreDelivery> = ({
  setLoading,
  loading,
  errorMessage,
  labelOpeningHours,
  labelSelect,
  storeClosedText,
  shippingAddress,
  useStoreDelivery,
  labels,
  updateFormInput
}) => {
  const {
    storeDeliveryState: state,
    setStoreDeliveryState: setState
  } = useStoreDelivery;
  const searchStoreDelivery = async () => {
    const stores = await getStoresByLocation(state.searchData, 'storeDelivery');
    setState({
      ...state,
      locations: stores
    });
  };
  const updateAddress = (id: string, checked: boolean) => {
    if (!checked) {
      updateFormInput('storeDeliveryAddress', null);
      updateFormInput('isUnselectedShippingAddress', true);
      return;
    }
    const store = state.locations.find(store => store.id === id);
    updateFormInput('isUnselectedShippingAddress', false);
    updateFormInput('storeDeliveryAddress', {
      company: store.name,
      addressId: store.id,
      streetName: store.street,
      streetNumber: store.streetNumber,
      postalCode: store.zip,
      city: store.city,
      country: store.country
    });
  };
  return <>
      <LocationSearch labels={labels} loading={loading} setLoading={setLoading} search={searchStoreDelivery} useFormState={{
      state,
      setState
    }} data-sentry-element="LocationSearch" data-sentry-source-file="index.tsx" />
      {state.locations.length === 0 ? <span className={styles.error}>{errorMessage}</span> : <StoreResults useStoreDelivery={useStoreDelivery} shippingAddress={shippingAddress} select={updateAddress} labelOpeningHours={labelOpeningHours} labelSelect={labelSelect} storeClosedText={storeClosedText} />}
    </>;
};