const getBlockStyle = (hasMarginRight?: boolean, isMobile?: boolean) => {
  if (!isMobile) {
    return {
      display: 'inline-flex',
      width: 'calc(50% - 8px)',
      flexDirection: 'column',
      marginRight: hasMarginRight ? '16px' : undefined,
    }
  }

  return undefined;
};

export default getBlockStyle;
