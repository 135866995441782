import { fetchApiHub } from '@frontastic-engbers/lib';
import { LocationSearch } from '../location-search';
import { PackStationResults } from './packstation-results';
import { Address as AddressType, Address } from '@frontastic-engbers/types/account/Address';
import { LocationSearchParams, LocationSearchState, PackstationInput } from '@frontastic-engbers/types/engbers-custom';
import styles from './packstation.module.scss';
interface IPackStation {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  errorMessage: string;
  errorPostNo: string;
  labelPostNo: string;
  labelSelect: string;
  shippingAddress: Address;
  usePackstation: {
    packstationState: LocationSearchState;
    setPackstationState: React.Dispatch<React.SetStateAction<LocationSearchState>>;
  };
  usePackstationInputs: {
    packstationInputs: {
      [key: string]: PackstationInput;
    };
    setPackstationInputs: React.Dispatch<React.SetStateAction<{
      [key: string]: PackstationInput;
    }>>;
  };
  labels: {
    [name: string]: string;
  };
  updateFormInput: (propName: string, newValue: string | AddressType | boolean) => void;
}
export const getPackstationsByAddress = async (packstationSearchData: LocationSearchParams) => {
  return await fetchApiHub('/action/packstation/getPackstationsByAddress', {
    method: 'POST'
  }, packstationSearchData);
};
export const PackStation: React.FC<IPackStation> = ({
  setLoading,
  loading,
  errorMessage,
  errorPostNo,
  labelPostNo,
  labelSelect,
  shippingAddress,
  usePackstation,
  usePackstationInputs,
  labels,
  updateFormInput
}) => {
  const {
    packstationState: state,
    setPackstationState: setState
  } = usePackstation;
  const searchPackstations = async () => {
    const packstations = await getPackstationsByAddress(state.searchData);
    setState({
      ...state,
      locations: packstations
    });
  };
  const updateAddress = (id: string, postNo: string, checked: boolean) => {
    if (!checked) {
      updateFormInput('packstationAddress', null);
      updateFormInput('isUnselectedShippingAddress', true);
      return;
    }
    const packstation = state.locations.find(packstation => packstation.id === id);
    updateFormInput('isUnselectedShippingAddress', false);
    updateFormInput('packstationAddress', {
      pOBox: postNo,
      streetName: packstation.type,
      streetNumber: packstation.id,
      postalCode: packstation.zip,
      city: packstation.city,
      country: packstation.country
    });
  };
  return <>
      <LocationSearch labels={labels} loading={loading} setLoading={setLoading} search={searchPackstations} useFormState={{
      state,
      setState
    }} showStreetInputs data-sentry-element="LocationSearch" data-sentry-source-file="index.tsx" />
      {state.locations.length === 0 ? <span className={styles.error}>{errorMessage}</span> : <PackStationResults usePackstation={usePackstation} usePackstationInputs={usePackstationInputs} shippingAddress={shippingAddress} select={updateAddress} errorPostNo={errorPostNo} labelPostNo={labelPostNo} labelSelect={labelSelect} />}
    </>;
};