import { SizeSuggestionConfig } from './index';

// adapted from the old shop
export const sizeSuggestionConfig: SizeSuggestionConfig = {
  'sizeGroups': {
    'international': {
      'XS': 0,
      'S': 1,
      'M': 2,
      'L': 3,
      'XL': 4,
      'XXL': 5,
      '3XL': 6,
      '4XL': 7,
      '5XL': 8,
      '6XL': 9,
    },
    'untersetzt': {
      '23': 0,
      '24': 1,
      '25': 2,
      '26': 3,
      '27': 4,
      '28': 5,
      '29': 6,
      '30': 7,
      '31': 8,
      '32': 9,
    },
    'normal': {
      '46': 0,
      '48': 1,
      '50': 2,
      '52': 3,
      '54': 4,
      '56': 5,
      '58': 6,
      '60': 7,
      '62': 8,
      '64': 9,
      '66': 10,
      '68': 11,
    },
    'schlank': {
      '94': 0,
      '98': 1,
      '102': 2,
      '106': 3,
      '110': 4,
      '114': 5,
    },
    'L32': {
      '30/32': 0,
      '31/32': 1,
      '32/32': 2,
      '33/32': 3,
      '34/32': 4,
      '35/32': 5,
      '36/32': 6,
      '38/32': 7,
      '40/32': 8,
      '42/32': 9,
      '44/32': 10,
      '46/32': 11,
    },
    'L34': {
      '30/34': 0,
      '31/34': 1,
      '32/34': 2,
      '33/34': 3,
      '34/34': 4,
      '35/34': 5,
      '36/34': 6,
      '38/34': 7,
      '40/34': 8,
      '42/34': 9,
      '44/34': 10,
      '46/34': 11,
    },
    'L36': {
      '31/36': 0,
      '32/36': 1,
      '33/36': 2,
      '34/36': 3,
      '35/36': 4,
      '36/36': 5,
      '38/36': 6,
      '40/36': 7,
      '42/36': 8,
      '44/36': 9,
      '46/36': 10,
    },
  },
  'productGroups': {
    'Anzug': {
      'sizeGroups': ['untersetzt', 'normal', 'schlank'],
      'modifiers': {
        'Label': {
          'engbers': 0,
          'emilio adani': -1,
        },
      },
    },
    'Hemd kurzarm': {
      'sizeGroups': ['international'],
      'modifiers': {
        'Label': {
          'engbers': 0,
          'emilio adani': -1,
        },
        'Form': {
          'regular': 0,
          'slim fit': -1,
        },
      },
    },
    'Hemd langarm': {
      'sizeGroups': ['international'],
      'modifiers': {
        'Label': {
          'engbers': 0,
          'emilio adani': -1,
        },
        'Form': {
          'regular': 0,
          'slim fit': -1,
        },
      },
    },
    'Jacke': {
      'sizeGroups': ['untersetzt', 'normal', 'schlank'],
      'modifiers': {
        'Label': {
          'engbers': 0,
          'emilio adani': -1,
        },
      },
    },
    'Langarm -Shirt': {
      'sizeGroups': ['international'],
      'modifiers': {
        'Label': {
          'engbers': 0,
          'emilio adani': -1,
        },
      },
    },
    'Leder': {
      'sizeGroups': ['untersetzt', 'normal', 'schlank'],
      'modifiers': {
        'Label': {
          'engbers': 0,
          'emilio adani': -1,
        },
      },
    },
    'Mantel': {
      'sizeGroups': ['untersetzt', 'normal', 'schlank'],
      'modifiers': {
        'Label': {
          'engbers': 0,
          'emilio adani': -1,
        },
      },
    },
    'Sakko': {
      'sizeGroups': ['untersetzt', 'normal', 'schlank'],
      'modifiers': {
        'Label': {
          'engbers': 0,
          'emilio adani': -1,
        },
        'Form': {
          'regular': 0,
          'slim fit': -1,
        },
      },
    },
    'Strick': {
      'sizeGroups': ['international', 'normal'],
      'modifiers': {
        'Label': {
          'engbers': 0,
          'emilio adani': -1,
        },
      },
    },
    'Sweat': {
      'sizeGroups': ['international'],
      'modifiers': {
        'Label': {
          'engbers': 0,
          'emilio adani': -1,
        },
      },
    },
    'T-Shirt': {
      'sizeGroups': ['international'],
      'modifiers': {
        'Label': {
          'engbers': 0,
          'emilio adani': -1,
        },
      },
    },
    // as adapted from the old shop: Hose, Jeans and Shorts were commented out there as well
    // 'Hose': {
    //   'sizeGroups': ['untersetzt', 'normal', 'schlank', 'L32', 'L34', 'L36'],
    //   'modifiers': {
    //     'Label': {
    //       'engbers': 0,
    //     },
    //   },
    // },
    // 'Jeans': {
    //   'sizeGroups': ['untersetzt', 'normal', 'schlank', 'L32', 'L34', 'L36'],
    //   'modifiers': {
    //     'Label': {
    //       'engbers': 0,
    //     },
    //   },
    // },
    // 'Shorts': {
    //   'sizeGroups': ['international', 'normal'],
    //   'modifiers': {
    //     'Label': {
    //       'engbers': 0,
    //     },
    //   },
    // },
  },
};
