import { Cart } from '@frontastic-engbers/types/cart/Cart';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';

export const checkLineItemDuplicates = (cartList: Cart, lineItem: LineItem) => {
  return cartList.lineItems?.reduce(
    (acc: Record<string, number>, item) => {
      if (item.lineItemId === lineItem.lineItemId) {
        acc.occurrences++;
        acc.count += item.count ?? 1;
      }
      return acc;
    },
    {
      occurrences: 0,
      count: 0,
    },
  );
};
