import React from 'react';
import { CreditVoucher } from '@engbers/components/online-shops/credit-voucher';
interface ICreditVouchersTasticData {
  placeholderText?: string;
  labelYourCredit?: string;
}
interface ICreditVouchersTasticComponent {
  data: ICreditVouchersTasticData;
}
export const CreditVouchersTastic: React.FC<ICreditVouchersTasticComponent> = ({
  data
}) => <CreditVoucher placeholderText={data.placeholderText} labelYourCredit={data.labelYourCredit} data-sentry-element="CreditVoucher" data-sentry-component="CreditVouchersTastic" data-sentry-source-file="index.tsx" />;