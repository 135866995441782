import React from 'react';
import { NewsletterResubscription } from '@engbers/components/online-shops/newsletter-resubscription';
import { MailingListGroup } from '@engbers/components/online-shops/newsletter-resubscription/types';
interface INewsletterResubscriptionData {
  groups: MailingListGroup[];
  ctaButtonLabel?: string;
  emailLabel?: string;
}
interface INewsletterResubscriptionTasticComponent {
  data: INewsletterResubscriptionData;
}
export const NewsletterResubscriptionTastic: React.FC<INewsletterResubscriptionTasticComponent> = ({
  data
}) => {
  return <NewsletterResubscription groups={data.groups} labels={{
    submit: data.ctaButtonLabel.trim().length > 0 ? data.ctaButtonLabel.trim() : undefined,
    email: data.emailLabel
  }} data-sentry-element="NewsletterResubscription" data-sentry-component="NewsletterResubscriptionTastic" data-sentry-source-file="index.tsx" />;
};