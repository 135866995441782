import React from 'react';
import classnames from 'classnames';
import { IFTLinkReference, IFTPageFolderReference, MediaType } from '@frontastic-engbers/types/engbers-custom';
import { CustomMarkdown } from '@engbers/components/custom-markdown';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { Image } from '@engbers/components/index';
import styles from './footer.module.scss';
export interface Props {
  thirdSectionLogo: MediaType;
  thirdSectionLogoLink: IFTLinkReference | IFTPageFolderReference;
  thirdSectionText: string;
  thirdSectionCopyRight: string;
  emilioThirdSectionBgColor: string;
  enGermanyThirdSectionBgColor: string;
  thirdSectionFontColor: string;
  enGermanyThirdSectionLinks: {
    linkText: string;
    link: IFTLinkReference | IFTPageFolderReference;
  }[];
  isEmilio: boolean;
  isEnGermany: boolean;
}
export const FooterThirdSection: React.FC<Props> = ({
  thirdSectionLogo,
  thirdSectionLogoLink,
  thirdSectionText,
  thirdSectionCopyRight,
  emilioThirdSectionBgColor,
  enGermanyThirdSectionBgColor,
  thirdSectionFontColor,
  enGermanyThirdSectionLinks,
  isEmilio,
  isEnGermany
}) => {
  const {
    getTitle
  } = useImageSEO();
  const logoLink: IFTLinkReference | IFTPageFolderReference = thirdSectionLogoLink ? thirdSectionLogoLink : {
    type: 'link',
    link: '/',
    target: '',
    openInNewWindow: false
  };
  return <div className={classnames(styles.footerThirdSectionWrap, {
    [styles.enGermanyFooterThirdSectionWrap]: isEnGermany
  })} style={{
    backgroundColor: (isEmilio && emilioThirdSectionBgColor || isEnGermany && enGermanyThirdSectionBgColor) ?? undefined,
    color: thirdSectionFontColor ?? undefined
  }} data-sentry-component="FooterThirdSection" data-sentry-source-file="footerThirdSection.tsx">
      {thirdSectionLogo?.media?.file && <div>
          <ReferenceLink target={logoLink}>
            <Image src={thirdSectionLogo.media.file} alt={getTitle(thirdSectionLogo)} title={getTitle(thirdSectionLogo)} width={90} />
          </ReferenceLink>
        </div>}
      {!isEnGermany && thirdSectionText && <CustomMarkdown text={thirdSectionText} />}
      <div className="flex justify-end">
        {isEnGermany && enGermanyThirdSectionLinks && <div className="flex justify-between gap-3 pr-3">
            {enGermanyThirdSectionLinks.map((sectionLink, index) => <ReferenceLink key={`section-link-${index}`} target={sectionLink.link}>
                {sectionLink.linkText}
              </ReferenceLink>)}
          </div>}
        {thirdSectionCopyRight ? <CustomMarkdown text={thirdSectionCopyRight} /> : null}
      </div>
    </div>;
};