import React from 'react';
import { ActionBanner } from '@engbers/components';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
interface ActionBannerProps {
  isFullWidth: boolean;
  isActive: boolean;
  showCountdown: boolean;
  backgroundColor: string;
  textColor: string;
  countdownTextColor: string;
  countdownClockColor: string;
  startDateTime: string;
  endDateTime: string;
  sliderDelay: number;
  actions: ActionProp[];
}
interface ActionProp {
  action: string;
}
interface ActionBannerData {
  data: ActionBannerProps;
}
export const ActionBannerTastic: React.FC<ActionBannerData> = ({
  data
}) => {
  if (data?.isFullWidth) {
    return <FullPageWidthWrapper>
        <ActionBanner data={data} isFullWidth={data.isFullWidth} />
      </FullPageWidthWrapper>;
  }
  return <ActionBanner data={data} data-sentry-element="ActionBanner" data-sentry-component="ActionBannerTastic" data-sentry-source-file="index.tsx" />;
};