import { Block } from '@engbers/components';
import { Text } from '@engbers/components/text';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
export const EngbersTextTastic = ({
  data
}) => {
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>
        <Text alignment={data.alignment} backgroundColor={data.backgroundColor} textColor={data.textColor} text={data.text} padding={data.padding} />
      </FullPageWidthWrapper>;
  }
  return <Block data-sentry-element="Block" data-sentry-component="EngbersTextTastic" data-sentry-source-file="index.tsx">
      <Text alignment={data.alignment} backgroundColor={data.backgroundColor} textColor={data.textColor} text={data.text} padding={data.padding} data-sentry-element="Text" data-sentry-source-file="index.tsx" />
    </Block>;
};