import { FullPageWidthWrapper } from '../full-page-width-wrapper';
import styles from './separation-line.module.scss';
interface ISeparationLine {
  sizeTop: number;
  sizeBottom: number;
  height: number;
  isFullWidth?: boolean;
}
export const SeparationLine: React.FC<ISeparationLine> = ({
  sizeBottom,
  sizeTop,
  height,
  isFullWidth = false
}) => {
  if (isFullWidth) {
    return <FullPageWidthWrapper>
        <hr className={styles.separationLine} style={{
        marginTop: `${sizeTop}px`,
        marginBottom: `${sizeBottom}px`,
        borderBottomWidth: `${height}px`
      }} />
      </FullPageWidthWrapper>;
  } else {
    return <hr className={styles.separationLine} style={{
      marginTop: `${sizeTop}px`,
      marginBottom: `${sizeBottom}px`,
      borderBottomWidth: `${height}px`
    }} />;
  }
};