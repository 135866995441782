import React, { useCallback } from 'react';
import { IconCustom, Markdown } from '@engbers/components';
import { getSKUParts } from '@frontastic-engbers/helpers/utils/getSKUParts';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import styles from './size-difference-overview.module.scss';
export interface ISizeDifferenceOverview {
  overviewDifferentSizeMessage: string;
  lineItems: LineItem[];
}
export const SizeDifferenceOverview: React.FC<ISizeDifferenceOverview> = ({
  overviewDifferentSizeMessage,
  lineItems
}) => {
  const checkForSizeDifference = useCallback(() => {
    if (!lineItems || !Array.isArray(lineItems)) {
      return false;
    }
    const skuSet = new Set();
    const sizeSet = new Set();
    for (const lineItem of lineItems) {
      const {
        key: sku,
        size
      } = getSKUParts(lineItem.variant.sku);
      if (skuSet.has(sku) && !sizeSet.has(size)) {
        return true;
      }
      skuSet.add(sku);
      sizeSet.add(size);
    }
    return false;
  }, [lineItems]);
  return checkForSizeDifference() ? <div className={styles.sizeInfoMessage}>
      <IconCustom width={20} icon="Info" color={'primary'} />
      <Markdown text={overviewDifferentSizeMessage} className={styles.markdown} linkToNewTab />
    </div> : null;
};