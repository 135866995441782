export class StringHelpers {
  /**
   * isNumeric tests a string and returns true when it's a decimal value
   */
  static isNumeric = (val: string) => /^\d+$/.test(val);

  /**
   * capitaliseFirstLetter capitalises only the very first character of a string, leaving the
   * rest unedited
   */
  static capitaliseFirstLetter = (val: string) => val.charAt(0).toUpperCase() + val.slice(1);

  static parameterReplace = (val: string, parameters: Record<string, string>): string => {
    const regExp = new RegExp(`{{${Object.keys(parameters).join('|')}}}`, 'gi');
    return val.replace(regExp, (matched) => parameters[matched.slice(2, -2)]);
  }
}
