import React, { useEffect, useState } from 'react';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useAccount, useCart } from '@frontastic-engbers/lib';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { UseCart } from '@frontastic-engbers/lib/provider/frontastic/UseCart';
import { joinDiscounts } from '@frontastic-engbers/helpers/dataLayerHelper/couponCodeDataHelper';
import { updateItemDatalayerPush } from '@frontastic-engbers/helpers/dataLayerHelper/updateItemDatalayerHelper';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { checkLineItemDuplicates } from '@frontastic-engbers/helpers/utils/checkLineItemDuplicates';
import { Discount } from '@frontastic-engbers/types/cart/Discount';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import { IFTLinkReference, IFTPageFolderReference, MediaType } from '@frontastic-engbers/types/engbers-custom';
import DiscountForm from '../commercetools-ui/discount-form';
import Spinner from '../commercetools-ui/spinner';
import ItemList from './itemList';
import { OrderSummary } from '@engbers/components/online-shops';
import { IconCustom, Image, LoadingSpinner } from '@engbers/components';
import styles from './cart.module.scss';
import { calculateCartLineItemsPrice } from '@frontastic-engbers/helpers/dataLayerHelper/calculateCartLineItemsPrice';
export interface Props {
  emptyCartText: string;
  continueShoppingLabel: string;
  ctaLabel: string;
  ctaBgColor?: string;
  ctaTextColor?: string;
  cartTitle: string;
  productOverviewArticle: string;
  productOverviewDesc: string;
  productOverviewAmount: string;
  productOverviewSum: string;
  voucherSection: {
    voucherInstruction: string;
    voucherHeadline: string;
    voucherInfo: string;
    voucherRedeemBtn: string;
    voucherRedeemedLabel?: string;
    voucherInvalidLabel?: string;
  };
  shippingTime: string;
  shippingInfo: string;
  serviceShippingCostsNote: string;
  fallbackShippingCostsDe: number;
  fallbackShippingCostsEu: number;
  shippingCostsNote: string;
  TSIcon?: MediaType;
  TSText?: string;
  SSLIcon?: MediaType;
  SSLText?: string;
  pathSizeSuggestion?: IFTLinkReference | IFTPageFolderReference;
}
const Cart = ({
  emptyCartText,
  continueShoppingLabel,
  ctaLabel,
  ctaBgColor,
  ctaTextColor,
  cartTitle,
  productOverviewArticle,
  productOverviewDesc,
  productOverviewAmount,
  productOverviewSum,
  shippingTime,
  shippingInfo,
  serviceShippingCostsNote,
  fallbackShippingCostsDe,
  fallbackShippingCostsEu,
  shippingCostsNote,
  voucherSection,
  TSIcon,
  TSText,
  SSLIcon,
  SSLText,
  pathSizeSuggestion
}: Props) => {
  const {
    getTitle
  } = useImageSEO();
  const {
    loggedIn
  } = useAccount();
  const {
    data: cart,
    isLoading,
    addItem,
    removeItem,
    updateItem,
    removeDiscountCode,
    undoRefuseGift
  }: UseCart = useCart();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const noItems = !isLoading && !cart?.lineItems || cart?.lineItems?.length < 1;
  const hasOnlyGiftCard = cart?.lineItems?.every(item => item.variant.attributes.IsGiftcard);
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const router = useRouter();
  const handleUpdate = async (fn: () => Promise<boolean>) => {
    setIsUpdating(true);
    const continueLoading = await fn();
    setIsUpdating(continueLoading);
  };
  const updateItemQuantity = (lineItem: LineItem, newQuantity: number) => {
    updateItemDatalayerPush(lineItem, newQuantity);
    return handleUpdate(async () => {
      await updateItem(lineItem.lineItemId, newQuantity);
      return false;
    });
  };
  const updateItemSize: UseCart['addItem'] = (variant, quantity, shouldMutate) => handleUpdate(async () => {
    await addItem(variant, quantity, shouldMutate);
    return !shouldMutate;
  });
  const addRefusedGift: UseCart['undoRefuseGift'] = refusedGift => handleUpdate(async () => {
    await undoRefuseGift(refusedGift);
    return false;
  });
  const deleteItem = (lineItem: LineItem) => {
    const total = Number(((lineItem.totalPrice.centAmount ?? 0) / 10 ** lineItem.price.fractionDigits).toFixed(lineItem.price.fractionDigits));
    new TagManager().removeFromCartEvent([ItemMapper.lineItemToItem(lineItem, lineItem.count)], total).executePush();

    // prevent position split by discount being removed completely when removing the cloned position
    const duplicateCheck = checkLineItemDuplicates(cart, lineItem);
    if (duplicateCheck.occurrences >= 2) {
      return handleUpdate(async () => {
        await updateItemQuantity(lineItem, duplicateCheck.count - lineItem.count);
        return false;
      });
    }
    return handleUpdate(async () => {
      await removeItem(lineItem.lineItemId);
      return false;
    });
  };
  const goToPage = (_url: string) => router.push(_url);
  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await goToPage(loggedIn ? '/checkout' : '/checkout/login');
    const datalayerCouponCodes = joinDiscounts(cart.discountCodes, cart.customLineItems);
    const datalayerItems = cart?.lineItems?.map((lineItem: LineItem, index) => ItemMapper.lineItemToItem(lineItem, lineItem.count, index, lineItem.variant, true));
    new TagManager().ecommerceEvent('begin_checkout', datalayerItems, calculateCartLineItemsPrice(cart) ?? cart.sum, datalayerCouponCodes).executePush();
  };
  useEffect(() => {
    if (cart?.lineItems) {
      const lineItemsTotalValue = cart.lineItems.reduce((price, lineItem) => price + (lineItem.variant?.discountedPrice?.centAmount ?? lineItem.variant?.price?.centAmount ?? 0) * lineItem.count, 0);
      const lineItemsTotalValueMoney = {
        fractionDigits: cart.sum.fractionDigits ?? 2,
        centAmount: lineItemsTotalValue,
        currency: cart.sum.currencyCode ?? 'EUR'
      };
      const datalayerCouponCodes = joinDiscounts(cart.discountCodes, cart.customLineItems);
      const datalayerItems = cart?.lineItems?.map((lineItem: LineItem, index) => ItemMapper.lineItemToItem(lineItem, lineItem.count, index, lineItem.variant, true));
      new TagManager().customEvent('ecomm', {
        ecomm_pagetype: 'cart',
        ecomm_prodid: cart.lineItems.map(lineItem => lineItem.variant.sku.split(/[-.]/)[0]),
        ecomm_totalvalue: lineItemsTotalValue / 100
      }).ecommerceEvent('view_cart', datalayerItems, lineItemsTotalValueMoney, datalayerCouponCodes).executePush();
    }
  }, [cart]);
  if (isLoading) {
    return <div className="flex items-stretch justify-center px-12 py-20">
        <Spinner />
      </div>;
  }
  const CTASection = (hasBorder = false) => <div className={classNames(styles.ctaSectionWrap, hasBorder ? styles.hasBorder : undefined)} data-sentry-component="CTASection" data-sentry-source-file="index.tsx">
      {continueShoppingLabel ? <button className={classNames('btn', styles.continueShopping)} onClick={() => router.back()}>
          <IconCustom width={12} icon="ChevronRightIcon" className="mr-1" />
          {continueShoppingLabel}
        </button> : null}
      {ctaLabel && !noItems && <button className={classNames('cta', styles.ctaBtn)} onClick={handleClick} style={{
      backgroundColor: ctaBgColor ? ctaBgColor : undefined,
      color: ctaTextColor ? ctaTextColor : undefined
    }}>
          {ctaLabel}
          <IconCustom width={12} color={ctaTextColor ? 'custom' : 'white'} icon="ChevronRightIcon" customColor={ctaTextColor ? ctaTextColor : undefined} className="mr-1" />
        </button>}
    </div>;
  return <main data-sentry-component="Cart" data-sentry-source-file="index.tsx">
      <LoadingSpinner isLoading={isUpdating} data-sentry-element="LoadingSpinner" data-sentry-source-file="index.tsx" />
      {CTASection(true)}
      <div className={styles.cartTitle}>
        <h2>{cartTitle}</h2>
      </div>
      {noItems ? <div className={styles.emptyCart}>{emptyCartText}</div> : <>
          <div className={styles.orderOverviewHeadlines}>
            <div style={{
          width: '20%'
        }}>{productOverviewArticle}</div>
            <div style={{
          width: '80%'
        }} className={styles.productOverviewInfoWrap}>
              <div style={{
            width: '60%'
          }}>{productOverviewDesc}</div>
              <div style={{
            width: '20%'
          }}>{productOverviewAmount}</div>
              <div style={{
            width: '20%'
          }}>{productOverviewSum}</div>
            </div>
          </div>
          <div className={styles.lineItemsWrap}>
            <ItemList updateItemQuantity={updateItemQuantity} updateItemSize={updateItemSize} deleteItem={deleteItem} undoRefuseGift={addRefusedGift} setIsUpdating={setIsUpdating} shippingTime={shippingTime} pathSizeSuggestion={pathSizeSuggestion} />
          </div>
          <div className={styles.voucherWrap}>
            <DiscountForm voucherSection={{
          ...voucherSection,
          discounts: discounts,
          setDiscounts: setDiscounts
        }} setLoading={setIsUpdating} />
          </div>
          <div className={styles.orderSummaryWrap}>
            {!hasOnlyGiftCard && <div className={styles.shippingWrapper}>
                {shippingTime || shippingInfo ? <>
                    <span className={styles.shippingHeadline}>{formatMessage({
                id: 'delivery'
              })}:</span>
                    <span>{shippingTime}</span>
                    <span className={styles.shippingInfo}>{shippingInfo}</span>
                  </> : null}
              </div>}
            <div className={styles.tsWrapper}>
              {TSIcon?.media && TSText && <div className={styles.tsIconWrapper}>
                  <Image src={TSIcon.media.file} alt={getTitle(TSIcon)} title={getTitle(TSIcon)} />
                  <span>{TSText}</span>
                </div>}
              {SSLIcon?.media && SSLText && <div className={styles.tsIconWrapper}>
                  <Image src={SSLIcon.media.file} alt={getTitle(SSLIcon)} title={getTitle(SSLIcon)} />
                  <span>{SSLText}</span>
                </div>}
            </div>
            <section>
              <OrderSummary shippingCostsNote={shippingCostsNote} removeDiscountCode={removeDiscountCode} serviceShippingCostsNote={serviceShippingCostsNote} fallbackShippingCostsDe={fallbackShippingCostsDe} fallbackShippingCostsEu={fallbackShippingCostsEu} />
            </section>
          </div>
          {CTASection()}
        </>}
    </main>;
};
export default Cart;