import useSWR from 'swr';
import { fetchApiHub } from '@frontastic-engbers/lib';
import { LocationSearchParams } from '@frontastic-engbers/types/engbers-custom';

export const getStoresByLocation = async (storeSearchData: LocationSearchParams, type: string) => {
  return await fetchApiHub(
    '/action/stores/getStoresByLocation',
    { method: 'POST' },
    {
      search: storeSearchData,
      type,
    },
  );
};

export const useGetStores = (
  { search, city }: { search?: string; city?: string },
  type: string,
  assortment: string[],
) => {
  return useSWR(`/action/stores/getStoresByLocation/${search}/${city}/${assortment}`, () => {
    return fetchApiHub(
      '/action/stores/getStoresByLocation',
      { method: 'POST' },
      {
        search,
        city,
        type,
        assortment,
      },
    );
  });
};

export const useStoresAvailability = (search: string, productId: string, assortment: string) => {
  return useSWR(`/action/stores/getStoresAvailability/${search}`, () => {
    if (!search) {
      return [];
    }

    return fetchApiHub(
      '/action/stores/getStoresAvailability',
      { method: 'POST' },
      {
        search,
        productId,
        assortment,
      },
    );
  });
};

export const useAvailableCitiesAndAssortments = (type: string, assortment: string | string[]) => {
  return useSWR(`/action/stores/getAvailableCitiesAndAssortments/${type}`, () => {
    return fetchApiHub(
      '/action/stores/getAvailableCitiesAndAssortments',
      { method: 'POST' },
      {
        type,
        assortment,
      },
    );
  });
};
