import React from 'react';
import Link from 'next/link';
import classnames from 'classnames';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useProductOutfitTitle } from '@frontastic-engbers/helpers/seoHelper';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';
import { IconCustom, WishlistButton } from '@engbers/components';
import { ProductBoxImage } from '../product-box/product-box-image';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import styles from './outfit-product-box.module.scss';
export interface IOutfitProductBox {
  outfit?: Outfit;
  pageNumber?: number;
  isOutfitSlider?: boolean;
  fallbackImage?: MediaType;
}
export const OutfitProductBox: React.FC<IOutfitProductBox> = ({
  outfit,
  pageNumber,
  isOutfitSlider = false,
  fallbackImage
}) => {
  const {
    t
  } = useI18n(true);
  const {
    formatMessage
  } = useFormat({
    name: 'product'
  });
  const {
    name,
    slug,
    variants
  } = outfit;
  const {
    title
  } = useProductOutfitTitle({
    product: outfit,
    attributes: outfit.attributes,
    isOutfit: true
  });
  const setLastVisitedProduct = () => {
    sessionStorage.setItem('storage_last_visited_product', JSON.stringify({
      id: variants[0].sku
    }));
  };
  return <div className={classnames(styles.outfitProductBox, {
    [styles.outfitSliderProductBox]: isOutfitSlider
  })} id={variants[0].sku} data-page-number={pageNumber} data-sentry-component="OutfitProductBox" data-sentry-source-file="index.tsx">
      <WishlistButton sku={variants[0].sku} wrapperClassName={styles.outfitWishlistWrapper} data-sentry-element="WishlistButton" data-sentry-source-file="index.tsx" />
      <Link href={t(slug)} data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <a onClick={setLastVisitedProduct}>
          <ProductBoxImage images={variants[0]?.images.length == 0 ? [fallbackImage?.media?.file] : variants[0]?.images} title={title} isHovering={false} data-sentry-element="ProductBoxImage" data-sentry-source-file="index.tsx" />
        </a>
      </Link>

      <div className={classnames(styles.outfitProductBoxInfo, {
      [styles.outfitSliderProductBoxInfo]: isOutfitSlider
    })}>
        <div>{t(name)}</div>
        <Link href={t(slug)} data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <a className={styles.outfitProductBoxInfoLink} onClick={setLastVisitedProduct}>
            {formatMessage({
            id: isOutfitSlider ? 'shopOutfit' : 'toOutfit',
            defaultMessage: isOutfitSlider ? 'Outfit shoppen' : 'Zum Outfit'
          })}
            <IconCustom icon="ChevronRightIcon" width={11} data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
            {isOutfitSlider && <IconCustom icon="ChevronRightIcon" width={11} />}
          </a>
        </Link>
      </div>
    </div>;
};