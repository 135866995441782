export const PAYONE_PAYMENTS = {
  creditCard: {
    paymentProducts: [
      {
        id: 1,
        brand: 'VISA',
      },
      {
        id: 2,
        brand: 'American Express',
      },
      {
        id: 3,
        brand: 'MasterCard',
      },
      {
        id: 117,
        brand: 'Maestro',
      },
      {
        id: 125,
        brand: 'JCB',
      },
      {
        id: 132,
        brand: 'Diners',
      },
    ],
    redirectPayment: false,
    isIframePayment: true,
  },
  paypal: {
    paymentProducts: [
      {
        id: 840,
        brand: 'PAYPAL',
      },
    ],
    redirectPayment: true,
    isIframePayment: false,
  },
};

export const PAYONE_STATUS = {
  INCOMPLETE_OR_INVALID: 0,
  CANCELLED_BY_CLIENT: 1,
  AUTHORIZATION_DECLINED: 2,
  ORDER_STORED: 4,
  WAITING_CLIENT_PAYMENT: 41,
  WAITING_AUTHENTICAION: 46,
  AUTHORIZED: 5,
  AUTHORIZED_WAITING_EXTERNAL_RESULT: 50,
  AUTHORIZATION_WAITING: 51,
  AUTHORIZATION_NOT_KNOWN: 52,
  AUTHORIZED_AND_CANCELLED: 6,
  AUTHORIZATION_DELETION_WAITING: 61,
  AUTHORIZATION_DELETION_UNCERTAIN: 62,
  AUTHORIZATION_DELETION_REFUSED: 63,
  PAYMENT_DELETED: 7,
  PAYMENT_DELETION_PENDING: 71,
  PAYMENT_DELETION_UNCERTAIN: 72,
  PAYMENT_DELETION_REFUSED: 73,
  REFUND: 8,
  REFUND_PENDING: 81,
  REFUND_UNCERTAIN: 82,
  REFUND_REFUSED: 83,
  PAYMENT_DECLINED_BY_ACQUIRER: 84,
  PAYMENT_REQUESTED: 9,
  PAYMENT_PROCESSING: 91,
  PAYMENT_UNCERTAIN: 92,
  PAYMENT_REFUSED: 93,
  BEING_PROCESSED: 99,
};
