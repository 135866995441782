import { EcommerceDataLayerItem } from '@frontastic-engbers/types/tagmanager/EcommerceDataLayerItem';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { Money } from '@frontastic-engbers/types/product/Money';
import { Translation } from '@frontastic-engbers/helpers/hooks/useI18n';
import { ProductUtils } from '@frontastic-engbers/helpers/utils/productUtils';
import { Discount } from '@frontastic-engbers/types/cart/Discount';

type Translator = (input: Translation | string) => string;

export class ItemMapper {
  static readonly ATTRIBUTE_BRAND = 'DesignerName';
  static readonly ATTRIBUTE_VARIANT = 'Sizing';
  static readonly ATTRIBUTE_CATEGORY = 'ProductGroupDescriptionWithoutPurchGroup';
  static readonly ATTRIBUTE_CATEGORY2 = 'ProductGroup';

  static readonly DEFAULT_CURRENCY = 'EUR';
  static readonly DEFAULT_OUTFIT_BRAND = 'engbers';
  static readonly DEFAULT_OUTFIT_CATEGORY = 'Outfit';
  static readonly DEFAULT_OUTFIT_CATEGORY2 = 'Outfit';

  // todo - remove source specific function calls
  public static commercetoolsProductToItem(
    product: Product,
    variant: Variant,
    quantity?: number,
    setVariantInfo?: boolean,
    index?: number,
  ): EcommerceDataLayerItem {
    return ItemMapper.productToItem(product, quantity, index, variant, setVariantInfo);
  }

  public static lineItemToItem(
    lineItem: LineItem,
    quantity?: number,
    index?: number,
    variant?: Variant,
    setVariantInfo?: boolean,
  ): EcommerceDataLayerItem {
    variant = variant ?? lineItem.variant;
    if (lineItem.discounts?.length > 0) {
      const discountedCentAmount =
        lineItem.price.centAmount -
        lineItem.discounts.reduce((sum: number, discount: Discount) => sum + discount.discountedAmount.centAmount, 0);
      variant['discountedPrice'] = {
        fractionDigits: lineItem.price?.fractionDigits ?? 2,
        centAmount: discountedCentAmount,
        currencyCode: lineItem.price?.currencyCode ?? 'EUR',
      };
    }
    return ItemMapper.getDataLayerItem(lineItem, variant, quantity, index, setVariantInfo);
  }

  public static productToItem(
    product: Product,
    quantity?: number,
    index?: number,
    variant?: Variant,
    setVariantInfo?: boolean,
    listName?: string,
  ): EcommerceDataLayerItem {
    if (variant === undefined) {
      variant = product.variants[0];
    }

    return ItemMapper.getDataLayerItem(product, variant, quantity, index, setVariantInfo, listName);
  }

  private static getDataLayerItem(
    baseItem: Product | LineItem,
    variant?: Variant,
    quantity?: number,
    index?: number,
    setVariantInfo?: boolean,
    listName?: string,
  ): EcommerceDataLayerItem {
    const product = ProductUtils.isProduct(baseItem) ? baseItem : undefined;
    const defaultAttributes: string[] = ProductUtils.isOutfit(baseItem)
      ? [ItemMapper.DEFAULT_OUTFIT_BRAND, ItemMapper.DEFAULT_OUTFIT_CATEGORY, ItemMapper.DEFAULT_OUTFIT_CATEGORY2]
      : [];

    const ecomLayerItem: EcommerceDataLayerItem = {
      item_id: baseItem.productId,
      item_name: baseItem.name,
      item_brand: ItemMapper.getAttributeValue(ItemMapper.ATTRIBUTE_BRAND, product, variant, defaultAttributes[0]),
      item_category: ItemMapper.getAttributeValue(
        ItemMapper.ATTRIBUTE_CATEGORY,
        product,
        variant,
        defaultAttributes[1],
      ),
      item_category2: ItemMapper.getAttributeValue(
        ItemMapper.ATTRIBUTE_CATEGORY2,
        product,
        variant,
        defaultAttributes[2],
      ),
      quantity: (quantity ?? 0) > 0 ? quantity : undefined,
      currency: variant?.price?.currencyCode ?? ItemMapper.DEFAULT_CURRENCY,
    };

    if (variant?.price) {
      const price = ItemMapper.convertCentAmount(variant?.price);
      const discounted = ItemMapper.convertCentAmount(variant?.discountedPrice);

      ecomLayerItem['currency'] = variant.price.currencyCode;
      ecomLayerItem['price'] = discounted || price;

      if (discounted > 0) {
        ecomLayerItem['discount'] = ItemMapper.convertCentAmount({
          fractionDigits: variant.price.fractionDigits,
          centAmount: variant.price.centAmount - variant.discountedPrice?.centAmount,
          currencyCode: variant.price.currencyCode,
        });
      }
    }

    if (index) {
      ecomLayerItem['index'] = index;
    }

    if (listName) {
      ecomLayerItem['item_list_name'] = listName;
    }

    if (setVariantInfo) {
      ecomLayerItem['item_id'] = variant?.sku;
      ecomLayerItem['item_variant'] = ItemMapper.getAttributeValue(ItemMapper.ATTRIBUTE_VARIANT, product, variant);
    }

    return ecomLayerItem;
  }

  private static getAttributeValue(
    attributeName: string,
    product?: Product,
    variant?: Variant,
    defaultValue?: string,
  ): string {
    const value = variant?.attributes?.[attributeName] ?? product?.attributes?.[attributeName] ?? defaultValue;
    return Array.isArray(value) ? value.join(',') : value;
  }

  public static convertCentAmount(price: Money | undefined): number {
    const fractionDigits = price?.fractionDigits ?? 2;
    return Number(((price?.centAmount ?? 0) / 10 ** fractionDigits).toFixed(fractionDigits));
  }
}
