import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { CheckoutData } from '@frontastic-engbers/types/engbers-custom';
import { Address } from '@frontastic-engbers/types/account/Address';
import isoAlpha2Data from '@frontastic-engbers/helpers/utils/iso-alpha2-data';
import { getSelectedShippingAddress } from '@frontastic-engbers/helpers/utils/getShippingInfo';
import { validateBillingAddress, validatePayment, validateShippingAddress } from '../../../utils/requiredDataIsValid';
import styles from './address-overview.module.scss';
interface IAddressOverview {
  checkoutData: CheckoutData;
  overviewBillingAddressHeadline: string;
  overviewShippingAddressHeadline: string;
  overviewDeliveryTimeHeadline: string;
  overviewPaymentHeadline: string;
  overviewDeliveryTimeDE: string;
  overviewDeliveryTimeEU: string;
  billingAddressSectionRef?: HTMLDivElement;
  shippingSectionRef?: HTMLDivElement;
  paymentSectionRef?: HTMLDivElement;
  overviewSectionRef?: HTMLDivElement;
  hasOnlyGiftCard?: boolean;
  updateFormInput: (propName: string, newValue: string | boolean) => void;
}
export const AddressOverview: React.FC<IAddressOverview> = ({
  checkoutData,
  overviewBillingAddressHeadline,
  overviewShippingAddressHeadline,
  overviewDeliveryTimeHeadline,
  overviewPaymentHeadline,
  overviewDeliveryTimeDE,
  overviewDeliveryTimeEU,
  billingAddressSectionRef,
  shippingSectionRef,
  paymentSectionRef,
  hasOnlyGiftCard,
  updateFormInput
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'checkout'
  });
  const selectedShippingAddress = getSelectedShippingAddress(checkoutData);
  const getAddress = (address: Address, checkoutData?: CheckoutData): JSX.Element => <div data-sentry-component="getAddress" data-sentry-source-file="index.tsx">
      {checkoutData?.shippingMethod === 'store' ? <>
          <div>
            {checkoutData.billingAddress.firstName} {checkoutData.billingAddress.lastName}
          </div>
          <div>{address.company}</div>
        </> : checkoutData?.shippingMethod === 'packStation' ? <div>
          {checkoutData.billingAddress.firstName} {checkoutData.billingAddress.lastName} {address.pOBox}
        </div> : checkoutData?.shippingMethod === 'shippingAddress' ? <>
          <div>{address.company}</div>
          <div>
            {address.firstName} {address.lastName}
          </div>
        </> : <div>
          {address.firstName} {address.lastName}
        </div>}
      <div>
        {address.streetName} {address.streetNumber}
      </div>
      <div>
        {address.postalCode} {address.city}
      </div>
      <div>{isoAlpha2Data[address.country] || address.country}</div>
    </div>;
  const getPaymentMethod = (): JSX.Element => {
    const payments = {
      creditCard: 'Kreditkarte',
      debit: 'Lastschrift',
      paypal: 'PayPal',
      invoice: 'Rechnung',
      prepayment: 'Vorauskasse'
    };
    return <div data-sentry-component="getPaymentMethod" data-sentry-source-file="index.tsx">{payments[checkoutData.paymentMethod]}</div>;
  };
  const getScrollToButton = (element: HTMLDivElement, label: string): JSX.Element => <button className={styles.scrollToButton} onClick={() => {
    if (shippingSectionRef === element) {
      updateFormInput('isUnselectedShippingAddress', true);
    }
    element.scrollIntoView({
      behavior: 'smooth'
    });
  }} data-sentry-component="getScrollToButton" data-sentry-source-file="index.tsx">
      {label}
    </button>;
  return <div className={styles.addressOverviewWrap} data-sentry-component="AddressOverview" data-sentry-source-file="index.tsx">
      <div className={styles.addressOverviewBlock}>
        <strong>{overviewBillingAddressHeadline}</strong>
        {validateBillingAddress(checkoutData) ? getAddress(checkoutData.billingAddress) : getScrollToButton(billingAddressSectionRef, formatMessage({
        id: 'chooseBillingAddress'
      }))}
      </div>
      <div className={styles.addressOverviewBlock}>
        <strong>{overviewShippingAddressHeadline}</strong>
        {checkoutData.shippingMethod === 'billingAddress' ? formatMessage({
        id: 'sameAsBillingAddress'
      }) : selectedShippingAddress && validateShippingAddress(checkoutData) ? getAddress(selectedShippingAddress, checkoutData) : getScrollToButton(shippingSectionRef, formatMessage({
        id: 'chooseShippingAddress'
      }))}
      </div>
      {!hasOnlyGiftCard && <div className={styles.addressOverviewBlock}>
          <strong>{overviewDeliveryTimeHeadline}</strong>
          <div>
            {selectedShippingAddress?.country === 'DE' || selectedShippingAddress?.country === isoAlpha2Data['DE'] ? overviewDeliveryTimeDE : overviewDeliveryTimeEU}
          </div>
        </div>}
      <div className={styles.addressOverviewBlock}>
        <strong>{overviewPaymentHeadline}</strong>
        <div>
          {validatePayment(checkoutData) ? getPaymentMethod() : getScrollToButton(paymentSectionRef, formatMessage({
          id: 'choosePaymentMethod'
        }))}
        </div>
      </div>
    </div>;
};