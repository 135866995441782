import React from 'react';
import classnames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { Product } from '@frontastic-engbers/types/product/Product';
import { ShippingCostsTooltip, WishlistButton } from '@engbers/components';
import styles from './pdp-add-to-cart.module.scss';
export interface IPdpAddToCart {
  handleAddToCart: () => Promise<void>;
  sizesModal: () => void;
  selectedVariant: Variant;
  loading: boolean;
  addToCartBtnLabel: string;
  shippingCostsNote: string;
  sku: string;
  product: Product;
  preventGiftCardBuy: boolean;
  notBuyableText: string;
}
export const PdpAddToCart: React.FC<IPdpAddToCart> = ({
  handleAddToCart,
  sizesModal,
  selectedVariant,
  loading,
  addToCartBtnLabel,
  shippingCostsNote,
  sku,
  product,
  preventGiftCardBuy,
  notBuyableText
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'product'
  });
  return <div className={styles.productAddToCart} data-sentry-component="PdpAddToCart" data-sentry-source-file="index.tsx">
      <div className={styles.addToCartActions}>
        <button type="button" className={classnames('cta', styles.addToCartButton)} disabled={loading || preventGiftCardBuy} onClick={!loading ? selectedVariant ? handleAddToCart : sizesModal : undefined}>
          {addToCartBtnLabel || formatMessage({
          id: 'addToCart',
          defaultMessage: 'In den Warenkorb'
        })}
        </button>
        <WishlistButton sku={sku} isCtaButton wrapperClassName={styles.wishlistWrapper} product={product} data-sentry-element="WishlistButton" data-sentry-source-file="index.tsx" />
      </div>
      {preventGiftCardBuy && notBuyableText && <span className={styles.notBuyableText}>{notBuyableText}</span>}
      <ShippingCostsTooltip className={styles.shippingCostsNote} shippingCostsHint={shippingCostsNote} tooltipId="pdp" data-sentry-element="ShippingCostsTooltip" data-sentry-source-file="index.tsx" />
    </div>;
};