import useSWR from 'swr';
import { GetOpenDiscountResponse } from '@frontastic-engbers/types/account/Account';
import { fetchApiHub, revalidateOptions } from '@frontastic-engbers/lib';
import { IconCustom, LoadingIndicatorInline, Markdown } from '@engbers/components';
import styles from './account-balance.module.scss';
interface IAccountBalance {
  heading: string;
  textSize?: number;
  label: string;
}
export const useAccountBalance = () => {
  const result = useSWR<GetOpenDiscountResponse>('/action/account/getOpenDiscount', fetchApiHub, revalidateOptions);
  if (!result.data?.openDiscount) {
    return {
      loaded: false
    };
  }
  return {
    loaded: true,
    balance: result.data.openDiscount
  };
};
export const AccountBalance: React.FC<IAccountBalance> = ({
  heading,
  textSize,
  label
}) => {
  const {
    loaded,
    balance
  } = useAccountBalance();
  return <div data-sentry-component="AccountBalance" data-sentry-source-file="index.tsx">
      <div className={styles.headingWrap}>
        <IconCustom color="primary" icon={'Card'} width={40} data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
        <Markdown text={heading} textSize={textSize} disableMargin data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
      </div>
      <div className={styles.balanceWrap}>
        <span>{label}</span>
        {!loaded ? <LoadingIndicatorInline color="blue" /> : <span className={styles.balance}>{balance}€</span>}
      </div>
    </div>;
};