import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import className from 'classnames';
import Sticky from 'react-sticky-el';
import { SidebarNavigation } from '@engbers/components/online-shops/sidebar-navigation';
import styles from './content-sidebar-navigation.module.scss';
import { Markdown } from '@engbers/components';
export const ContentSidebarNavigationTastic = ({
  data
}) => {
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const [forceIsHidden, setForceIsHidden] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    maxWidth: 1024
  });
  const topOffset = -100;
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  // This function checks if elements with full width overlap with the sticky navigation exist
  // if so, it will hide the navigation
  const checkFullWidthIntersection = useCallback(() => {
    const navigationElement = document.getElementsByClassName(styles.sticky)[0];
    if (!navigationElement) {
      return false;
    }
    const navigation = navigationElement?.getBoundingClientRect();
    const parentNode = navigationElement.parentNode.parentNode.parentNode.parentNode;
    // All elements with full width
    const fullWidthElements = parentNode.querySelectorAll("[style*='span 12 / span 12']");
    let hasOverlap = false;
    Array.from(fullWidthElements).forEach((element, elementIndex) => {
      // We assume that the first element is the navigation itself
      if (elementIndex === 0) {
        return;
      }
      const fullWidthBoundingBox = element?.getBoundingClientRect();
      const overlap = !(navigation.bottom < fullWidthBoundingBox.top || navigation.top > fullWidthBoundingBox.bottom);
      hasOverlap = hasOverlap ? true : overlap;
    });
    return hasOverlap;
  }, []);
  useEffect(() => {
    const checkFullWidthIntersectionLoop = setInterval(() => {
      if (checkFullWidthIntersection()) {
        setForceIsHidden(true);
      } else {
        setForceIsHidden(false);
      }
    }, 60);
    return () => clearInterval(checkFullWidthIntersectionLoop);
  }, [checkFullWidthIntersection]);
  return <Sticky topOffset={topOffset} stickyClassName={className(styles.sticky, {
    [styles.hiddenNav]: forceIsHidden
  })} wrapperClassName={styles.stickyWrapper} disabled={isMinWidthMedium} boundaryElement={'#mainAreaWrap'} bottomOffset={140} hideOnBoundaryHit data-sentry-element="Sticky" data-sentry-component="ContentSidebarNavigationTastic" data-sentry-source-file="index.tsx">
      {data.headline && <Markdown className="mb-5 mt-3" text={data.headline} textSize={data.textSize} />}
      <SidebarNavigation navTree={data.sidebarNavTree} className={className(styles.contentSidebarNav)} isContentSidebar data-sentry-element="SidebarNavigation" data-sentry-source-file="index.tsx" />
    </Sticky>;
};