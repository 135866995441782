import React, { useEffect, useState } from 'react';
import { Reference } from '@frontastic-engbers/helpers/reference';
import { Product } from '@frontastic-engbers/types/product/Product';
import { EcondaWidgetCredentials } from '@frontastic-engbers/types/engbers-custom';
import { ProductMapper } from '@frontastic-engbers/lib/lib/mappers/productMapper';
import ProductSlider from '../../product-slider';
import LoadingPlaceholder from '@frontastic-engbers/lib/lib/loading-placeholder';
interface Props {
  config: EcondaWidgetCredentials;
  widgetId: string;
  title: string;
  textSize?: number;
  isStartPage?: boolean;
  ctaLabel?: string;
  ctaLink?: Reference;
  contextProductIds?: string[];
  customTitleStyle?: object;
  isTopSellerSlider?: boolean;
  onSelect?: () => void;
}
export const EcondaSlider: React.FC<Props> = ({
  config,
  widgetId,
  title,
  textSize,
  isStartPage = false,
  ctaLabel = null,
  ctaLink = null,
  contextProductIds,
  customTitleStyle,
  isTopSellerSlider,
  onSelect
}) => {
  const accountId = `${config?.clientKey}-${config?.accountId}`;
  const [econda, setEconda] = useState(null);
  const [products, setProducts] = useState<Product[]>(null);
  const [productsFetched, setProductsFetched] = useState<boolean>(true);
  useEffect(() => {
    window.econda.ready(() => {
      setEconda(window.econda);
    });
  }, [typeof window !== 'undefined' && window.econda]);
  useEffect(() => {
    if (econda?.recengine) {
      const request = new econda.recengine.Request({
        accountId,
        widgetId: widgetId,
        autoContext: !contextProductIds,
        ...(contextProductIds && {
          context: {
            products: contextProductIds.map(id => ({
              id
            }))
          }
        }),
        success: response => {
          setProducts(response.getProducts().map(product => ProductMapper.econdaProductToProduct(product)));
        },
        error: () => {
          setProductsFetched(false);
        }
      });
      request.send();
    }
  }, [econda]);
  if (!productsFetched) {
    return null;
  }
  if (productsFetched && products === null) {
    return <LoadingPlaceholder height="550px" />;
  }
  return <ProductSlider products={products} title={title} textSize={textSize} isStartPage={isStartPage} ctaLabel={ctaLabel} ctaLink={ctaLink} customTitleStyle={customTitleStyle} isTopSellerSlider={isTopSellerSlider} onSelect={onSelect} data-sentry-element="ProductSlider" data-sentry-component="EcondaSlider" data-sentry-source-file="index.tsx" />;
};