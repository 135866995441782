import React from 'react';
import classnames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { SaleFlag as Flag } from '@frontastic-engbers/types/product/Flag';
import styles from './sale-flag.module.scss';
type Props = {
  flag: Flag;
  className?: string;
  fractionDigits?: number;
};
export const SaleFlag: React.FC<Props> = ({
  flag,
  className,
  fractionDigits = 0
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'product'
  });
  return <div className={classnames(className, styles.productFlag, styles.sale)} data-sentry-component="SaleFlag" data-sentry-source-file="index.tsx">
      {flag.discountPerc ? <>-{flag.discountPerc.toFixed(fractionDigits)}%</> : <>{formatMessage({
        id: 'sale',
        defaultMessage: '%'
      })}</>}
    </div>;
};