import { getProjectInfo } from '@frontastic-engbers/lib';
import { PageFolder } from '@frontastic-engbers/lib/lib/types';
import { Product } from '@frontastic-engbers/types/product/Product';
import { ParsedUrlQuery } from 'querystring';
import { useRouter } from 'next/router';
import { useFormat } from './hooks/useFormat';

const EXCLUDED_QUERY_PARAMS = ['slug', 'path', 'locale'];
const EXCLUDED_PAGEFOLDER_TYPES = [
  'landingpage',
  'frontastic/product-detail-page',
  'frontastic/outfit-detail-page',
  'frontastic/category',
];

export const getMetaRobotsContent = (query: ParsedUrlQuery, path: string, pageFolder?: PageFolder) => {
  let metaRobots = pageFolder?.configuration?.seoRobots?.length ? pageFolder.configuration.seoRobots : 'index, follow';
  const hasQueryParams = hasQueryParam(query);
  const isProductListWithParams = isProductListWithFilterOrPageParam(path, pageFolder);

  if (
    hasQueryParams ||
    isProductListWithParams ||
    (pageFolder?.pageFolderType && !EXCLUDED_PAGEFOLDER_TYPES.includes(pageFolder.pageFolderType))
  ) {
    metaRobots = 'noindex, follow';
  }

  return metaRobots;
};

const hasQueryParam = (query: ParsedUrlQuery) => {
  return !!Object.keys(query).filter((param) => !EXCLUDED_QUERY_PARAMS.includes(param)).length;
};

const isProductListWithFilterOrPageParam = (path: string, pageFolder?: PageFolder) => {
  const slug = path.replace(/(^\/|\/$)/g, '').split('/');

  return (
    (pageFolder?.pageFolderType === 'landingpage' || pageFolder?.pageFolderType === 'frontastic/category') &&
    slug.length > 0 &&
    (!isNaN(parseInt(slug[slug.length - 1], 10)) || slug[slug.length - 1].startsWith('_'))
  );
};

export const useCanonical = () => {
  const { query } = useRouter();
  const { siteUrl } = getProjectInfo();

  return {
    getCanonical: (href?: string) => {
      if (!href) {
        href = (query.path as string) ?? (query.slug && `/${(query.slug as string[]).join('/')}`) ?? '/';
      }

      return {
        rel: 'canonical',
        key: 'canonical',
        href: siteUrl + href,
      };
    },
  };
};

type ProductOutfitTitleProps = {
  product: Product;
  attributes: Record<string, string>;
  isOutfit?: boolean;
};

export const useProductOutfitTitle = ({ product, attributes = {}, isOutfit = false }: ProductOutfitTitleProps) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const { productId, name } = product;
  const values = {
    id: productId as string,
    name: name as string,
    color: attributes.BaseColor,
    manufacturer: attributes.DesignerName,
  };
  let missingAttributes = '';

  if (!attributes.DesignerName) {
    missingAttributes += '.noManufacturer';
  }

  if (!isOutfit && !attributes.BaseColor) {
    missingAttributes += '.noColor';
  }

  const title = formatMessage({
    id: `seoTitle.${isOutfit ? 'outfit' : 'product'}Image${missingAttributes}`,
    values,
  });

  return {
    title,
  };
};
