import React from 'react';
import { Markdown } from '@engbers/components';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import styles from './card.module.scss';
interface ICard {
  label: string;
  textSize?: number;
  custNr: string;
  background: any;
  barcodeSvg: string;
}
export const Card: React.FC<ICard> = ({
  label,
  textSize,
  custNr,
  barcodeSvg,
  background
}) => {
  const {
    getTitle
  } = useImageSEO();
  return <div className={styles.card} data-sentry-component="Card" data-sentry-source-file="index.tsx">
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img className={styles.image} src={background.media.file} alt={getTitle(background)} title={getTitle(background)} />
      <div className={styles.custNrWrap}>
        <Markdown text={label} textSize={textSize} disableMargin data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
        <span className={styles.custNr}>{custNr}</span>
      </div>
      <div className={styles.barcode}>
        <div dangerouslySetInnerHTML={{
        __html: barcodeSvg
      }} className={styles.barcodeInner} />
      </div>
    </div>;
};