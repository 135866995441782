import React from 'react';
import { Newsletter, Props as NewsletterProps } from '@engbers/components/online-shops/newsletter';
interface Props {
  data: NewsletterProps;
}
const NewsletterTastic: React.FC<Props> = ({
  data
}) => {
  return <Newsletter {...data} data-sentry-element="Newsletter" data-sentry-component="NewsletterTastic" data-sentry-source-file="index.tsx" />;
};
export default NewsletterTastic;