import React from 'react';
import classnames from 'classnames';
import { IFTLinkReference, IFTPageFolderReference, MediaType } from '@frontastic-engbers/types/engbers-custom';
import { useCurrentShop } from '@frontastic-engbers/lib';
import { FooterFirstSection } from './footerFirstSection';
import { FooterSecondSection } from './footerSecondSection';
import { FooterThirdSection } from './footerThirdSection';
import styles from './footer.module.scss';
export interface Props {
  data: DataType;
  isFullWidth?: boolean;
  isEmilio: boolean;
}
export interface DataType {
  emilioFirstSectionBgColor: string;
  firstSectionFontColor: string;
  newsletterText: string;
  newsletterHeadline: string;
  newsletterPlaceholder: string;
  newsletterLink: IFTLinkReference | IFTPageFolderReference;
  socialMediaHeadline: string;
  enGermanyFirstSectionBgColor: string;
  enGermanyFirstSectionFontColor: string;
  enGermanyNewsletterText: string;
  enGermanyNewsletterButtonText: string;
  enGermanyNewsletterPlaceholder: string;
  enGermanySocialMediaHeadline: string;
  socialMediaIcons: SocialMediaType[];
  headerCol1: string;
  textCol1: string;
  imagesCol1: {
    imageCol1: MediaType;
  }[];
  headerCol2: string;
  iconCol2: MediaType;
  linksCol2: LinksCol2Type[];
  headerCol3: string;
  iconCol3: MediaType;
  linksCol3: LinksCol3Type[];
  headerCol4: string;
  iconCol4: MediaType;
  linksCol4: LinksCol4Type[];
  partners: {
    partnersHeader: string;
    partnersIcon: MediaType;
    partnersLinks: PartnersLinksType[];
  }[];
  headerCol5: string;
  iconCol5: MediaType;
  linksCol5: LinksCol5Type[];
  TrustedShopImage: MediaType;
  TrustedShopText: string;
  paymentsHeader: string;
  paymentsIcon: MediaType;
  payments: PaymentsType[];
  copyRightsText: string;
  emilioThirdSectionBgColor: string;
  thirdSectionFontColor: string;
  thirdSectionLogo: MediaType;
  thirdSectionLogoLink: IFTLinkReference | IFTPageFolderReference;
  thirdSectionText: string;
  thirdSectionCopyRight: string;
  enGermanyThirdSectionBgColor: string;
  enGermanyThirdSectionFontColor: string;
  enGermanyThirdSectionLogo: MediaType;
  enGermanyThirdSectionLogoLink: IFTLinkReference | IFTPageFolderReference;
  enGermanyThirdSectionCopyRight: string;
  enGermanyThirdSectionLinks: {
    linkText: string;
    link: IFTLinkReference | IFTPageFolderReference;
  }[];
}
export interface SocialMediaType {
  socialMediaIcon: MediaType;
  socialMediaLink: IFTLinkReference | IFTPageFolderReference;
}
export interface LinksCol2Type {
  nameCol2: string;
  referenceCol2: IFTLinkReference | IFTPageFolderReference;
}
export interface LinksCol3Type {
  nameCol3: string;
  referenceCol3: IFTLinkReference | IFTPageFolderReference;
}
export interface LinksCol4Type {
  nameCol4: string;
  referenceCol4: IFTLinkReference | IFTPageFolderReference;
}
export interface LinksCol5Type {
  nameCol5: string;
  referenceCol5: IFTLinkReference | IFTPageFolderReference;
}
export interface PartnersLinksType {
  partnersName: string;
  partnersReference: IFTLinkReference | IFTPageFolderReference;
}
export interface PaymentsType {
  paymentName: string;
  paymentImage: MediaType;
}
export const Footer: React.FC<Props> = ({
  data,
  isFullWidth = false,
  isEmilio = false
}) => {
  const currentSection = useCurrentShop();
  const isEnGermany = currentSection === 'engbers-germany';
  let secondSectionColumns = 0;
  for (let i = 1; i < 6; i++) {
    if (data[`headerCol${i}`] || data[`imagesCol${i}`]?.length || data[`linksCol${i}`]?.length) {
      secondSectionColumns++;
    }
  }
  return <footer className={classnames(styles.footer, {
    [styles.emilioFooter]: isEmilio
  })} aria-label="footer" data-sentry-component="Footer" data-sentry-source-file="index.tsx">
      <div className={isFullWidth ? styles.footer1FulWidthWrap : undefined} style={{
      backgroundColor: (isFullWidth && (isEmilio && data.emilioFirstSectionBgColor || isEnGermany && data.enGermanyFirstSectionBgColor)) ?? undefined
    }}>
        <FooterFirstSection newsletterText={isEnGermany ? data.enGermanyNewsletterText : data.newsletterText} newsletterHeadline={!isEnGermany && data.newsletterHeadline} newsletterPlaceholder={isEnGermany ? data.enGermanyNewsletterPlaceholder : data.newsletterPlaceholder} enGermanyNewsletterButtonText={data.enGermanyNewsletterButtonText} newsletterLink={data.newsletterLink} socialMediaHeadline={isEnGermany ? data.enGermanySocialMediaHeadline : data.socialMediaHeadline} socialMediaIcons={data.socialMediaIcons} emilioFirstSectionBgColor={data.emilioFirstSectionBgColor} enGermanyFirstSectionBgColor={data.enGermanyFirstSectionBgColor} firstSectionFontColor={isEnGermany ? data.enGermanyFirstSectionFontColor : data.firstSectionFontColor} isEmilio={isEmilio} isEnGermany={isEnGermany} data-sentry-element="FooterFirstSection" data-sentry-source-file="index.tsx" />
      </div>
      <div className={isFullWidth ? classnames(styles.footer2FulWidthWrap, {
      'hidden lg:block': isEnGermany
    }) : undefined}>
        <FooterSecondSection headerCol1={data.headerCol1} headerCol2={data.headerCol2} headerCol3={data.headerCol3} headerCol4={data.headerCol4} headerCol5={data.headerCol5} imagesCol1={data.imagesCol1} textCol1={data.textCol1} iconCol2={data.iconCol2} iconCol3={data.iconCol3} iconCol4={data.iconCol4} iconCol5={data.iconCol5} paymentsIcon={data.iconCol5} linksCol2={data.linksCol2} linksCol3={data.linksCol3} linksCol4={data.linksCol4} linksCol5={data.linksCol5} partners={data.partners} TrustedShopImage={data.TrustedShopImage} TrustedShopText={data.TrustedShopText} paymentsHeader={data.paymentsHeader} payments={data.payments} copyRightsText={data.copyRightsText} columns={secondSectionColumns} isEmilio={isEmilio} data-sentry-element="FooterSecondSection" data-sentry-source-file="index.tsx" />
      </div>
      <div className={isFullWidth ? styles.footer3FulWidthWrap : undefined} style={{
      backgroundColor: (isFullWidth && (isEmilio && data.emilioThirdSectionBgColor || isEnGermany && data.enGermanyThirdSectionBgColor)) ?? undefined
    }}>
        <FooterThirdSection thirdSectionLogo={isEnGermany ? data.enGermanyThirdSectionLogo : data.thirdSectionLogo} thirdSectionLogoLink={isEnGermany ? data.enGermanyThirdSectionLogoLink : data.thirdSectionLogoLink} thirdSectionText={data.thirdSectionText} thirdSectionCopyRight={isEnGermany ? data.enGermanyThirdSectionCopyRight : data.thirdSectionCopyRight} emilioThirdSectionBgColor={data.emilioThirdSectionBgColor} enGermanyThirdSectionBgColor={data.enGermanyThirdSectionBgColor} thirdSectionFontColor={isEnGermany ? data.enGermanyThirdSectionFontColor : data.thirdSectionFontColor} enGermanyThirdSectionLinks={data.enGermanyThirdSectionLinks} isEmilio={isEmilio} isEnGermany={isEnGermany} data-sentry-element="FooterThirdSection" data-sentry-source-file="index.tsx" />
      </div>
    </footer>;
};