import classNames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import styles from './input-toggle.module.scss';
interface IInputToggle {
  isActive?: boolean;
  onChange?: () => void;
}
export const InputToggle: React.FC<IInputToggle> = ({
  isActive,
  onChange
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const label = isActive ? formatMessage({
    id: 'yes'
  }) : formatMessage({
    id: 'no'
  });
  return <div className={classNames(styles.wrap, {
    [styles['isActive']]: isActive
  })} onClick={onChange} data-sentry-component="InputToggle" data-sentry-source-file="index.tsx">
      <div className={styles.knob} />
      <div className={styles.label}>{label}</div>
    </div>;
};