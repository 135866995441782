import { Typography } from '@engbers/components';
import React from 'react';
const StyleGuideTypography: React.FC = () => <div className="dark:bg-encom-900 mb-10 border border-solid border-slate-300 dark:text-white" data-sentry-component="StyleGuideTypography" data-sentry-source-file="style-guide-typography.tsx">
    <h2 className="mx-6 my-4 dark:text-white">Typography</h2>
    <div className="text-2xl-bold py-4 text-center">Heading</div>

    <div className="flex items-center justify-between text-center">
      <div className="my-4 w-[10%]" />
      <div className="my-4 w-[40%]">Default</div>
      <div className="my-4 w-[40%]">Uppercase</div>
    </div>

    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="text-base-bold mx-3 w-[10%] text-center">Heading 1</div>
      <div className="mx-3 w-[40%] text-center">
        <Typography tag="h1" variant="heading-1" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
      <div className="mx-3 w-[40%] text-center">
        <Typography transform="uppercase" tag="h1" variant="heading-1" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
    </div>

    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="text-base-bold mx-3 w-[10%] text-center">Heading 2</div>
      <div className="mx-3 w-[40%] text-center">
        <Typography tag="h2" variant="heading-2" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
      <div className="mx-3 w-[40%] text-center">
        <Typography tag="h2" transform="uppercase" variant="heading-2" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
    </div>

    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="text-base-bold mx-3 w-[10%] text-center">Heading 3</div>
      <div className="mx-3 w-[40%] text-center">
        <Typography tag="h3" variant="heading-3" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
      <div className="mx-3 w-[40%] text-center">
        <Typography transform="uppercase" tag="h3" variant="heading-3" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
    </div>

    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="text-base-bold mx-3 w-[10%] text-center">Heading 4</div>
      <div className="mx-3 w-[40%] text-center">
        <Typography tag="h4" variant="heading-4" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
      <div className="mx-3 w-[40%] text-center">
        <Typography transform="uppercase" tag="h4" variant="heading-4" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
    </div>

    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="text-base-bold mx-3 w-[10%] text-center">Heading 5</div>
      <div className="mx-3 w-[40%] text-center">
        <Typography tag="h5" variant="heading-5" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
      <div className="mx-3 w-[40%] text-center">
        <Typography transform="uppercase" tag="h5" variant="heading-5" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
    </div>

    <div className="text-2xl-bold py-6 text-center">Text</div>

    <div className="flex items-center justify-between text-center">
      <div className="my-4 w-[10%]" />
      <div className="my-4 w-[20%]">Light</div>
      <div className="my-4 w-[20%]">Regular</div>
      <div className="my-4 w-[20%]">Medium</div>
      <div className="my-4 w-[20%]">Bold</div>
    </div>

    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="text-base-bold mx-3 w-[10%] text-center">Text-3xl</div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-3xl-light">The quick brown fox</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-3xl">The quick brown fox</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-3xl-medium">The quick brown fox</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-3xl-bold">The quick brown fox</p>
      </div>
    </div>

    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="text-base-bold mx-3 w-[10%] text-center">Text-2xl</div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-2xl-light">The quick brown fox</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-2xl">The quick brown fox</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-2xl-medium">The quick brown fox</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-2xl-bold">The quick brown fox</p>
      </div>
    </div>

    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="text-base-bold mx-3 w-[10%] text-center">Text-xl</div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-xl-light">The quick brown fox</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-xl">The quick brown fox</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-xl-medium">The quick brown fox</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-xl-bold">The quick brown fox</p>
      </div>
    </div>

    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="text-base-bold mx-3 w-[10%] text-center">Text-lg</div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-lg-light">The quick brown fox</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-lg">The quick brown fox</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-lg-medium">The quick brown fox</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-lg-bold">The quick brown fox</p>
      </div>
    </div>

    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="text-base-bold mx-3 w-[10%] text-center">Text-base</div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-base-light">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-base">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-base-medium">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-base-bold">The quick brown fox jumps over the lazy dog.</p>
      </div>
    </div>

    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="text-base-bold mx-3 w-[10%] text-center">Text-sm</div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-sm-light">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-sm">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-sm-medium">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-sm-bold">The quick brown fox jumps over the lazy dog.</p>
      </div>
    </div>

    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="text-base-bold mx-3 w-[10%] text-center">Text-xs</div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-xs-light">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-xs">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-xs-medium">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-xs-bold">The quick brown fox jumps over the lazy dog.</p>
      </div>
    </div>

    <div className="my-3 flex items-center p-4 align-middle">
      <div className="text-base-bold mx-3 w-[10%] text-center">Link</div>
      <div className="mx-3">
        <a href="style-guide/style-guide-typography#" className="link dark:link-dark">
          The quick brown fox jumps over the lazy dog.
        </a>
      </div>
    </div>

    <div>
      <h4 className="m-6 dark:text-white">Usage:</h4>
      <ol className="mx-10 mb-6 list-inside list-decimal">
        <li className="pb-2">
          Using Typography component (check component for more details). Example:
          <code className="ml-3 mt-1 block bg-gray-100 p-1">
            {'<Typography transform="uppercase" tag="h3" variant="heading-3">Content</Typography>'}
          </code>
        </li>
        <li className="pb-2">
          Using class names.
          <div className="ml-3 mt-1">
            Each font size has 4 different weight types: (Light / Regular / Medium / Bold)
            <code className="ml-3 mt-1 block bg-gray-100 p-1">Light: {'<div class="text-xl-light">Content</div>'}</code>
            <code className="ml-3 mt-1 block bg-gray-100 p-1">Regular: {'<div class="text-xl">Content</div>'}</code>
            <code className="ml-3 mt-1 block bg-gray-100 p-1">
              Medium: {'<div class="text-xl-medium">Content</div>'}
            </code>
            <code className="ml-3 mt-1 block bg-gray-100 p-1">Bold: {'<div class="text-xl-bold">Content</div>'}</code>
          </div>
        </li>
      </ol>
    </div>
  </div>;
export default StyleGuideTypography;