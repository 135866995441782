import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { fetchApiHub, useCart } from '@frontastic-engbers/lib';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { UseCart } from '@frontastic-engbers/lib/provider/frontastic/UseCart';
import { updateSizeDatalayerPush } from '@frontastic-engbers/helpers/dataLayerHelper/updateItemDatalayerHelper';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { checkLineItemDuplicates } from '@frontastic-engbers/helpers/utils/checkLineItemDuplicates';
import { getSKUParts } from '@frontastic-engbers/helpers/utils/getSKUParts';
import { sortSizes } from '@frontastic-engbers/helpers/utils/sizeSorting';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import { ISizeVariant } from './index';
import styles from './cart-line-items.module.scss';
export interface ISizeSelect {
  lineItem: LineItem;
  sizeVariants: {
    [key: string]: ISizeVariant[];
  }[];
  addItem: UseCart['addItem'];
  removeItem: UseCart['removeItem'];
  updateItem: UseCart['updateItem'];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
export const FlyoutCartSizeSelect: React.FC<ISizeSelect> = ({
  lineItem,
  sizeVariants,
  addItem,
  removeItem,
  updateItem,
  setLoading
}) => {
  const {
    data: cart
  } = useCart();
  const [sizeVariant, setSizeVariant] = useState<ISizeVariant[]>([]);
  const {
    formatMessage
  } = useFormat({
    name: 'cart'
  });
  const {
    formatMessage: productFormatMessage
  } = useFormat({
    name: 'product'
  });
  useEffect(() => {
    const currentSizeVariant: ISizeVariant[] = sizeVariants?.map(variant => variant[lineItem.lineItemId]).filter(variant => variant !== undefined)[0]?.sort((a, b) => sortSizes({
      value: a.size
    }, {
      value: b.size
    }));
    if (currentSizeVariant) {
      setSizeVariant(currentSizeVariant);
    }
  }, [sizeVariants]);
  if (!sizeVariant || lineItem?.discounts?.length && !lineItem?.pushCampaigns?.length) {
    return null;
  }
  const handleAddItem = async (sku: string) => {
    setLoading(true);
    const product = await fetchApiHub(`/action/product/getProduct?sku=${sku}`);
    const currentVariant = product?.variants.find(variant => variant.sku === sku);
    const removedVariant = product?.variants.find(variant => variant.sku === lineItem?.variant?.sku);
    if (!currentVariant?.isInStock) {
      return setLoading(false);
    }
    const quantity = lineItem.count <= currentVariant.quantity ? lineItem.count : currentVariant.quantity;
    await addItem(currentVariant, quantity || 1, false);

    // prevent position split by discount being removed completely when changing size of the cloned position
    const duplicateCheck = checkLineItemDuplicates(cart, lineItem);
    if (duplicateCheck.occurrences >= 2) {
      await updateItem(lineItem.lineItemId, duplicateCheck.count - quantity);
    } else {
      await removeItem(lineItem.lineItemId);
    }
    updateSizeDatalayerPush(lineItem, product, currentVariant, quantity);
    new TagManager().econdaCartEvent(product, currentVariant, 'econdaAddToCart', 'c_add', quantity).econdaCartEvent(product, removedVariant, 'econdaRemoveFromCart', 'c_rmv', lineItem.count).executePush();
    setLoading(false);
  };
  return <div data-sentry-component="FlyoutCartSizeSelect" data-sentry-source-file="flyout-cart-size-select.tsx">
      <div className={styles.lineItemSelectWrap}>
        <label className={styles.lineItemSelectLabel}>
          {lineItem.variant.attributes.IsGiftcard ? productFormatMessage({
          id: 'giftCardVariantsLabel',
          defaultMessage: 'Wert:'
        }) : formatMessage({
          id: 'size',
          defaultMessage: 'Größe'
        })}
        </label>
        {sizeVariant.length > 1 ? <select className={styles.lineItemSelect} name={lineItem.variant.sku} value={lineItem.variant.sku} onChange={event => handleAddItem(event.currentTarget.value)}>
            {sizeVariant.map((variant, index) => {
          const size = variant.size || getSKUParts(variant.sku).size;
          return <option key={`size-selection-${index}`} value={variant.sku} disabled={!variant.availableQuantity || variant.availableQuantity <= 0}>
                  {size}
                </option>;
        })}
          </select> : <div className={classnames(styles.lineItemSelect, styles.lineItemSize)}>{sizeVariant[0]?.size}</div>}
      </div>
    </div>;
};