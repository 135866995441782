import React from 'react';
import { IconCustom } from '@engbers/components';
const StyleGuideButtons: React.FC = () => <div className="mb-10 border border-solid border-slate-300 dark:bg-encom-900 dark:text-white" data-sentry-component="StyleGuideButtons" data-sentry-source-file="style-guide-buttons.tsx">
    <h2 className="my-4 mx-6 dark:text-white">Buttons</h2>
    <div className="my-3 flex flex-wrap justify-between border-t border-solid border-slate-300 p-4 align-middle">
      <div className="mx-3 text-center">
        <p>CTA</p>
        <button className="cta dark:cta-dark mt-3">Button</button>
      </div>
      <div className="mx-3 text-center">
        <p>CTA with icon left</p>
        <button className="cta dark:cta-dark mt-3">
          <IconCustom icon="Cart" width={18} className="mr-2" data-sentry-element="IconCustom" data-sentry-source-file="style-guide-buttons.tsx" />
          Button
        </button>
      </div>
      <div className="mx-3 text-center">
        <p>CTA with icon right</p>
        <button className="cta dark:cta-dark mt-3">
          Button
          <IconCustom icon="Cart" width={18} className="ml-2" data-sentry-element="IconCustom" data-sentry-source-file="style-guide-buttons.tsx" />
        </button>
      </div>
      <div className="mx-3 text-center">
        <p>CTA icon</p>
        <button className="cta dark:cta-dark mt-3">
          <IconCustom icon="Cart" width={20} data-sentry-element="IconCustom" data-sentry-source-file="style-guide-buttons.tsx" />
        </button>
      </div>
    </div>

    <div className="my-3 flex flex-wrap justify-between border-t border-solid border-slate-300 p-4 align-middle">
      <div className="mx-3 text-center">
        <p>Primary</p>
        <button className="primary dark:primary-dark mt-3">Button</button>
      </div>
      <div className="mx-3 text-center">
        <p>Primary with icon left</p>
        <button className="primary dark:primary-dark mt-3">
          <IconCustom icon="Cart" width={18} className="mr-2" data-sentry-element="IconCustom" data-sentry-source-file="style-guide-buttons.tsx" />
          Button
        </button>
      </div>
      <div className="mx-3 text-center">
        <p>Primary with icon right</p>
        <button className="primary dark:primary-dark mt-3">
          Button
          <IconCustom icon="Cart" width={18} className="ml-2" data-sentry-element="IconCustom" data-sentry-source-file="style-guide-buttons.tsx" />
        </button>
      </div>
      <div className="mx-3 text-center">
        <p>Primary icon</p>
        <button className="primary dark:primary-dark mt-3">
          <IconCustom icon="Cart" width={20} data-sentry-element="IconCustom" data-sentry-source-file="style-guide-buttons.tsx" />
        </button>
      </div>
    </div>

    <div className="my-3 flex flex-wrap justify-between border-t border-solid border-slate-300 p-4 align-middle">
      <div className="mx-3 text-center">
        <p>Secondary</p>
        <button className="secondary dark:secondary-dark mt-3">
          <span className="btn-secondary-text">Button</span>
        </button>
      </div>
      <div className="mx-3 text-center">
        <p>Secondary with icon left</p>
        <button className="secondary dark:secondary-dark mt-3">
          <IconCustom icon="Cart" width={18} className="mr-2" data-sentry-element="IconCustom" data-sentry-source-file="style-guide-buttons.tsx" />
          <span className="btn-secondary-text">Button</span>
        </button>
      </div>
      <div className="mx-3 text-center">
        <p>Secondary with icon right</p>
        <button className="secondary dark:secondary-dark mt-3">
          <span className="btn-secondary-text">Button</span>
          <IconCustom icon="Cart" width={18} className="ml-2" data-sentry-element="IconCustom" data-sentry-source-file="style-guide-buttons.tsx" />
        </button>
      </div>
      <div className="mx-3 text-center">
        <p>Secondary icon</p>
        <button className="secondary dark:secondary-dark mt-3">
          <IconCustom icon="Cart" width={20} data-sentry-element="IconCustom" data-sentry-source-file="style-guide-buttons.tsx" />
        </button>
      </div>
    </div>
  </div>;
export default StyleGuideButtons;