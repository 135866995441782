import { useRecoilCallback } from 'recoil';
import { toastNotificationsState } from './atoms';
import { v4 as uuidv4 } from 'uuid';
import { ReactNode } from 'react';
import { IToastNotifications } from '@frontastic-engbers/types/engbers-custom';

type PushNotification = (
  message: string | ReactNode,
  type?: 'dark' | 'error' | 'success',
  autoremove?: boolean,
) => string;

type RemoveNotification = (id: string) => void;

export interface IToastNotificationsHook {
  pushNotification: PushNotification;
  removeNotification: RemoveNotification;
}

export const useToastNotificationsActions = (): IToastNotificationsHook => {
  /**
   * Notifications are kept (shown) for 5 seconds and then removed.
   */
  const pushNotification = useRecoilCallback(
    ({ set }): PushNotification =>
      (message, type = 'dark', autoremove = true) => {
        const newID: string = uuidv4();

        set(toastNotificationsState, (previousNotifications: IToastNotifications) => {
          return {
            ...previousNotifications,
            [newID]: {
              message,
              type,
            },
          };
        });

        if (autoremove) {
          setTimeout(() => removeNotification(newID), 7000);
        }

        return newID;
      },
  );

  const removeNotification = useRecoilCallback(
    ({ set }): RemoveNotification =>
      (id) => {
        set(toastNotificationsState, (previousNotifications: IToastNotifications) => {
          const stateCopy: IToastNotifications = { ...previousNotifications };

          delete stateCopy[id];

          return stateCopy;
        });
      },
  );

  return {
    pushNotification,
    removeNotification,
  };
};
