import React from 'react';
import classnames from 'classnames';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { Product } from '@frontastic-engbers/types/product/Product';
import { tablet } from '@frontastic-engbers/helpers/utils/screensizes';
import { Slider, SliderProps } from '@engbers/components/slider';
import { ProductBox } from '../product-list/components/product-box';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import styles from './en-germany-product-slider.module.scss';
interface EnGermanySlider {
  products?: Product[];
  isFullWidth: boolean;
  enGermanySliderDelay: number;
  enGermanySubheadline: string;
  enGermanyHeadline: string;
  enGermanyLinkText: string;
  enGermanyLink: IFTLinkReference | IFTPageFolderReference;
}
export const EngbersGermanyProductSlider: React.FC<EnGermanySlider> = ({
  products,
  isFullWidth,
  enGermanySliderDelay,
  enGermanySubheadline,
  enGermanyHeadline,
  enGermanyLinkText,
  enGermanyLink
}) => {
  const sliderConfiguration: SliderProps = {
    arrows: true,
    dots: true,
    loop: true,
    autoplay: enGermanySliderDelay > 0 ? {
      delay: enGermanySliderDelay * 1000,
      disableOnInteraction: false
    } : false,
    breakpoints: {
      0: {
        slidesPerView: 1
      },
      [tablet]: {
        slidesPerView: 3
      }
    }
  };
  if (!products) {
    return null;
  }
  return <div className={classnames(styles.enGermanySliderWrap, {
    'mx-3': isFullWidth
  })} data-sentry-component="EngbersGermanyProductSlider" data-sentry-source-file="index.tsx">
      <div className={styles.caption}>
        {enGermanySubheadline && <div className={classnames('text-lg', styles.subheadline)}>{enGermanySubheadline}</div>}
        {enGermanyHeadline && <div className={classnames('h1', styles.headline)}>{enGermanyHeadline}</div>}
        {enGermanyLinkText && enGermanyLink && <ReferenceLink target={enGermanyLink} className={styles.captionLink}>
            {enGermanyLinkText}
          </ReferenceLink>}
      </div>
      <div className={styles.sliderBackground} />
      <Slider {...sliderConfiguration} data-sentry-element="Slider" data-sentry-source-file="index.tsx">
        {products.map((product, index: number) => <ProductBox key={`en-germany-slider-product-${index}`} product={product} hasHoverEffect={false} supportHover={false} isEnGermany disableFlags />)}
      </Slider>
    </div>;
};