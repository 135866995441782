import { useRecoilCallback } from 'recoil';
import { IModal } from '@frontastic-engbers/types/engbers-custom';
import { disableScrollOnBody } from '@frontastic-engbers/helpers/utils/disableScrollOnBody';
import { modalState } from './atoms';

export interface IFieldActionsHook {
  pushModal: (modal: IModal) => void;
  removeModal: () => void;
}

export const useModalActions = (): IFieldActionsHook => {
  const pushModal = useRecoilCallback(({ set }) => async (modal: IModal) => {
    const disableBgScroll = modal.disableBgScroll ?? true;

    set(modalState, modal);
    disableScrollOnBody(disableBgScroll);
  });
  const removeModal = useRecoilCallback(({ set }) => async () => {
    set(modalState, undefined);
    disableScrollOnBody(false);
  });

  return {
    pushModal,
    removeModal,
  };
};
