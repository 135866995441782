import React from 'react';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import styles from './popular-category.module.scss';
export interface ISuggestedSearch {
  item: {
    categoryLabel: string;
    categoryLink: IFTLinkReference | IFTPageFolderReference;
  };
  onSelect: () => void;
}
export const PopularCategory: React.FC<ISuggestedSearch> = ({
  item,
  onSelect
}) => {
  if (!item.categoryLabel || !item.categoryLink) {
    return null;
  }
  return <li className={styles.category} data-sentry-component="PopularCategory" data-sentry-source-file="index.tsx">
      <ReferenceLink target={item.categoryLink} className={styles.categoryLink} onClick={onSelect} data-sentry-element="ReferenceLink" data-sentry-source-file="index.tsx">
        {item.categoryLabel}
      </ReferenceLink>
    </li>;
};