import { AccordionItem, Block, ChangePasswordForm } from '@engbers/components';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
export const ChangePasswordFormTastic = ({
  data
}) => {
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>
        <Block>
          <AccordionItem label="Passwort ändern">
            <ChangePasswordForm />
          </AccordionItem>
        </Block>
      </FullPageWidthWrapper>;
  }
  return <Block data-sentry-element="Block" data-sentry-component="ChangePasswordFormTastic" data-sentry-source-file="index.tsx">
      <AccordionItem label="Passwort ändern" data-sentry-element="AccordionItem" data-sentry-source-file="index.tsx">
        <ChangePasswordForm data-sentry-element="ChangePasswordForm" data-sentry-source-file="index.tsx" />
      </AccordionItem>
    </Block>;
};