import { fetchApiHub } from '@frontastic-engbers/lib';
import {
  CustomerOrigin,
  DeviceParams,
  IPaymentMethod,
  PayonePaymentProductAvailability,
} from '@frontastic-engbers/types/engbers-custom';

export const getPaymentMethods = async (currencyCode: string): Promise<PayonePaymentProductAvailability> => {
  return await fetchApiHub('/action/payment/getPaymentMethods', { method: 'POST' }, { currencyCode });
};

export const createHostedTokenizationPage = async () => {
  return await fetchApiHub('/action/payment/createHostedTokenizationPage', { method: 'POST' });
};

export const handleRedirectPayment = async (paymentId: string) => {
  const payload = {
    paymentId,
  };

  return await fetchApiHub('/action/payment/handleRedirectPayment', { method: 'POST' }, payload);
};

export const handlePayment = async (
  paymentMethod: IPaymentMethod,
  customerOrigin: CustomerOrigin,
  hostedTokenizationId: string,
  paymentProductId: number,
  deviceParams: DeviceParams,
  accountId: string,
) => {
  const payload = {
    paymentMethod,
    customerOrigin,
    hostedTokenizationId,
    paymentProductId,
    deviceParams,
    accountId,
  };

  return await fetchApiHub('/action/payment/handlePayment', { method: 'POST' }, payload);
};
