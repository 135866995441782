import { useMemo } from 'react';
import { VoucherCard } from '../voucher-card';
import { useUsedCoupons } from '@frontastic-engbers/lib/actions/account';
import { LoadingIndicatorInline, SectionLayout } from '@engbers/components';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import styles from './vouchers.module.scss';
type CouponAsImage = {
  voucherCode: string;
  image: any;
};
type VouchersProps = {
  // couponsAsImage are maintained in the frontastic studio
  couponsAsImage: Array<CouponAsImage>;
  placeholderLabel: string;
};
export const Vouchers = ({
  couponsAsImage,
  placeholderLabel
}: VouchersProps) => {
  const {
    getTitle
  } = useImageSEO();
  const {
    loaded: loadedUsedCoupons,
    usedCoupons
  } = useUsedCoupons();
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const filteredCoupons = useMemo(() => {
    const usedCouponCodes = usedCoupons?.map(x => x.identNumber) || [];
    return couponsAsImage.filter(x => !usedCouponCodes.includes(x.voucherCode));
  }, [usedCoupons, couponsAsImage]);
  if (!loadedUsedCoupons) {
    return <LoadingIndicatorInline color="blue" />;
  }
  return <div className={styles.items} data-sentry-component="Vouchers" data-sentry-source-file="index.tsx">
      {loadedUsedCoupons && !couponsAsImage?.length && <SectionLayout>
          <VoucherCard header={<div className={styles.currentCredit}>
                <div>{placeholderLabel || formatErrorMessage({
            id: 'noAvailabeVouchers'
          })}</div>
              </div>} />
        </SectionLayout>}
      {filteredCoupons.map((item: CouponAsImage) => {
      return <div key={`coupons-${item.voucherCode}`} className={styles.item}>
            <img src={item.image?.media?.file} alt={getTitle(item.image)} title={getTitle(item.image)} />
          </div>;
    })}
    </div>;
};