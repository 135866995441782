import { Card } from '@engbers/components';
import { useCardData } from '@frontastic-engbers/lib/actions/account/useCardData';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
const EngbersCardTastic = ({
  data
}) => {
  const {
    loaded,
    cardData
  } = useCardData();
  if (!loaded) {
    return null;
  }
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>
        <Card custNr={cardData.cardId} barcodeSvg={cardData.barcodeImage.svg} label={data.label} textSize={data.textSize} background={data.backgroundImage} />
      </FullPageWidthWrapper>;
  }
  return <Card custNr={cardData.cardId} barcodeSvg={cardData.barcodeImage.svg} label={data.label} textSize={data.textSize} background={data.backgroundImage} data-sentry-element="Card" data-sentry-component="EngbersCardTastic" data-sentry-source-file="index.tsx" />;
};
export default EngbersCardTastic;