import React, { useEffect, useState } from 'react';
import { OrderCalculation } from '../order-calculation';
import { OrderStatusConfirmation } from '../order-status-confirmation';
import { OrderHistoryData, OrderHistoryProductItem } from '@frontastic-engbers/types/account/Account';
import { fetchApiHub } from '@frontastic-engbers/lib';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useMediaQuery } from 'react-responsive';
import { Block, FormattedAmount, IconCustom, Image } from '@engbers/components';
import classNames from 'classnames';
import styles from './order-item-content.module.scss';
import useSWR from 'swr';
export const OrderItemContent: React.FC<{
  order: OrderHistoryData;
  labels: {
    orderStatus: {
      1: string;
      2: string;
      3: string;
      4: string;
    };
  };
  colors?: {
    [key: string]: string;
  };
  trackingLink?: string;
}> = ({
  order,
  labels,
  colors,
  trackingLink
}) => {
  const {
    formatMessage: formatProductMessage
  } = useFormat({
    name: 'product'
  });
  const {
    formatMessage
  } = useFormat({
    name: 'account'
  });
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    maxWidth: 1024
  });
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  return <>
      {!isMinWidthMedium && <div className={styles.itemRow}>
          <span className="basis-1/6">{formatProductMessage({
          id: 'product'
        })}</span>
          <span className="basis-1/2">{formatProductMessage({
          id: 'product.desc'
        })}</span>
          <span className="basis-1/6 text-center">{formatProductMessage({
          id: 'number'
        })}</span>
          <span className="basis-1/4 text-right">{formatMessage({
          id: 'orders.total.amount'
        })}</span>
        </div>}
      {order.lineItems.map((lineItem, index) => {
      if (lineItem.type === 'product') {
        const product = lineItem.product;
        return <OrderItemInner isLastItem={order.lineItems?.length - 1 === index} product={product} isMinWidthMedium={isMinWidthMedium} />;
      }
    })}
      <div className={styles.summary}>
        <OrderCalculation order={order} data-sentry-element="OrderCalculation" data-sentry-source-file="index.tsx" />
        {order.type === 'KASSE' ? null : <OrderStatusConfirmation labels={labels} order={order} statusColor={colors ? colors.statusColor : undefined} statusTextColor={colors ? colors.statusTextColor : undefined} trackingLink={trackingLink} />}
      </div>
    </>;
};
const OrderItemInner: React.FC<{
  product: OrderHistoryProductItem;
  isMinWidthMedium: boolean;
  isLastItem?: boolean;
}> = ({
  product,
  isMinWidthMedium,
  isLastItem
}) => {
  const {
    formatMessage: formatProductMessage
  } = useFormat({
    name: 'product'
  });
  const {
    data
  } = useSWR(`/action/product/getProduct?id=${product.modelId}`, fetchApiHub);
  if (isMinWidthMedium) {
    return <Block className={classNames(styles.minWidthBlock, {
      [styles[`minWidthBlock--last`]]: isLastItem
    })} key={`lineItem=${product.modelId}`}>
        <div className={styles.itemRow}>
          <div className={classNames(styles.imageWrap, {
          [styles[`imageWrap--placeholder`]]: !data?.variants
        })}>
            {data?.variants ? <Image src={data?.variants[0]?.images[0]} link={data?._url ? {
            type: 'link',
            link: data?._url,
            openInNewWindow: false
          } : undefined} alt={product.itemName} title={product.itemName} /> : <IconCustom width={50} icon="CartFilled" />}
          </div>
          <div className={styles.contentWrap}>
            <span className={styles.name}>{product.itemName}</span>
            <span>
              {formatProductMessage({
              id: 'typeNumber'
            })}: {product.modelId}
            </span>
            <span>
              {formatProductMessage({
              id: 'size'
            })}: {product.sizeName}
            </span>
            <div className={styles.amountPriceRow}>
              <span>
                {formatProductMessage({
                id: 'number'
              })}: {product.quantity}
              </span>
              <div className={styles.price}>
                <FormattedAmount amount={product.amount} />
              </div>
            </div>
          </div>
        </div>
      </Block>;
  }
  return <div className={styles.itemRow} key={`lineItem=${product.modelId}`} data-sentry-component="OrderItemInner" data-sentry-source-file="index.tsx">
      <div className={classNames(styles.imageWrap, 'basis-1/6')}>
        {data?.variants ? <Image src={data?.variants[0]?.images[0]} link={data?._url ? {
        type: 'link',
        link: data?._url,
        openInNewWindow: false
      } : undefined} alt={product.itemName} title={product.itemName} /> : <IconCustom width={50} icon="CartFilled" />}
      </div>
      <div className="basis-1/2">
        <span className={styles.name}>{product.itemName}</span>
        <span>
          {formatProductMessage({
          id: 'typeNumber'
        })}: {product.modelId}
        </span>
        <span>
          {formatProductMessage({
          id: 'color'
        })}: {product.description}
        </span>
        <span>
          {formatProductMessage({
          id: 'size'
        })}: {product.sizeName}
        </span>
      </div>
      <div className="mt-2 basis-1/6 text-center">{product.quantity}</div>
      <div className={classNames(styles.price, 'basis-1/4', 'text-right', 'mt-2')}>
        <FormattedAmount amount={product.amount} data-sentry-element="FormattedAmount" data-sentry-source-file="index.tsx" />
      </div>
    </div>;
};