import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Transition } from '@headlessui/react';
import { Discount } from '@frontastic-engbers/types/cart/Discount';
import { VoucherInfo } from '@frontastic-engbers/types/engbers-custom';
import { IconCustom, Markdown } from '@engbers/components';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useCart } from '@frontastic-engbers/lib/provider';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { useMediaQuery } from 'react-responsive';
import styles from './discount-form.module.scss';
export interface Props {
  className?: string;
  voucherSection: {
    voucherInstruction?: string;
    voucherHeadline: string;
    voucherInfo: string;
    voucherRedeemBtn: string;
    voucherRedeemedLabel?: string;
    voucherInvalidLabel?: string;
    discounts: Discount[];
    setDiscounts: React.Dispatch<React.SetStateAction<Discount[]>>;
  };
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const DiscountForm: React.FC<Props> = ({
  className,
  voucherSection,
  setLoading
}) => {
  const {
    data: cart
  } = useCart();
  const {
    formatMessage: formatCartMessage
  } = useFormat({
    name: 'cart'
  });
  const [voucherValue, setVoucherValue] = useState<VoucherInfo>({
    voucherCode: '',
    voucherPin: ''
  });
  const [hasVoucherError, setHasVoucherError] = useState<boolean>(false);
  const [error, setError] = useState<string>(undefined);
  const [showHint, setShowHint] = useState<boolean>(false);
  const {
    redeemDiscountCode
  } = useCart();
  const {
    pushModal
  } = useModalActions();
  const isMobile = useMediaQuery({
    maxWidth: 768
  });
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setVoucherValue({
      ...voucherValue,
      [e.target.name]: e.target.value.trim()
    });
  };
  const onApplyDiscount = () => {
    setLoading(true);
    redeemDiscountCode(voucherValue.voucherCode, voucherValue.voucherPin).then(response => {
      const hasError = typeof response === 'string';
      setHasVoucherError(hasError);
      if (hasError) {
        setError(response);
      }
    }).catch(() => {
      setHasVoucherError(true);
    }).finally(() => {
      setLoading(false);
      setVoucherValue({
        voucherCode: '',
        voucherPin: ''
      });
      setShowHint(true);
    });
  };
  const createModal = () => {
    pushModal({
      title: '',
      id: 'voucher_info',
      content: <Markdown text={voucherSection.voucherInfo} className={styles.voucherInfoMarkdown} />,
      canCloseByBackdropClick: true,
      isLarge: true
    });
  };
  useEffect(() => {
    voucherSection.setDiscounts(cart?.discountCodes);
  }, [cart?.discountCodes]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowHint(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [showHint]);
  return <div className={classnames(className, styles.sectionWrap, styles.voucherWrap)} data-sentry-component="DiscountForm" data-sentry-source-file="index.tsx">
      {!isMobile && voucherSection.voucherInstruction && <div className={classnames(styles.voucherInstructionWrap, 'w-1/3', {
      'w-1/4': voucherValue.voucherCode.length >= 11
    })} key="voucherInstruction">
          <span className={styles.voucherInstruction}>{voucherSection.voucherInstruction}</span>
        </div>}

      <div className={classnames(styles.sectionWrap, styles.voucherFormWrap, {
      'w-3/4 !justify-end': voucherValue.voucherCode.length >= 11 && !isMobile && voucherSection.voucherInstruction
    })}>
        {isMobile && <>
            <label htmlFor="voucherCode" className={classnames('mr-2', styles.voucherLabel)}>
              <span>{voucherSection.voucherHeadline}</span>
            </label>
            {voucherSection.voucherInstruction && <div className={classnames(styles.voucherInstructionWrap)} key="voucherInstruction--mobile">
                <span className={styles.voucherInstruction}>{voucherSection.voucherInstruction}</span>
              </div>}
          </>}

        <div className={classnames(styles.voucherInputWrap, {
        'flex-wrap': voucherValue.voucherCode.length >= 11 && !isMobile && voucherSection.voucherInstruction,
        'flex-col !items-start': voucherValue.voucherCode.length >= 11 && isMobile
      })}>
          <div className="flex items-center">
            {!isMobile && <label htmlFor="voucherCode" className={classnames('mr-2', styles.voucherLabel)}>
                <span>{voucherSection.voucherHeadline}</span>
              </label>}
            <button className={styles.voucherInfoButton} onClick={createModal}>
              <IconCustom icon="Info" width={20} data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
            </button>

            <input id="voucherCode" name="voucherCode" type="text" value={voucherValue.voucherCode} onChange={handleInputChange} className={styles.voucherInput} />
          </div>

          {isMobile && <label htmlFor="voucherPin" className={classnames(styles.pinLabel, {
          [styles.active]: voucherValue.voucherCode.length >= 11
        })}>
              <span>
                {formatCartMessage({
              id: 'onlinePin',
              defaultMessage: 'Online-Pin'
            })}
              </span>
            </label>}

          <div className={styles.voucherApplyWrap}>
            {!isMobile && <label htmlFor="voucherPin" className={classnames(styles.pinLabel, {
            [styles.active]: voucherValue.voucherCode.length >= 11
          })}>
                <span>
                  {formatCartMessage({
                id: 'onlinePin',
                defaultMessage: 'Online-Pin'
              })}
                </span>
              </label>}
            <div className={classnames(styles.pinWrapper, {
            [styles.active]: voucherValue.voucherCode.length >= 11
          })}>
              <input id="voucherPin" name="voucherPin" type="text" value={voucherValue.voucherPin} onChange={handleInputChange} />
            </div>

            <button className="cta" type="button" onClick={onApplyDiscount} disabled={voucherValue.voucherCode === ''}>
              {voucherSection.voucherRedeemBtn || formatCartMessage({
              id: 'cart.apply',
              defaultMessage: 'Apply'
            })}
            </button>
          </div>

          <Transition show={showHint} enter="transition-transform duration-4" enterFrom="translate-y-full" enterTo="translate-y-0" leave="transition-transform duration-4" leaveFrom="translate-y-0" leaveTo="translate-y-full" className={classnames(styles.voucherHint, {
          [styles.hasError]: hasVoucherError
        })} data-sentry-element="Transition" data-sentry-source-file="index.tsx">
            <span>
              {hasVoucherError ? error ?? voucherSection.voucherInvalidLabel : voucherSection.voucherRedeemedLabel}
            </span>
          </Transition>
        </div>
      </div>
    </div>;
};
export default DiscountForm;