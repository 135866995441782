import React from 'react';
import Head from 'next/head';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { ForgotPasswordForm } from '@engbers/components/forgot-password-form';
interface IForgotPasswordFormData {
  forgotPasswordSectionTitle?: string;
  textSize?: number;
  forgotPasswordSectionSubheading?: string;
  forgotPasswordDescription?: string;
  emailInputLabel?: string;
  requestButtonLabel?: string;
  confirmationRedirectLink?: IFTLinkReference | IFTPageFolderReference;
  isFullWidth?: boolean;
  errorMessages?: IForgotPasswordErrorMessage[];
}
interface IForgotPasswordErrorMessage {
  messageId: string;
  messageText: string;
}
interface IForgotPasswordFormTasticComponent {
  data: IForgotPasswordFormData;
}
export const ForgotPasswordFormTastic: React.FC<IForgotPasswordFormTasticComponent> = ({
  data
}) => {
  const {
    formatMessage: formatAccountMessage
  } = useFormat({
    name: 'account'
  });
  const getErrorMessages = (): Record<string, string> => {
    if (!data.errorMessages || data.errorMessages.length === 0) {
      return undefined;
    }
    const messages = {};
    for (const message of data.errorMessages) {
      messages[message.messageId] = message.messageText;
    }
    return messages;
  };
  const forgotPassword = <>
      <Head>
        <title>{formatAccountMessage({
          id: 'password.forgot',
          defaultMessage: 'Forgot your password?'
        })}</title>
      </Head>
      <ForgotPasswordForm title={data.forgotPasswordSectionTitle} textSize={data.textSize} subHeading={data.forgotPasswordSectionSubheading} description={data.forgotPasswordDescription} emailInputLabel={data.emailInputLabel} requestButtonLabel={data.requestButtonLabel} redirectLink={data.confirmationRedirectLink} errorMessages={getErrorMessages()} />
    </>;
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>{forgotPassword}</FullPageWidthWrapper>;
  }
  return forgotPassword;
};