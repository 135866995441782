import { useSortBy } from 'react-instantsearch-core';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import SortFacet from '../facets/sort';
import styles from './sorting.module.scss';
export interface ISorting {
  resultsIndexName: string;
  sortingLabel?: string;
  closeFilterModal?: () => void;
  indexName: string;
  isMobile?: boolean;
}
export const Sorting: React.FC<ISorting> = ({
  resultsIndexName,
  sortingLabel,
  closeFilterModal,
  indexName,
  isMobile
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'product'
  });
  indexName = indexName.replace('_default', '');
  const {
    currentRefinement,
    options,
    refine
  } = useSortBy({
    items: [{
      label: formatMessage({
        id: 'sortDefault',
        defaultMessage: 'Standard'
      }),
      value: `${indexName}_default`
    }, {
      label: formatMessage({
        id: 'new',
        defaultMessage: 'Neuheiten'
      }),
      value: `${indexName}_new`
    }, {
      label: formatMessage({
        id: 'priceDesc',
        defaultMessage: 'Höchster Preis'
      }),
      value: `${indexName}_price_desc`
    }, {
      label: formatMessage({
        id: 'priceAsc',
        defaultMessage: 'Niedrigster Preis'
      }),
      value: `${indexName}_price_asc`
    }, {
      label: formatMessage({
        id: 'sortSale',
        defaultMessage: 'SALE'
      }),
      value: `${indexName}_sale`
    }]
  });
  const sortFacet: JSX.Element = <SortFacet resultsIndexName={resultsIndexName} className={styles.sortingDropdown} items={options} isMobile={isMobile} onClick={closeFilterModal} currentRefinement={currentRefinement} options={options} refine={refine} />;
  if (isMobile) {
    return sortFacet;
  }
  return <div className={styles.sortingWrap} data-sentry-component="Sorting" data-sentry-source-file="index.tsx">
      <div className={styles.sortingLabel}>
        {sortingLabel || formatMessage({
        id: 'sortBy',
        defaultMessage: 'Sortieren nach'
      })}
      </div>
      {sortFacet}
    </div>;
};