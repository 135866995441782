import React, { useEffect } from 'react';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getSKUParts } from '@frontastic-engbers/helpers/utils/getSKUParts';
import { sortSizes } from '@frontastic-engbers/helpers/utils/sizeSorting';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { CustomDropdown } from '@engbers/components';
import styles from './pdp-sizes.module.scss';
export interface IPdpSizesModal {
  handleAddToCart: (sku: string) => Promise<void> | void;
  variants: Variant[];
  isGiftCard?: boolean;
}
export const PdpSizesModal: React.FC<IPdpSizesModal> = ({
  handleAddToCart,
  variants,
  isGiftCard
}) => {
  const {
    removeModal
  } = useModalActions();
  const {
    formatMessage
  } = useFormat({
    name: 'product'
  });
  const [selectedVariant, setSelectedVariant] = React.useState<Variant>();
  const minArticles = 2;
  useEffect(() => {
    const inStockVariants = variants.filter(variant => variant.isInStock);
    if (inStockVariants.length === 1) {
      setSelectedVariant(inStockVariants[0]);
    }
  }, [variants]);
  const onAddToCart = async value => {
    removeModal();
    await handleAddToCart(value);
  };
  const getSizesForDropdown = variants?.sort((a, b) => sortSizes({
    value: a.attributes.Sizing
  }, {
    value: b.attributes.Sizing
  }))?.map(variant => {
    const size = variant.attributes.Sizing || getSKUParts(variant.sku).size;
    return {
      label: <div>
            {size}
            {variant.quantity > 0 && variant.quantity <= minArticles && <span className={styles.sizesFewArticlesLeftNote}>
                {formatMessage({
            id: 'fewArticlesLeftNote',
            defaultMessage: `- Nur noch ${variant.quantity} verfügbar!`,
            values: {
              amount: variant.quantity
            }
          })}
              </span>}
          </div>,
      value: variant.sku,
      disabled: !variant.isInStock
    };
  });
  return <div className={styles.sizesModal} data-sentry-component="PdpSizesModal" data-sentry-source-file="sizesModal.tsx">
      <div className={styles.sizesModalLabel}>
        {isGiftCard ? formatMessage({
        id: 'giftCardVariantsLabel',
        defaultMessage: 'Wert:'
      }) : `${formatMessage({
        id: 'size',
        defaultMessage: 'Größe'
      })} :`}
      </div>
      <CustomDropdown className={styles.sizesModalDropdown} items={getSizesForDropdown} onClick={onAddToCart} placeholder={isGiftCard ? formatMessage({
      id: 'giftCardSelectVariantLabel',
      defaultMessage: 'Bitte Betrag wählen'
    }) : formatMessage({
      id: 'selectSizeLabel',
      defaultMessage: 'Bitte Größe wählen'
    })} preSelectedItemValue={selectedVariant?.sku} closeOnBackdrop isSizeDropdown data-sentry-element="CustomDropdown" data-sentry-source-file="sizesModal.tsx" />
    </div>;
};