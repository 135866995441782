import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import { Discount } from '@frontastic-engbers/types/cart/Discount';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Variant } from '@frontastic-engbers/types/product/Variant';

export const updateItemDatalayerPush = (lineItem: LineItem, newQuantity: number) => {
  if (!lineItem.price || !lineItem.price.centAmount || !lineItem.count) {
    return;
  }
  const fractionDigits: number = lineItem.price.fractionDigits ?? 2;
  const quantity = lineItem.count < newQuantity ? newQuantity - lineItem.count : lineItem.count - newQuantity;

  const discountedCentAmount =
    lineItem.price.centAmount -
    (lineItem.discounts?.reduce(
      (sum: number, discount: Discount) => sum + (discount.discountedAmount?.centAmount ?? 0),
      0,
    ) ?? 0);

  const total = Number(((discountedCentAmount ?? 0) / 10 ** fractionDigits).toFixed(fractionDigits));

  if (lineItem.count < newQuantity) {
    new TagManager()
      .addToCartEvent([ItemMapper.lineItemToItem(lineItem, newQuantity - lineItem.count)], total * quantity)
      .executePush();
  } else {
    new TagManager()
      .removeFromCartEvent([ItemMapper.lineItemToItem(lineItem, lineItem.count - newQuantity)], total * quantity)
      .executePush();
  }
};

export const updateSizeDatalayerPush = (
  lineItem: LineItem,
  product: Product,
  currentVariant: Variant,
  quantity: number,
) => {
  if (
    !lineItem ||
    !lineItem.price ||
    !lineItem.price.centAmount ||
    !lineItem.totalPrice ||
    !lineItem.totalPrice.centAmount ||
    !product ||
    !currentVariant.price ||
    !currentVariant.price.centAmount
  ) {
    return;
  }
  const fractionDigits: number = currentVariant.price.fractionDigits ?? 2;

  const totalPriceAddedItem = Number(
    ((currentVariant.price.centAmount ?? 0) / 10 ** fractionDigits).toFixed(fractionDigits),
  );
  const totalPriceRemovedItem = Number(
    ((lineItem.totalPrice.centAmount ?? 0) / 10 ** fractionDigits).toFixed(fractionDigits),
  );

  new TagManager()
    .removeFromCartEvent([ItemMapper.lineItemToItem(lineItem, lineItem.count)], totalPriceRemovedItem)
    .addToCartEvent([ItemMapper.productToItem(product, quantity, 1, currentVariant)], totalPriceAddedItem * quantity)
    .executePush();
};
