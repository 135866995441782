const getAge = (birthday: Date) => {
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const validateDateFormat = (day: string, month: string, year: string) => {
  const daysInSelectedMonth = new Date(parseFloat(year), parseFloat(month), 0).getDate();
  const isValidFormat =
    /^\d{2}$/.test(day) &&
    parseFloat(day) !== 0 &&
    /^\d{2}$/.test(month) &&
    parseFloat(month) !== 0 &&
    /^\d{4}$/.test(year) &&
    parseFloat(year) !== 0;
  const isMinYearValid = parseFloat(year) >= new Date().getFullYear() - 100;
  // Person should be at least 18 years old
  const isMaxYearValid = getAge(new Date(parseFloat(year), parseFloat(month), 0)) >= 18;

  return isValidFormat && parseFloat(day) <= daysInSelectedMonth && isMinYearValid && isMaxYearValid;
};
