import React from 'react';
import classNames from 'classnames';
import styles from './section-layout.module.scss';
export const SectionLayout: React.FC<{
  maxWidth?: number;
  isCentered?: boolean;
}> = ({
  children,
  maxWidth,
  isCentered
}) => {
  return <div style={{
    maxWidth: maxWidth ?? undefined
  }} className={classNames(styles.base, {
    [styles.isCentered]: isCentered
  })} data-sentry-component="SectionLayout" data-sentry-source-file="index.tsx">{children}</div>;
};