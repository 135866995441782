import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { Image, Slider } from '@engbers/components';
import { ImageExtended } from '@engbers/components/image/image-extended';
import styles from './engbers-image-slider.module.scss';
export const EngbersImageSliderTastic = ({
  data
}) => {
  const SliderElement = <div className={styles.imageSliderWrapper}>
      <Slider autoplay={{
      delay: data.autoPlayDelay * 1000,
      waitForTransition: true,
      disableOnInteraction: false
    }} dots={false} autoHeight loop overlapDots arrows>
        {data?.sliderItems?.map(item => item.btnActive || item.textActive ? <ImageExtended key={item.image?.media?.mediaId} media={item.image?.media} width={item.image?.width} link={item?.link} alt={item.image?.name} isFullWidth={data.isFullWidth} allElementsRedirect={false} containerWidth={item?.containerWidth} containerBackgroundColor={item?.containerBackgroundColor} verticalAlignment={item?.verticalAlignment} horizontalAlignment={item?.horizontalAlignment} imgPadding={item?.imgPadding} containerPadding={item?.containerPadding} button={{
        btnActive: item.btnActive,
        buttonText: item?.buttonText,
        buttonIcon: item?.buttonIcon,
        buttonIconWidth: item?.buttonIconWidth,
        buttonWidth: item?.buttonWidth,
        buttonTextColor: item?.buttonTextColor,
        buttonBackgroundColor: item?.buttonBackgroundColor,
        buttonAlignment: item?.buttonAlignment
      }} text={{
        textActive: item.textActive,
        markdownText: item?.text,
        textColor: item?.textColor,
        textAlignment: item?.textAlignment
      }} /> : <Image media={item.image?.media} link={item.link} isFullWidth={data.isFullWidth} />)}
      </Slider>
    </div>;
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>{SliderElement}</FullPageWidthWrapper>;
  }
  return SliderElement;
};