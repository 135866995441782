import React from 'react';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { RaffleForm } from '@engbers/components/raffle';
interface IRaffleTastic {
  data: any;
}
export const RaffleTastic: React.FC<IRaffleTastic> = ({
  data
}) => {
  const raffleForm = <>
      <RaffleForm raffleId={data.raffleId} raffleName={data.raffleName} newsletterOption={data.newsletterOption} thankYouUrl={data.thankYouUrl} newsletter={data.newsletter.dataSource ?? []} reCaptchaKey={data?.reCaptchaSiteKey?.dataSource?.reCaptchaSiteKey} labels={{
      radioMister: data.radioMister,
      radioMs: data.radioMs,
      labelTitel: data.labelTitel,
      labelFirstName: data.labelFirstName,
      labelLastName: data.labelLastName,
      labelStreetName: data.labelStreetName,
      labelStreetNumber: data.labelStreetNumber,
      labelAdditionalInfo: data.labelAdditionalInfo,
      labelZip: data.labelZip,
      labelCity: data.labelCity,
      labelCountry: data.labelCountry,
      labelTelephone: data.labelTelephone,
      labelMobilephone: data.labelMobilephone,
      labelBirthdate: data.labelBirthdate,
      labelEmail: data.labelEmail,
      labelRecaptcha: data.labelRecaptcha,
      labelRaffleOriginInfos: data.labelRaffleOriginInfos,
      labelDesktopBtnSubmit: data.labelDesktopBtnSubmit,
      labelMobileBtnSubmit: data.labelMobileBtnSubmit,
      labelTextTos: data.labelTextTos,
      ...(data.labelNewsletterOption && {
        labelNewsletterOption: data.labelNewsletterOption
      })
    }} />
    </>;
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>{raffleForm}</FullPageWidthWrapper>;
  }
  return raffleForm;
};