import { Newsletter, NewsletterSubscription } from '@frontastic-engbers/types/newsletter/types';
import { fetchApiHub } from '@frontastic-engbers/lib/lib/fetch-api-hub';

type NewsletterByMailData = {
  email: string;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  subscriptions: Newsletter[];
};

export const useNewsletter = () => {
  const updateSubscriptions = async (subscriptions: NewsletterSubscription[]) => {
    const response = await fetchApiHub(
      '/action/account/updateNewsletterSubscriptions',
      { method: 'POST' },
      { subscriptions },
    );

    return response;
  };

  const getSubscriptionsByEmail = async (data: NewsletterByMailData): Promise<NewsletterSubscription[]> => {
    const response = await fetchApiHub('/action/account/getNewsletterSubscriptionsByEmail', { method: 'POST' }, data);

    return response.subscriptions && Array.isArray(response.subscriptions) ? response.subscriptions : [];
  };

  const getSubscriptions = async (newsletters: Newsletter[]) => {
    const response = await fetchApiHub(
      '/action/account/getNewsletterSubscriptions',
      { method: 'POST' },
      {
        newsletters: newsletters.map((newsletter) => ({
          id: newsletter.id,
          name: newsletter.name,
        })),
      },
    );

    return response.subscriptions && Array.isArray(response.subscriptions) ? response.subscriptions : [];
  };

  const registerNewsletterByEmail = async (data: NewsletterByMailData) => {
    const response = await fetchApiHub('/action/account/registerNewsletterByEmail', { method: 'POST' }, data);

    return response;
  };

  return {
    updateSubscriptions,
    getSubscriptionsByEmail,
    getSubscriptions,
    registerNewsletterByEmail,
  };
};
