import React from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';
import { IconCustom, TIconId } from '@engbers/components/icon-custom';
import { Typography } from '@engbers/components/typography';
import { SeparationLine } from '@engbers/components/online-shops/separation-line';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { Markdown } from '@engbers/components';
import styles from './my-account-headline.module.scss';
const MediaQuery = dynamic(() => import('react-responsive'), {
  ssr: false
});
type Props = {
  headlineText: string;
  textSize?: number;
  iconCustom: TIconId;
  iconMedia?: MediaType;
  uppercase?: boolean;
  subHeadline?: string;
  amountBubble?: number;
  showAmountBubble?: boolean;
};
export const MyAccountHeadline: React.FC<Props> = ({
  headlineText,
  textSize,
  iconCustom,
  iconMedia,
  uppercase = false,
  subHeadline,
  amountBubble,
  showAmountBubble
}) => {
  const {
    getTitle
  } = useImageSEO();
  return <MediaQuery minWidth={1024} data-sentry-element="MediaQuery" data-sentry-component="MyAccountHeadline" data-sentry-source-file="index.tsx">
      <div className={styles.accountHeadline}>
        <h3 className={classnames('inline-flex', {
        [styles.uppercase]: uppercase
      })}>
          {iconMedia?.media?.file ? <div className={styles.icon}>
              <img src={iconMedia.media.file} alt={getTitle(iconMedia)} title={getTitle(iconMedia)} />
            </div> : <IconCustom icon={iconCustom} className={styles.icon} width={22} />}
          <Markdown text={headlineText} textSize={textSize} disableMargin data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
          {showAmountBubble && <div className={styles.amountBubble}>{amountBubble}</div>}
        </h3>
        {subHeadline && <div className={styles.subHeadline}>
            <Typography weight="semi-bold" size="l">
              {subHeadline}
            </Typography>
          </div>}
        <SeparationLine sizeTop={15} sizeBottom={15} height={1} data-sentry-element="SeparationLine" data-sentry-source-file="index.tsx" />
      </div>
    </MediaQuery>;
};