import { useState } from 'react';
import { useRouter } from 'next/router';
import { commercetoolsLanguageMapper, languageMapper } from '@frontastic-engbers/lib/project.config';

type Languages = (typeof languageMapper)[keyof typeof languageMapper];
export type Translation = Partial<Record<Languages, string>>;

const useI18n = (isCommercetoolsLanguageMapper = false) => {
  const router = useRouter();
  const [country] = useState('DE');

  const locale = !isCommercetoolsLanguageMapper
    ? languageMapper[router.locale || router.defaultLocale]
    : commercetoolsLanguageMapper[router.locale || router.defaultLocale];

  function translate(input: Translation | string): string {
    return typeof input === 'string'
      ? input
      : input[locale];
  }

  return {
    country,
    t: translate 
  };
};

export default useI18n;
