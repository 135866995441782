import React from 'react';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { ContactForm } from '@engbers/components/contact-form';
export interface Props {
  data: any;
}
export const ContactFormTastic: React.FC<Props> = ({
  data
}) => {
  const contactForm = <ContactForm headline={data.headline} headlineSize={data.headlineSize} isEmilio={data.isEmilio} reCaptchaKey={data?.reCaptchaSiteKey?.dataSource?.reCaptchaSiteKey} redirectSite={data.redirectSite} labels={{
    radioMister: data.radioMister,
    radioMs: data.radioMs,
    labelFirstName: data.labelFirstName,
    labelLastName: data.labelLastName,
    labelZip: data.labelZip,
    labelCity: data.labelCity,
    labelEngbersCard: data.labelEngbersCard,
    labelEmail: data.labelEmail,
    labelTelephone: data.labelTelephone,
    labelAbout: data.labelAbout,
    labelMessage: data.labelMessage,
    labelRecaptcha: data.labelRecaptcha,
    textTos: data.textTos,
    labelMobileBtnSubmit: data.labelMobileBtnSubmit,
    labelDesktopBtnSubmit: data.labelDesktopBtnSubmit
  }} />;
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>{contactForm}</FullPageWidthWrapper>;
  }
  return contactForm;
};