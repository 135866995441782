export const handlePopState = () => {
  const lastVisitedProduct = sessionStorage.getItem('storage_last_visited_product');

  if (!lastVisitedProduct) {
    return null;
  }

  const visitedProduct = JSON.parse(lastVisitedProduct);
  visitedProduct.visited = true;
  sessionStorage.setItem('storage_last_visited_product', JSON.stringify(visitedProduct));
};
