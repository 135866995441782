import { ProductReference } from '@commercetools/platform-sdk';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';

export class ProductUtils {
  private static readonly OUTFIT_IDENTIFIER = 'kit';

  public static isOutfit(product: any): product is Outfit {
    if (!ProductUtils.isProduct(product)) {
      return false;
    }

    return product.productId?.toLowerCase().includes(ProductUtils.OUTFIT_IDENTIFIER) ?? false;
  }

  public static isProduct(product: any): product is Product {
    return (product as Product).productId !== undefined
        && (product as Product).variants !== undefined;
  }

  public static isProductReference(product: any): product is ProductReference {
    return (product as ProductReference).typeId === 'product'
        && (product as ProductReference).id !== undefined;
  }
}