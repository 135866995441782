import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { PaymentMethod } from '@frontastic-engbers/types/engbers-custom';
import { Button, Markdown } from '@engbers/components';
import styles from './solvency-check-modal.module.scss';
interface ISolvencyCheckModal {
  solvencyCheck: (selectedPaymentMethod: PaymentMethod) => void;
  solvencyStatus: number;
  solvencyCheckResult: number;
  cancelLabel: string;
  submitLabel: string;
  selectedPaymentMethod: PaymentMethod;
  solvencyFailedMessage: string;
  solvencyInvoiceMessage: string;
  solvencyDebitMessage: string;
}
export const SolvencyCheckModal: React.FC<ISolvencyCheckModal> = ({
  solvencyCheck,
  solvencyStatus,
  solvencyCheckResult,
  cancelLabel,
  submitLabel,
  selectedPaymentMethod,
  solvencyFailedMessage,
  solvencyInvoiceMessage,
  solvencyDebitMessage
}) => {
  const {
    removeModal
  } = useModalActions();
  const getModalBody = () => {
    let content = '';
    if (solvencyCheckResult === 1 && selectedPaymentMethod.canUse === false) {
      content = solvencyFailedMessage;
    } else if (solvencyCheckResult !== 1 && selectedPaymentMethod.type === 'invoice') {
      content = solvencyInvoiceMessage;
    } else if (solvencyCheckResult !== 1 && selectedPaymentMethod.type === 'debit') {
      content = solvencyDebitMessage;
    }
    return <div className={styles.content} data-sentry-component="getModalBody" data-sentry-source-file="index.tsx">
        <Markdown text={content} linkToNewTab data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
      </div>;
  };
  return <>
      {solvencyStatus !== 1 && getModalBody()}
      {solvencyCheckResult === 0 ? <div className={styles.buttonWrap}>
          <Button type="primary" textColor="#000" label={cancelLabel} onClick={removeModal} />
          <Button label={submitLabel} onClick={() => solvencyCheck(selectedPaymentMethod)} />
        </div> : <div className={styles.buttonWrap}>
          <Button label={cancelLabel} onClick={removeModal} />
        </div>}
    </>;
};