const intlFormat = Intl.NumberFormat('de-DE', {
  minimumFractionDigits: 2,
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'symbol'
});
const formatAmount = intlFormat.format;
export const FormattedAmount = ({
  amount,
  render
}: {
  amount: number;
  render?: (str: string) => React.ReactNode;
}) => {
  if (typeof render === 'function') {
    return <>{render(formatAmount(amount))}</>;
  }
  return <>{formatAmount(amount)}</>;
};