import { Money } from '@frontastic-engbers/types/product/Money';
import { Cart } from '@frontastic-engbers/types/cart/Cart';
import { Order } from '@frontastic-engbers/types/cart/Order';

export const calculateCartLineItemsPrice = (cart: Cart | Order): Money | undefined => {
  if (!cart?.lineItems?.length) {
    return undefined;
  }

  const lineItemsTotalValue = cart.lineItems.reduce(
    (price, lineItem) =>
      price +
      (lineItem.variant?.discountedPrice?.centAmount ?? lineItem.variant?.price?.centAmount ?? 0) *
        (lineItem?.count ? lineItem.count : 1),
    0,
  );

  return {
    fractionDigits: cart.sum?.fractionDigits ?? 2,
    centAmount: lineItemsTotalValue,
    currencyCode: cart.sum?.currencyCode ?? 'EUR',
  };
};
