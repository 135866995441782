import React from 'react';
import useMediaQuery from '@frontastic-engbers/helpers/hooks/useMediaQuery';
import * as screenSizes from '@frontastic-engbers/helpers/utils/screensizes';
export type SpacerProps = {
  variant?: 'sm' | 'md' | 'lg' | 'custom';
  customDesktop?: number;
  customMobile?: number;
};
const Spacer: React.FC<SpacerProps> = ({
  customDesktop,
  customMobile,
  variant = 'md'
}) => {
  //responsiveness
  const [isLargerThanDesktop] = useMediaQuery(screenSizes.desktop);

  //pre defined variants
  const variants = {
    sm: isLargerThanDesktop ? 24 : 16,
    md: isLargerThanDesktop ? 32 : 24,
    lg: isLargerThanDesktop ? 56 : 32
  } as Record<typeof variant, number>;

  //if custom px specified
  const custom = isLargerThanDesktop ? customDesktop : customMobile;

  //applied styles
  const margin = !!custom ? custom : variants[variant];
  return <div style={{
    marginTop: `${margin}px`
  }} data-sentry-component="Spacer" data-sentry-source-file="index.tsx" />;
};
export default Spacer;