import React, { forwardRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Menu } from '@headlessui/react';
import { useHits } from 'react-instantsearch';
import { useClearRefinements, useCurrentRefinements } from 'react-instantsearch-core';
import classnames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getRefinementCount } from '@frontastic-engbers/helpers/utils/getRefinementCount';
import { useProductList } from '../../context';
import useDynamicFacets from '../../hooks/useDynamicFacets';
import { IconCustom } from '@engbers/components';
import styles from './desktop-facets.module.scss';
const DesktopFacets: React.FC = () => {
  const {
    formatMessage: formatProductMessage
  } = useFormat({
    name: 'product'
  });
  const {
    facetsConfiguration
  } = useProductList();
  const {
    results
  } = useHits();
  const {
    items
  } = useCurrentRefinements();
  const {
    refine: clearAllRefinements,
    canRefine: canClear
  } = useClearRefinements();
  const [showAll, setShowAll] = useState<boolean>(false);
  const [dropdownState, setDropdownState] = useState<{
    [key: string]: boolean;
  }>({});
  const updateDropdownState = (attribute?: string) => {
    const state = Object.keys(facetsConfiguration).reduce((obj, key) => ({
      ...obj,
      [key]: attribute === key ? !dropdownState[attribute] : false
    }), {});
    setDropdownState(state);
  };
  useEffect(() => {
    updateDropdownState();
  }, []);
  const facets = useDynamicFacets({
    configuration: facetsConfiguration,
    ordering: results?.renderingContent?.facetOrdering?.facets?.order,
    showAll: showAll,
    facetItemWrapper: Menu.Item,
    render: ({
      attribute,
      Component
    }) => {
      const refinementCount = getRefinementCount(items, attribute);
      const GA4SizeFilterTrigger = attribute === 'availableSizes' ? 'GA4-size-filter-trigger' : undefined;

      // eslint-disable-next-line react/display-name
      const CustomMenuButton = forwardRef<HTMLButtonElement>(({
        children
      }, ref) => <button className={classnames(GA4SizeFilterTrigger, styles.facetButton)} onClick={() => updateDropdownState(attribute)} ref={ref}>
          {children}
        </button>);
      return <>
          {dropdownState[attribute] && createPortal(<div className={styles.filterDropdownBackDrop} onClick={() => updateDropdownState()} />, document.body)}
          <Menu as="div" className={classnames(styles.facetWrap, 'relative')}>
            <Menu.Button as={CustomMenuButton}>
              <span className={classnames(GA4SizeFilterTrigger, {
              'font-bold': refinementCount
            })}>
                {facetsConfiguration[attribute].label}
                {refinementCount > 0 && ` (${refinementCount})`}
              </span>
              <IconCustom icon="ChevronRightIcon" color="primary" width={12} className={classnames(GA4SizeFilterTrigger, styles.buttonIcon, {
              [styles.open]: dropdownState[attribute]
            })} />
            </Menu.Button>
            <Menu.Items static className={classnames(styles.facetItemsWrap, {
            [styles.open]: dropdownState[attribute]
          })}>
              <div className={styles.facetItemWrap}>{Component}</div>
            </Menu.Items>
          </Menu>
        </>;
    }
  });
  const hasFacets = Array.isArray(facets) && facets.length > 0;
  return <div className={styles.facetsWrap} data-sentry-component="DesktopFacets" data-sentry-source-file="index.tsx">
      <div className={styles.facets}>{facets}</div>
      {hasFacets && <div className={styles.buttonWrap}>
          {facets.length > 5 && <button className={styles.showAllButton} onClick={() => {
        updateDropdownState();
        setShowAll(showAll => !showAll);
      }}>
              {showAll ? `${formatProductMessage({
          id: 'lessFilters',
          defaultMessage: 'Weniger Filter'
        })} -` : `${formatProductMessage({
          id: 'moreFilters',
          defaultMessage: 'Mehr Filter'
        })} +`}
            </button>}
          {canClear && <button onClick={() => {
        updateDropdownState();
        clearAllRefinements();
      }} className={styles.clearRefinementsButton}>
              x{' '}
              {formatProductMessage({
          id: 'resetFilters',
          defaultMessage: 'Filter zurücksetzen'
        })}
            </button>}
        </div>}
    </div>;
};
export default DesktopFacets;