import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getSKUParts } from '@frontastic-engbers/helpers/utils/getSKUParts';
import { sortSizes } from '@frontastic-engbers/helpers/utils/sizeSorting';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { PdpSizesModal } from './sizesModal';
import { IconCustom } from '@engbers/components';
import { TIconId } from '@engbers/components/icon-custom';
import styles from './pdp-sizes.module.scss';
export interface IPdpSizes {
  sizeSection: {
    sizesLabel: string;
    sizesUnavailableMessage: string;
  };
  product: Product;
  selectedVariant: Variant;
  setSelectedVariant: React.Dispatch<React.SetStateAction<Variant>>;
  isModal?: boolean;
  handleAddToCart: (sku: string) => Promise<void>;
  sizeDeviationLabel: string;
  sizeDeviationIcon: TIconId;
  sizeDeviationTexts: {
    sizeDeviationProductGroup: string;
    sizeDeviationText: string;
  }[];
  isGiftCard?: boolean;
}
export const PdpSizes: React.FC<IPdpSizes> = ({
  sizeSection,
  product,
  selectedVariant,
  setSelectedVariant,
  isModal = false,
  handleAddToCart,
  sizeDeviationLabel,
  sizeDeviationIcon,
  sizeDeviationTexts,
  isGiftCard
}) => {
  const [fewArticlesLeftNote, setFewArticlesLeftNote] = useState<string>(null);
  const {
    variants
  } = product;
  const {
    formatMessage
  } = useFormat({
    name: 'product'
  });
  const minArticles = 2;
  const isVariantsInStock = !!variants?.filter(variant => variant.isInStock && variant.quantity > 0)?.length;
  const isMobile = useMediaQuery({
    maxWidth: 768
  });
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const sizeContainerRef = useRef(null);
  const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  const productGroupText = sizeDeviationTexts.find(sizeDeviationText => sizeDeviationText.sizeDeviationProductGroup === product.attributes?.ProductGroup);
  function formatDeviationLabel(text: string, tooltipId: string, label: string): React.ReactNode {
    const parts = text.split('%');
    const elements: React.ReactNode[] = [];
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      if (i % 2 === 0) {
        elements.push(<span key={i}>{part}</span>);
      } else {
        elements.push(<div data-tooltip-class-name={styles.sizeTooltipWrap} data-tooltip-id={tooltipId} key={i}>
            <span className={styles.labelTooltipWrap}>{part}</span>
            <Tooltip style={{
            fontSize: "14px"
          }} opacity={1} id={tooltipId} place="bottom" content={label} />
          </div>);
      }
    }
    return elements;
  }
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  useEffect(() => {
    if (sizeContainerRef?.current) {
      const el = sizeContainerRef.current;
      const isElementOverflowing = el.clientWidth === el.scrollWidth;
      setIsOverflowing(!isElementOverflowing);
    }
  }, [innerWidth]);
  const sizes = useMemo(() => {
    const sizes = variants?.map(variant => ({
      variant,
      value: variant.attributes.Sizing
    }));
    sizes.sort(sortSizes);
    return sizes;
  }, [variants]);
  useEffect(() => {
    if (!selectedVariant) {
      return;
    }
    setFewArticlesLeftNote(selectedVariant.quantity > 0 && selectedVariant.quantity <= minArticles ? formatMessage({
      id: 'fewArticlesLeftNote',
      defaultMessage: `- Nur noch ${selectedVariant.quantity} verfügbar!`,
      values: {
        amount: selectedVariant.quantity
      }
    }) : null);
  }, [selectedVariant]);
  if (isModal) {
    return <PdpSizesModal handleAddToCart={handleAddToCart} variants={variants} isGiftCard={isGiftCard} />;
  }
  return <div className={styles.productSizes} data-sentry-component="PdpSizes" data-sentry-source-file="index.tsx">
      {variants?.length && isVariantsInStock ? <div className={styles.sizesWrap}>
          {productGroupText?.sizeDeviationText && <span className="inline-flex">
              <IconCustom className="mr-1" icon={sizeDeviationIcon} width={17} />
              {formatDeviationLabel(sizeDeviationLabel, 'deviation-tolltip', productGroupText.sizeDeviationText)}
            </span>}
          <div className={styles.sizesLabel}>
            {isGiftCard ? formatMessage({
          id: 'giftCardVariantsLabel',
          defaultMessage: 'Wert:'
        }) : <span>{sizeSection.sizesLabel}</span>}
            {fewArticlesLeftNote?.length > 0 && <span className={styles.sizesFewArticlesLeftNote}>{fewArticlesLeftNote}</span>}
          </div>
          <div className={styles.sizesContainer} ref={sizeContainerRef}>
            {isMinWidthMedium && isOverflowing && <IconCustom className={classnames(styles.arrowIcons, styles.left)} width={17} color="custom" icon="ChevronLeftIcon" />}
            {sizes.map(({
          variant
        }, index) => {
          const size = variant.attributes.Sizing || getSKUParts(variant.sku).size;
          return <button key={`size-${index}`} value={size} className={classnames(styles.sizeBtn, (selectedVariant?.attributes.Sizing === size || getSKUParts(selectedVariant?.sku)?.size === size) && variant.isInStock ? styles.isSelected : undefined, !variant.isInStock ? styles.isDisabled : undefined)} disabled={!variant.isInStock} onClick={() => setSelectedVariant(variant)}>
                  {size}
                </button>;
        })}
            {isMinWidthMedium && isOverflowing && <IconCustom className={classnames(styles.arrowIcons, styles.right)} width={17} color="custom" icon="ChevronRightIcon" />}
          </div>
        </div> : <div className={styles.unavailableSizesMessage}>{sizeSection.sizesUnavailableMessage}</div>}
    </div>;
};