import React from 'react';
import Spacer, { SpacerProps } from '@engbers/components/online-shops/commercetools-ui/content/spacer';
export type Props = {
  data: SpacerProps;
};
const SpacerTastic: React.FC<Props> = ({
  data
}) => {
  return <Spacer {...data} data-sentry-element="Spacer" data-sentry-component="SpacerTastic" data-sentry-source-file="index.tsx" />;
};
export default SpacerTastic;