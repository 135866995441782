import { Typography, AccordionItem, SectionLayout, MigrationLoginForm } from '@engbers/components';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
interface IMigrationFormData {
  migrationButtonLabel?: string;
  migrationEmailPlaceholder?: string;
  migrationEmailTooltipContent?: string;
  migrationEmailTooltipHeadline?: string;
  migrationLoginSectionTitle?: string;
  migrationFirstTimeInShopLabel?: string;
  migrationVerifyInformation?: string;
  migrationVerifyBirthdate?: string;
  migrationInitiallyOpen?: boolean;
  isFullWidth?: boolean;
}
interface IMigrationFormTasticComponent {
  data: IMigrationFormData;
}
export const MigrationFormTastic: React.FC<IMigrationFormTasticComponent> = ({
  data
}) => {
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>
        <SectionLayout>
          <AccordionItem isInitiallyOpen={data.migrationInitiallyOpen} label={data.migrationLoginSectionTitle || 'Online-Konto freischalten'}>
            <MigrationLoginForm {...data} />
            <Typography>{data.migrationFirstTimeInShopLabel || '1. Mal im Online-Shop mit engbersCard?'}</Typography>
          </AccordionItem>
        </SectionLayout>
      </FullPageWidthWrapper>;
  }
  return <SectionLayout data-sentry-element="SectionLayout" data-sentry-component="MigrationFormTastic" data-sentry-source-file="index.tsx">
      <AccordionItem isInitiallyOpen={data.migrationInitiallyOpen} label={data.migrationLoginSectionTitle || 'Online-Konto freischalten'} data-sentry-element="AccordionItem" data-sentry-source-file="index.tsx">
        <MigrationLoginForm {...data} data-sentry-element="MigrationLoginForm" data-sentry-source-file="index.tsx" />
        <Typography data-sentry-element="Typography" data-sentry-source-file="index.tsx">{data.migrationFirstTimeInShopLabel || '1. Mal im Online-Shop mit engbersCard?'}</Typography>
      </AccordionItem>
    </SectionLayout>;
};