import { getSelectedShippingAddress } from '@frontastic-engbers/helpers/utils/getShippingInfo';
import { Account } from '@frontastic-engbers/types/account/Account';
import { Cart } from '@frontastic-engbers/types/cart/Cart';
import { CheckoutData, IPaymentMethod, PaymentMethod } from '@frontastic-engbers/types/engbers-custom';

const SECURE_COUNTRIES = ['DE', 'NL', 'AT'];
const FALLBACK_SECURE_PAYMENTS = ['paypal', 'creditCard'];
export const SECURE_PAYMENTS = {
  paypal: true,
  creditCard: true,
  prepayment: true,
};

const INVOICE_COUNTRY_MAPPING = {
  prepayment: ['BE', 'DE', 'LU', 'NL', 'AT'],
  invoice: [
    'BE',
    'DE',
    'LU',
    'NL',
    'AT',
    'BG',
    'DK',
    'EE',
    'FI',
    'FR',
    'GR',
    'IE',
    'IT',
    'HR',
    'LV',
    'LT',
    'MT',
    'PL',
    'PT',
    'RO',
    'SE',
    'SK',
    'SI',
    'ES',
    'CZ',
    'HU',
    'CY',
  ],
};

export const getPaymentMethods = (
  paymentMethods: PaymentMethod[],
  checkoutData: CheckoutData,
  accountData: Account,
  cartData: Cart,
) => {
  let filteredPayments = filterPaymentsByInvoiceCountry(paymentMethods, checkoutData.billingAddress.country);

  if (checkoutData.shippingMethod === 'packStation' || checkoutData.shippingMethod === 'store') {
    filteredPayments = filteredPayments.filter((method) => method.type !== 'invoice' && method.type !== 'debit');
  }

  let hasLockedProducts: boolean;
  ({ filteredPayments, hasLockedProducts } = filterInvoiceProductMapping(
    filteredPayments,
    accountData?.cardId,
    cartData,
  ));

  return {
    ...validateAndFilterPaymentMethods(filteredPayments, checkoutData, cartData),
    hasLockedProducts,
  };
};

const filterPaymentsByInvoiceCountry = (paymentMethods: PaymentMethod[], country: string) => {
  return paymentMethods.filter((method) => {
    return !(method.type in INVOICE_COUNTRY_MAPPING && !INVOICE_COUNTRY_MAPPING[method.type].includes(country));
  });
};

const filterInvoiceProductMapping = (paymentMethods: PaymentMethod[], cardId: string, cart: Cart) => {
  const attribute = !cardId ? 'LockInvoiceNew' : 'LockInvoiceExisting';
  const lockedProductFound = Boolean(
    cart.lineItems.find((lineItem) => lineItem.variant?.attributes?.[attribute] === true),
  );

  if (lockedProductFound) {
    return {
      filteredPayments: paymentMethods.filter((method) => method.type !== 'invoice'),
      hasLockedProducts: true,
    };
  }

  return {
    filteredPayments: paymentMethods,
    hasLockedProducts: false,
  };
};

const validateAndFilterPaymentMethods = (
  paymentMethods: PaymentMethod[],
  checkoutData: CheckoutData,
  cartData: Cart,
) => {
  if (cartData.sum.centAmount <= 0) {
    return {
      filteredPaymentMethods: filterPaymentsForZeroBasket(paymentMethods),
      filterByWebservice: false,
    };
  }

  if (!isUserCountrySecure(checkoutData)) {
    return {
      filteredPaymentMethods: filterPaymentsWithSecurePayments(paymentMethods),
      filterByWebservice: false,
    };
  }

  return {
    filteredPaymentMethods: paymentMethods,
    filterByWebservice: true,
  };
};

const filterPaymentsForZeroBasket = (paymentMethods: PaymentMethod[]) => {
  return paymentMethods.reduce((filtered: PaymentMethod[], method) => {
    if (method.type === 'invoice') {
      filtered.push({
        ...method,
        isVisible: true,
        canUse: true,
      });
    }
    return filtered;
  }, []);
};

export const filterPaymentsWithSecurePayments = (paymentMethods: PaymentMethod[]) => {
  return paymentMethods.reduce((filtered, method) => {
    if (FALLBACK_SECURE_PAYMENTS.includes(method.type)) {
      filtered.push({
        ...method,
        isVisible: true,
        canUse: true,
      });
    }
    return filtered;
  }, []);
};

const isUserCountrySecure = (checkoutData: CheckoutData) => {
  const shippingAddress = getSelectedShippingAddress(checkoutData);

  return (
    SECURE_COUNTRIES.includes(checkoutData.billingAddress.country) && SECURE_COUNTRIES.includes(shippingAddress.country)
  );
};

export const mapPaymentMethods = (paymentMethods: IPaymentMethod[]) => {
  return paymentMethods.map((paymentMethod) => {
    return {
      icon: {
        file: paymentMethod.paymentMethodIcon?.media?.file,
        title: paymentMethod.paymentMethodIcon?.title ?? '',
        name: paymentMethod.paymentMethodIcon?.media?.name,
      },
      infoText: paymentMethod.paymentMethodInfoText ?? '',
      label: paymentMethod.paymentMethodLabel ?? '',
      type: paymentMethod.paymentMethodType,
      canUse: true,
      checked: false,
      isVisible: true,
    };
  });
};
