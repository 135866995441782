import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { getProjectInfo, useAccount } from '@frontastic-engbers/lib';
import { getOrder } from '@frontastic-engbers/lib/actions/cart';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { joinDiscounts } from '@frontastic-engbers/helpers/dataLayerHelper/couponCodeDataHelper';
import { getEcondaPurchaseLayer } from '@frontastic-engbers/helpers/dataLayerHelper/econdaDataHelper';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import { Order } from '@frontastic-engbers/types/cart/Order';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { Image, Sovendus } from '@engbers/components';
import { TrustedShopsCheckout } from '@engbers/components/online-shops/trusted-shops/checkout';
import { OrderConfirmationModal } from './order-confirmation-modal';
import styles from './thank-you.module.scss';
import { calculateCartLineItemsPrice } from '@frontastic-engbers/helpers/dataLayerHelper/calculateCartLineItemsPrice';
interface IThankYou {
  deliveryTimeDE: string;
  deliveryTimeEU: string;
  shippingBy: string;
  orderConfirmationLabel: string;
  orderConfirmationIcon: MediaType;
  orderModalImage: MediaType;
  orderModalEmailText: string;
  orderModalLastText: string;
  orderModalPrepaymentHint: string;
  orderModalPrepaymentAccountData: {
    payee: string;
    iban: string;
    bic: string;
    bank: string;
  };
  trustedShopIcon: MediaType;
  trustedShopText: string;
  sslIcon: MediaType;
  sslText: string;
}
const ThankYou: React.FC<IThankYou> = ({
  deliveryTimeDE,
  deliveryTimeEU,
  shippingBy,
  orderConfirmationLabel,
  orderConfirmationIcon,
  orderModalImage,
  orderModalEmailText,
  orderModalLastText,
  orderModalPrepaymentHint,
  orderModalPrepaymentAccountData,
  trustedShopIcon,
  trustedShopText,
  sslIcon,
  sslText
}) => {
  const {
    account
  } = useAccount();
  const {
    getTitle
  } = useImageSEO();
  const [order, setOrder] = useState<Order>();
  const {
    pushModal
  } = useModalActions();
  const {
    formatMessage
  } = useFormat({
    name: 'checkout'
  });
  const [orderTracked, setOrderTracked] = useState(false);
  const isEmilio = getProjectInfo()?.projectId === 'ea';
  useEffect(() => {
    getOrder().then(order => setOrder(order));
  }, []);
  useEffect(() => {
    if (order && !orderTracked) {
      const datalayerItems = order.lineItems?.map((lineItem: LineItem, index) => ItemMapper.lineItemToItem(lineItem, lineItem.count, index, lineItem.variant, true));
      const datalayerCouponCodes = joinDiscounts(order.discountCodes, order.customLineItems);
      const tax = order.sum.centAmount - order.taxed.amount.centAmount;
      if (datalayerItems.length > 0) {
        const econdaDataLayer = JSON.parse(sessionStorage.getItem('econda_data_layer'));
        new TagManager().purchaseEvent(calculateCartLineItemsPrice(order) ?? order.sum, tax, order.shippingInfo.price, order.orderId, datalayerItems, datalayerCouponCodes).updateEcondaDataEvent(getEcondaPurchaseLayer(order, account, econdaDataLayer)).orderSubmitEvent(order).customEvent('ecomm', {
          ecomm_pagetype: 'purchase',
          ecomm_prodid: order.lineItems.map(lineItem => lineItem.variant.sku.split(/[-.]/)[0]),
          ecomm_totalvalue: (calculateCartLineItemsPrice(order)?.centAmount ?? order.sum.centAmount) / 100
        }).executePush();
        setOrderTracked(true);
      }
    }
  }, [order]);
  const showModal = () => {
    pushModal({
      id: '',
      title: '',
      content: <OrderConfirmationModal order={order} deliveryTimeDE={deliveryTimeDE} deliveryTimeEU={deliveryTimeEU} orderModalImage={orderModalImage} orderModalEmailText={orderModalEmailText} orderModalLastText={orderModalLastText} orderModalPrepaymentHint={orderModalPrepaymentHint} orderModalPrepaymentAccountData={orderModalPrepaymentAccountData} />,
      canCloseByBackdropClick: true,
      isLarge: true
    });
  };
  if (!order) {
    return null;
  }
  return <div className={styles.thankYou} data-sentry-component="ThankYou" data-sentry-source-file="index.tsx">
      <div className={styles.thankYouContent}>
        <div className={styles.orderInfo}>
          <div>
            <strong>
              {formatMessage({
              id: 'orderNumber',
              defaultMessage: 'Auftragsnummer:'
            })}
            </strong>
            <span>{order.orderId}</span>
          </div>
          <div>
            <strong>
              {formatMessage({
              id: 'delivery',
              defaultMessage: 'Lieferung:'
            })}
            </strong>
            <span>{order.shippingAddress?.country.toLowerCase() === 'de' ? deliveryTimeDE : deliveryTimeEU}</span>
          </div>
          <div>{shippingBy}</div>
        </div>
        {order?.billingAddress && order?.lineItems?.length > 0 && <div>
            <button className={classNames('btn', styles.orderConfirmationBtn)} onClick={showModal}>
              {orderConfirmationIcon?.media?.file && <Image src={orderConfirmationIcon.media.file} alt={getTitle(orderConfirmationIcon)} title={getTitle(orderConfirmationIcon)} width={30} />}
              <span>{orderConfirmationLabel}</span>
            </button>
          </div>}
        <div className={styles.trustedShopIcons}>
          <div className={styles.trustedShopIcons}>
            <img src={trustedShopIcon.media.file} alt={getTitle(trustedShopIcon)} title={getTitle(trustedShopIcon)} width={50} />
            <span>{trustedShopText}</span>
          </div>
          <div className={styles.trustedShopIcons}>
            <img src={sslIcon.media.file} alt={getTitle(sslIcon)} title={getTitle(sslIcon)} width={50} />
            <span>{sslText}</span>
          </div>
        </div>
      </div>
      {!isEmilio && <Sovendus order={order} />}
      <TrustedShopsCheckout order={order} data-sentry-element="TrustedShopsCheckout" data-sentry-source-file="index.tsx" />
    </div>;
};
export default ThankYou;