import { SectionLayout } from '@engbers/components';
import { Coupons } from '@engbers/components/online-shops/coupons';
interface ICouponVouchersTasticData {
  placeholderLabel?: string;
  description?: string;
}
interface ICouponVouchersTasticComponent {
  data: ICouponVouchersTasticData;
}
export const CouponsTastic = ({
  data
}: ICouponVouchersTasticComponent) => {
  return <SectionLayout data-sentry-element="SectionLayout" data-sentry-component="CouponsTastic" data-sentry-source-file="index.tsx">
      <Coupons description={data.description} placeholderLabel={data.placeholderLabel} data-sentry-element="Coupons" data-sentry-source-file="index.tsx" />
    </SectionLayout>;
};