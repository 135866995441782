import classNames from 'classnames';
import React from 'react';
import dynamic from 'next/dynamic';
import styles from './notification-wrap.module.css';
import { useRecoilValue } from 'recoil';
import { toastNotificationsState } from '@frontastic-engbers/lib/state/notification/atoms';
import { IconCustom } from '@engbers/components';
const AnimatePresence = dynamic(() => import('framer-motion').then(module => module.AnimatePresence));
const CustomMotion = dynamic(() => import('@frontastic-engbers/lib/lib/custom-motion'));
export interface IToastNotifications {
  [key: string]: IToastNotification;
}
export interface IToastNotification {
  message: string | React.ReactNode;
  type?: 'success' | 'error';
}
export interface INotificationComponent {
  zIndex?: number;
}
export const NotificationWrap: React.FC<INotificationComponent> = ({
  zIndex = 1001
}) => {
  const toastNotifications = useRecoilValue(toastNotificationsState);
  const ICON_COLORS: any = {
    success: 'green',
    error: 'danger',
    dark: 'green'
  };
  return <div className={classNames(styles.notificationsContainer)} style={{
    zIndex
  }} data-sentry-component="NotificationWrap" data-sentry-source-file="index.tsx">
      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="index.tsx">
        {Object.keys(toastNotifications).map((toastNotificationKey: string) => {
        const currentNotification = toastNotifications[toastNotificationKey];
        return <CustomMotion layout key={`toast-notification-${toastNotificationKey}`} initial={{
          y: -10,
          opacity: 0
        }} animate={{
          y: 0,
          opacity: 1
        }} exit={{
          y: -10,
          opacity: 0
        }} classNames={classNames(styles.toastNotification, {
          [styles[`toastNotification--type-${currentNotification.type}`]]: currentNotification.type
        })}>
              <IconCustom className={styles.icon} icon="Info" color={ICON_COLORS[currentNotification.type || 'success']} />
              <div className={styles.message}>{currentNotification.message}</div>
            </CustomMotion>;
      })}
      </AnimatePresence>
    </div>;
};