import { atom } from 'recoil';
import { CheckoutErrorsType } from '@frontastic-engbers/types/engbers-custom';

export const checkoutErrorsState = atom<CheckoutErrorsType>({
  key: 'checkoutErrorsState',
  default: undefined,
});

export const isCheckoutSubmittedState = atom<boolean>({
  key: 'isCheckoutSubmittedState',
  default: false,
});
