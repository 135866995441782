import React, { useState } from 'react';
import { tz } from 'moment-timezone';
import classnames from 'classnames';
import { Countdown, Markdown, Slider } from '@engbers/components';
import { useIsMounted } from '@frontastic-engbers/helpers/hooks/useIsMounted';
import styles from './action-banner.module.scss';
interface ActionBannerProps {
  isActive: boolean;
  showCountdown: boolean;
  backgroundColor: string;
  textColor: string;
  countdownTextColor: string;
  countdownClockColor: string;
  startDateTime: string;
  endDateTime: string;
  sliderDelay: number;
  actions: ActionProp[];
}
interface ActionProp {
  action: string;
}
interface ActionBannerData {
  data: ActionBannerProps;
  isFullWidth?: boolean;
}
export const ActionBanner: React.FC<ActionBannerData> = ({
  data,
  isFullWidth = false
}) => {
  const {
    isActive,
    showCountdown,
    backgroundColor,
    textColor,
    countdownTextColor,
    countdownClockColor,
    startDateTime,
    endDateTime,
    sliderDelay,
    actions
  } = data;
  const startTime = tz(data.startDateTime, 'Europe/Berlin');
  const endTime = tz(data.endDateTime, 'Europe/Berlin');
  const now = tz(Date.now(), 'Europe/Berlin');
  const [hidden, setHidden] = useState<boolean>(!now.isBetween(startTime, endTime));
  const isMounted = useIsMounted();
  if (!isActive || !backgroundColor.length || !textColor.length || !startDateTime.length || !endDateTime.length || !actions || !actions.length || hidden || !isMounted) {
    return null;
  }
  return <div className={classnames({
    [styles.actionBannerWrap]: isFullWidth
  })} style={{
    '--backgroundColor': backgroundColor,
    '--textColor': textColor
  } as React.CSSProperties} data-sentry-component="ActionBanner" data-sentry-source-file="index.tsx">
      <div className={styles.actionBannerSlider}>
        {actions.length > 1 ? <Slider dots={false} autoHeight autoplay={{
        delay: sliderDelay,
        disableOnInteraction: false
      }} loop overlapDots>
            {actions.map((action, index) => <div key={`action-banner-${index}`} className={styles.contentWrapper}>
                {showCountdown ? <Countdown end={endTime} onComplete={() => setHidden(true)} countdownTextColor={countdownTextColor} countdownClockColor={countdownClockColor} /> : null}
                <Markdown className={styles.actionBannerMarkdown} key={`slider-key-${index}`} text={action.action} />
              </div>)}
          </Slider> : <div className={styles.contentWrapper}>
            {showCountdown ? <Countdown end={endTime} onComplete={() => setHidden(true)} countdownTextColor={countdownTextColor} countdownClockColor={countdownClockColor} /> : null}
            <Markdown className={styles.actionBannerMarkdown} text={actions[0].action} />
          </div>}
      </div>
    </div>;
};