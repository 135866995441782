import React, { useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import regexifyString from 'regexify-string';
import classnames from 'classnames';
import { Markdown } from '@engbers/components';
import { useSnippets } from '@frontastic-engbers/lib/actions/config';
import styles from './index.module.scss';
const tooltipRegex = /<tooltip>(.*?)<\/tooltip>/g;
const getRegex = () => {
  tooltipRegex.lastIndex = 0; // reset lastIndex to be able to reuse the regex variable
  return tooltipRegex;
};
export type Props = {
  className?: string;
  shippingCostsHint?: string;
  shippingCostsTooltip?: string;
  tooltipId: string;
};
export const ShippingCostsTooltip: React.FC<Props> = ({
  className,
  tooltipId
}) => {
  const {
    snippetHintShippingCosts: hint,
    snippetTooltipShippingCosts: tooltip
  } = useSnippets();
  const ttId = `shippingcosts-${tooltipId}`;
  const hintIncludesTooltip = getRegex().test(hint);
  const result = useMemo(() => {
    if (!hintIncludesTooltip) {
      return [hint];
    }
    return regexifyString({
      pattern: getRegex(),
      decorator: (match, index, result) => <span data-tooltip-id={ttId} key={match}>
          <Markdown className={styles.markdown} text={result[1]} />
        </span>,
      input: hint
    });
  }, [hint, hintIncludesTooltip]);
  if (!hint || !tooltip) {
    return null;
  }
  return <div className={classnames(styles.container, className)} data-tooltip-id={hintIncludesTooltip ? undefined : ttId} data-sentry-component="ShippingCostsTooltip" data-sentry-source-file="index.tsx">
      {result.map(item => typeof item === 'string' ? <Markdown className={styles.markdown} text={item} key={item} /> : item)}
      <Tooltip id={ttId} className={styles.tooltip} key={result as never} data-sentry-element="Tooltip" data-sentry-source-file="index.tsx">
        <Markdown className={styles.markdown} text={tooltip} data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
      </Tooltip>
    </div>;
};