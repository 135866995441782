import React from 'react';
import { motion } from 'framer-motion';
import classnames from 'classnames';
import { IconCustom, Link } from '@engbers/components';
import { SuggestedPage } from '@frontastic-engbers/types/engbers-custom';
import styles from './suggested-category.module.scss';
export interface ISuggestedCategory {
  category: SuggestedPage;
  query: string;
}
export const SuggestedCategory: React.FC<ISuggestedCategory> = ({
  category,
  query
}) => {
  const highlightTerm = (text: string) => {
    return text.replace(new RegExp('(' + query + ')', 'gi'), '<span class="text-xs-bold">$1</span>');
  };
  if (!category?.name || !category?.link) {
    return null;
  }
  const breadcrumbs = category.breadcrumbs?.map(breadcrumb => breadcrumb.name) ?? [];
  return <motion.div style={{
    overflow: 'hidden'
  }} initial={{
    height: 'auto'
  }} animate={{
    height: 'auto'
  }} exit={{
    height: 0
  }} transition={{
    duration: 0.2
  }} data-sentry-element="unknown" data-sentry-component="SuggestedCategory" data-sentry-source-file="index.tsx">
      <Link onClick={() => {
      (document.activeElement as HTMLElement)?.blur();
    }} href={category.link} className={classnames(styles.link, styles.categoryBox)} data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <div className={styles.details}>
          <span dangerouslySetInnerHTML={{
          __html: highlightTerm(category.name)
        }} />
          <span className="text-gray-400">{breadcrumbs.join(' > ')}</span>
        </div>
        <IconCustom icon="ChevronRightIcon" width={18} className={styles.chevron} data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
      </Link>
    </motion.div>;
};