import { Variant } from '@frontastic-engbers/types/product/Variant';
import { Cart } from '@frontastic-engbers/types/cart/Cart';
import { sizeSuggestionConfig } from './config';
import { getSKUParts } from '../getSKUParts';

type LabelType = 'engbers' | 'emilio adani';
type FormType = 'regular' | 'slim fit';
type SizeGroup = Record<string, number>;
type Modifiers = {
  Label: Record<LabelType, number>;
  Form?: Record<FormType, number>;
};
export type SizeSuggestionConfig = {
  sizeGroups: Record<string, SizeGroup>;
  productGroups: Record<
    string,
    {
      sizeGroups: string[];
      modifiers: Modifiers;
    }
  >;
};

export class VariantSizeSuggestion implements Variant {
  sku;
  attributes;

  constructor(variant: Variant) {
    this.sku = variant.sku;
    this.attributes = variant.attributes as NonNullable<typeof variant.attributes>;
  }

  private get Form(): FormType {
    return this.attributes.FormDescription;
  }

  private get Label(): LabelType {
    return this.attributes.DesignerName;
  }

  private get ProductGroup(): string {
    return this.attributes.ProductGroupDescriptionWithoutPurchGroup;
  }

  private get Size(): string {
    return this.attributes.Sizing;
  }

  private get ParentId(): string {
    return getSKUParts(this.sku).key;
  }

  public getSizeSuggestion(cart: Cart) {
    const sizeGroup = this.getSizeGroup();

    if (!sizeGroup) {
      return undefined;
    }

    const cartItems = cart.lineItems as NonNullable<typeof cart.lineItems>;
    let maxSizeArticle: VariantSizeSuggestion = this;

    for (const cartItem of cartItems) {
      const otherArticle = new VariantSizeSuggestion(cartItem.variant as NonNullable<typeof cartItem.variant>);

      if (
        this.sku !== otherArticle.sku &&
        this.ParentId !== otherArticle.ParentId &&
        this.ProductGroup === otherArticle.ProductGroup &&
        (this.Label !== otherArticle.Label || this.Form !== otherArticle.Form) &&
        sizeGroup[otherArticle.Size] &&
        otherArticle.getUnifiedSize() > maxSizeArticle.getUnifiedSize()
      ) {
        maxSizeArticle = otherArticle;
      }
    }

    if (maxSizeArticle.sku !== this.sku) {
      return this.getVarSelectByUnifiedSize(maxSizeArticle.getUnifiedSize());
    }

    return undefined;
  }

  private getUnifiedSize() {
    const modifiers = this.getProductGroupConfig().modifiers;
    const sizes = this.getSizeGroup();
    let size = sizes[this.Size];

    if (!size) {
      return 0;
    }

    for (const [attributeName, values] of Object.entries(modifiers)) {
      // @ts-ignore // proper typing for this seems impossible, attributeName is either
      size += values[this[attributeName]] ?? 0;
    }

    return size;
  }

  private getVarSelectByUnifiedSize(unifiedSize: number) {
    const modifiers = this.getProductGroupConfig().modifiers;

    for (const [attributeName, values] of Object.entries(modifiers)) {
      // @ts-ignore // proper typing for this seems impossible
      unifiedSize -= values[this[attributeName]] ?? 0;
    }

    const sizes = this.getSizeGroup();
    return Object.keys(sizes).find((key) => sizes[key] === unifiedSize);
  }

  private getSizeGroup() {
    const sizeGroups = sizeSuggestionConfig.sizeGroups;
    const productGroupConfig = this.getProductGroupConfig();
    const sizeGroupNames = productGroupConfig?.sizeGroups;

    if (!sizeGroupNames) {
      return {};
    }

    let sizeGroup: SizeGroup = {};

    for (const sizeGroupName of sizeGroupNames) {
      if (sizeGroups[sizeGroupName][this.Size]) {
        sizeGroup = sizeGroups[sizeGroupName];
      }
    }

    return sizeGroup;
  }

  private getProductGroupConfig() {
    const productGroups = sizeSuggestionConfig.productGroups;

    return productGroups[this.ProductGroup];
  }
}
