import { Image, SectionLayout } from '@engbers/components';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { ImageExtended } from '@engbers/components/image/image-extended';
const EngbersImageTastic = ({
  data
}) => {
  if (data?.btnActive || data?.textActive) {
    const imageExtended = <ImageExtended media={data.image?.media} link={data?.link} alt={data.image?.name} isFullWidth={data.isFullWidth} allElementsRedirect={data?.allElementsRedirect} containerWidth={data?.containerWidth} containerBackgroundColor={data?.containerBackgroundColor} verticalAlignment={data?.verticalAlignment} horizontalAlignment={data?.horizontalAlignment} imgPadding={data?.imgPadding} containerPadding={data?.containerPadding} priority={data.priority} button={{
      btnActive: data?.btnActive,
      buttonText: data?.buttonText,
      buttonIcon: data?.buttonIcon,
      buttonIconWidth: data?.buttonIconWidth,
      buttonWidth: data?.buttonWidth,
      buttonTextColor: data?.buttonTextColor,
      buttonBackgroundColor: data?.buttonBackgroundColor,
      buttonAlignment: data?.buttonAlignment
    }} text={{
      textActive: data?.textActive,
      markdownText: data?.text,
      textColor: data?.textColor,
      textAlignment: data?.textAlignment
    }} />;
    return data.isFullWidth ? <FullPageWidthWrapper>{imageExtended}</FullPageWidthWrapper> : imageExtended;
  }
  if (data?.isFullWidth) {
    return <FullPageWidthWrapper>
        <Image media={data.image?.media} link={data.link} isFullWidth={data.isFullWidth} priority={data.priority} />
      </FullPageWidthWrapper>;
  }
  if (data?.hasMargin) {
    return <SectionLayout>
        <Image media={data.image?.media} link={data.link} priority={data.priority} />
      </SectionLayout>;
  }
  return <Image media={data.image?.media} link={data.link} priority={data.priority} data-sentry-element="Image" data-sentry-component="EngbersImageTastic" data-sentry-source-file="index.tsx" />;
};
export default EngbersImageTastic;