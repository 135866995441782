import React from 'react';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { LinksCol2Type, LinksCol3Type, LinksCol4Type, LinksCol5Type, PartnersLinksType } from '.';
export interface Column {
  header: string;
  links: LinksCol2Type[] | LinksCol3Type[] | LinksCol4Type[] | LinksCol5Type[] | PartnersLinksType[];
  className?: string;
  partnerClassName?: string;
  columnCount: number;
  partners?: {
    partnersHeader: string;
    partnersIcon: MediaType;
    partnersLinks: PartnersLinksType[];
  }[];
}
const checkForPartnersLinks = (links: any[]): links is PartnersLinksType[] => {
  return Array.isArray(links) && links.every(link => typeof link === 'object' && 'partnersName' in link && 'partnersReference' in link);
};
const Column: React.FC<Column> = ({
  header,
  links,
  className,
  partnerClassName,
  columnCount,
  partners
}) => {
  const isPartnersLinks = checkForPartnersLinks(links);
  return <div className={className} data-sentry-component="Column" data-sentry-source-file="column.tsx">
      <h5>{header}</h5>
      <ul role="list">
        {isPartnersLinks ? links.map((item, i) => <li key={`column-${columnCount}-${i}`}>
              <ReferenceLink target={item.partnersReference}>{item.partnersName}</ReferenceLink>
            </li>) : links.map((item, i) => <li key={`column-${columnCount}-${i}`}>
              <ReferenceLink target={item[`referenceCol${columnCount}`]}>
                {item[`nameCol${columnCount}`]}
              </ReferenceLink>
            </li>)}
      </ul>

      {partners && partners.length ? partners.map((partner, index) => <div key={`partner-${partner.partnersHeader}-${index}`} className={partnerClassName}>
            <h5>{partner.partnersHeader}</h5>
            <ul role="list">
              {partner.partnersLinks.map((item, i) => <li key={`partner-${columnCount}-${i}`}>
                  <ReferenceLink target={item.partnersReference}>{item.partnersName}</ReferenceLink>
                </li>)}
            </ul>
          </div>) : null}
    </div>;
};
export default Column;