import React from 'react';
import classnames from 'classnames';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { useAccount } from '@frontastic-engbers/lib';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { IFTLinkReference, IFTPageFolderReference, MediaType } from '@frontastic-engbers/types/engbers-custom';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { Image, Markdown } from '@engbers/components';
import styles from './overview.module.scss';
type AccountOverviewType = {
  overviewHeadline: string;
  textSize?: number;
  overviewLinks: AccountOverviewLinkType[];
  isEmilio?: boolean;
};
type AccountOverviewLinkType = {
  overviewIcon: MediaType;
  overviewTitle: string;
  overviewText: string;
  overviewLinkText: string;
  reference: IFTPageFolderReference | IFTLinkReference;
  overviewImage: MediaType;
  isEngbersCard: boolean;
};
export const AccountOverview: React.FC<AccountOverviewType> = ({
  overviewHeadline,
  textSize,
  overviewLinks,
  isEmilio = false
}) => {
  const {
    t
  } = useI18n();
  const {
    getTitle
  } = useImageSEO();
  const {
    account
  } = useAccount();
  const {
    formatMessage
  } = useFormat({
    name: 'account'
  });
  return <div data-sentry-component="AccountOverview" data-sentry-source-file="index.tsx">
      <div className={styles.headlineWrap}>
        <p>
          {formatMessage({
          id: 'account.overview.welcomeMsg',
          values: {
            salutation: account.salutation === 'MR' ? 'Herr' : 'Frau'
          }
        })}
          <strong>
            {account.firstName} {account.lastName}
            {account.cardId && formatMessage({
            id: 'account.overview.welcomeMsg.enCard',
            values: {
              cardId: account.cardId
            }
          })}
          </strong>
        </p>
        <Markdown text={overviewHeadline} textSize={textSize} disableMargin data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
      </div>

      <div className={styles.contentWrap}>
        {overviewLinks.length ? overviewLinks.map((overviewLink, index) => {
        if (overviewLink?.overviewImage?.media?.file) {
          return <div key={`account-overview-${index}`} className={styles.overviewLinkImage}>
                  <Image src={overviewLink.overviewImage.media.file} alt={getTitle(overviewLink.overviewImage)} title={getTitle(overviewLink.overviewImage)} />
                </div>;
        }
        return <div key={`account-overview-${index}`} className={styles.overviewLink}>
                <div className={classnames(styles.overviewLinkHeadline, {
            [styles.emilioOverviewLinkHeadline]: isEmilio
          })}>
                  {overviewLink?.overviewIcon?.media?.file && <Image src={overviewLink.overviewIcon.media.file} width={18} alt={getTitle(overviewLink.overviewIcon)} title={getTitle(overviewLink.overviewIcon)} />}
                  <h5>
                    <ReferenceLink target={overviewLink.reference}>
                      {overviewLink.overviewTitle}
                      {overviewLink.isEngbersCard && account.cardId ? ` ${account.cardId}` : null}
                    </ReferenceLink>
                  </h5>
                </div>
                <p className={styles.overviewLinkText}>{overviewLink.overviewText}</p>
                <ReferenceLink target={overviewLink.reference} className={classnames(styles.overviewLinkTextLink, {
            'mt-auto': index > 1
          })}>
                  {overviewLink.overviewLinkText}
                </ReferenceLink>
              </div>;
      }) : null}
      </div>
    </div>;
};