import React from 'react';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { ITabMenuItem } from '../../online-shops/tab-menu/components/tab-menu-item';
import { MyAccountHeadline } from '../my-account-headline';
import { TabMenu } from '../../online-shops/tab-menu';
type Props = {
  headlineText: string;
  textSize?: number;
  headlineIcon: MediaType;
  items: ITabMenuItem[];
};
export const MyVouchers: React.FC<Props> = ({
  headlineText,
  textSize,
  headlineIcon,
  items
}) => {
  return <div data-sentry-component="MyVouchers" data-sentry-source-file="index.tsx">
      <MyAccountHeadline headlineText={headlineText} textSize={textSize} iconMedia={headlineIcon} iconCustom="CardsOpen" uppercase data-sentry-element="MyAccountHeadline" data-sentry-source-file="index.tsx" />
      <TabMenu items={items} data-sentry-element="TabMenu" data-sentry-source-file="index.tsx" />
    </div>;
};