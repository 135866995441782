import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { getProjectInfo } from '@frontastic-engbers/lib';
import { useTrustedShopsConfig } from '@frontastic-engbers/lib/actions/config';
import { languageMapper } from '@frontastic-engbers/lib/project.config';
import { TrustedShopsScript } from '../script';
type Props = {
  sku: string;
};
export const TrustedShopsReviews: React.FC<Props> = ({
  sku
}) => {
  const {
    defaultLocale,
    locale
  } = useRouter();
  const projectInfo = getProjectInfo();
  const trustedShopsConfig = useTrustedShopsConfig();
  const scriptRef = useRef<HTMLScriptElement>();
  useEffect(() => {
    if (trustedShopsConfig?.trustedShopsId && scriptRef.current) {
      window._tsProductReviewsConfig = {
        tsId: trustedShopsConfig?.trustedShopsId,
        apiServer: 'https://cdn1.api.trustedshops.com/',
        variant: 'productreviews',
        theme: 'light',
        element: '#review_sticker',
        locale: languageMapper[locale || defaultLocale],
        richSnippets: 'on',
        borderColor: '#f3f3f1',
        backgroundColor: '#ffffff',
        sku,
        starSize: '15px',
        starColor: '#ffdc0f',
        maxHeight: '1200px',
        hideEmptySticker: true,
        commentBorderColor: '#f3f3f1',
        introtext: trustedShopsConfig.trustedShopsIntroText,
        script: scriptRef.current
      };
    }
  }, [trustedShopsConfig?.trustedShopsId, scriptRef.current]);
  if (projectInfo?.projectId !== 'en' || !trustedShopsConfig?.trustedShopsId || !sku) {
    return null;
  }
  return <>
      <TrustedShopsScript src="reviews/tsSticker/tsProductSticker.js" refCallback={script => scriptRef.current = script} data-sentry-element="TrustedShopsScript" data-sentry-source-file="index.tsx" />
      <div id="review_sticker" />
    </>;
};