import React from 'react';
import Image from 'next/image';
import classnames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { useEngbersFlagsConfig } from '@frontastic-engbers/lib/actions/config';
import { NewFlag as Flag } from '@frontastic-engbers/types/product/Flag';
import styles from './new-flag.module.scss';
type Props = {
  flag: Flag;
  className?: string;
  width?: number;
  height?: number;
};
export const NewFlag: React.FC<Props> = ({
  flag,
  className,
  width = 37,
  height = 20
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'product'
  });
  const {
    flagsConfig,
    isLoading
  } = useEngbersFlagsConfig();
  const {
    getTitle
  } = useImageSEO();
  const newFlagImage = flag.flagImage ?? flagsConfig?.newFlagImage;
  return isLoading ? null : <div className={classnames(className, styles.productFlag, styles.new, {
    [styles.flagImage]: newFlagImage !== undefined
  })}>
      {newFlagImage ? <Image loader={({
      src
    }) => src} src={newFlagImage.media.file} alt={getTitle(newFlagImage)} title={getTitle(newFlagImage)} width={newFlagImage.media.width ?? width} height={newFlagImage.media.height ?? height} /> : <>
          {formatMessage({
        id: 'new',
        defaultMessage: 'NEU'
      })}
        </>}
    </div>;
};