import React, { useEffect } from 'react';
import { getProjectInfo } from '@frontastic-engbers/lib';
import { useTrustedShopsConfig } from '@frontastic-engbers/lib/actions/config';
import { TrustedShopsScript } from '../script';
import styles from './trusted-shops-badge.module.scss';
const allowedProjects = ['en', 'ea'];
export interface ITrustedBadge {
  trustedText: string;
}
export const TrustedShopsBadge: React.FC<ITrustedBadge> = ({
  trustedText = null
}) => {
  const projectInfo = getProjectInfo();
  const trustedShopsConfig = useTrustedShopsConfig();
  useEffect(() => {
    if (trustedShopsConfig?.trustedShopsId) {
      window._tsConfig = {
        yOffset: '0',
        variant: 'custom',
        customElementId: 'customTrustBadge',
        trustcardDirection: 'bottomRight',
        customBadgeWidth: '40',
        customBadgeHeight: '40',
        disableResponsive: 'true',
        disableTrustbadge: 'false'
      };
    }
  }, [trustedShopsConfig?.trustedShopsId]);
  if (!allowedProjects.includes(projectInfo?.projectId) || !trustedShopsConfig?.trustedShopsId) {
    return null;
  }
  return <>
      <TrustedShopsScript src={`js/${trustedShopsConfig?.trustedShopsId}.js`} data-sentry-element="TrustedShopsScript" data-sentry-source-file="index.tsx" />
      <div id="customTrustBadge" className={styles.trustedShopContainer} />
      <div className={styles.trustedShopText}>{trustedText}</div>
    </>;
};