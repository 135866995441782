import { Vouchers } from '@engbers/components/online-shops/vouchers';
interface Coupon {
  image: any;
  voucherCode: string;
}
interface ICouponVouchersTasticData {
  placeholderLabel?: string;
  voucherItems?: Array<Coupon>;
}
interface ICouponVouchersTasticComponent {
  data: ICouponVouchersTasticData;
}
export const VouchersTastic = ({
  data
}: ICouponVouchersTasticComponent) => {
  return <div data-sentry-component="VouchersTastic" data-sentry-source-file="index.tsx">
      <Vouchers couponsAsImage={data.voucherItems} placeholderLabel={data.placeholderLabel} data-sentry-element="Vouchers" data-sentry-source-file="index.tsx" />
    </div>;
};