import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { SearchAgain } from '@engbers/components/online-shops/search-again';
import { Block } from '@engbers/components';
export const EngbersSearchAgainTastic = ({
  data
}) => {
  const searchAgain: JSX.Element = <SearchAgain sectionText={data.sectionText} searchPlaceholderText={data.searchPlaceholderText} />;
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>
        {searchAgain}
      </FullPageWidthWrapper>;
  }
  return <Block data-sentry-element="Block" data-sentry-component="EngbersSearchAgainTastic" data-sentry-source-file="index.tsx">
      {searchAgain}
    </Block>;
};