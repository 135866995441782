import { fetchApiHub } from '../../lib/fetch-api-hub';
import { ContactFormData } from '@engbers/shop-backend/inxmail-commerce/types/Mails';
import { RaffleData } from '@frontastic-engbers/types/engbers-custom';

export const sendContactMessage = async (formData: ContactFormData) => {
  return await fetchApiHub('/action/service/sendContactMessage', { method: 'POST' }, formData);
};

export const sendRaffleRequest = async (formData: RaffleData) => {
  return await fetchApiHub('/action/service/sendRaffleData', { method: 'POST' }, formData);
};
