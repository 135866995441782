import { AccordionItem, Markdown } from '@engbers/components';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import styles from './accordion-item.module.scss';
const EngbersAccordionItemTastic = ({
  data
}) => {
  if (data?.isFullWidth) {
    return <FullPageWidthWrapper>
        <AccordionItem label={data.label} textSize={data.textSize} isInitiallyOpen={data.isInitiallyOpen}>
          <Markdown className={styles.text} text={data.content} disableMargin />
        </AccordionItem>
      </FullPageWidthWrapper>;
  }
  return <AccordionItem label={data.label} textSize={data.text} isInitiallyOpen={data.isInitiallyOpen} data-sentry-element="AccordionItem" data-sentry-component="EngbersAccordionItemTastic" data-sentry-source-file="index.tsx">
      <Markdown className={styles.text} text={data.content} disableMargin data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
    </AccordionItem>;
};
export default EngbersAccordionItemTastic;