import { InfoBox, SectionLayout } from '@engbers/components';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
const EngbersInfoBoxTastic = ({
  data
}) => {
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>
        <InfoBox type="os" content={data.infoText} reference={data.reference} />
      </FullPageWidthWrapper>;
  }
  return <SectionLayout data-sentry-element="SectionLayout" data-sentry-component="EngbersInfoBoxTastic" data-sentry-source-file="index.tsx">
      <InfoBox type="os" content={data.infoText} reference={data.reference} data-sentry-element="InfoBox" data-sentry-source-file="index.tsx" />
    </SectionLayout>;
};
export default EngbersInfoBoxTastic;