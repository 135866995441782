import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useFormat } from './hooks/useFormat';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';

// regex to ensure we get the clean path and page, filter and query just included to "get rid of it"
const productListRegex = /(?<path>\/.*?)(?:\/(?<filter>(?:_.*?)+))?(?:\/(?<page>\d+)?)?(?:\/?(?<query>\?.*))?$/;
const excludedPaths = ['/login', '/mein-konto', '/cart'];
type Props = {
  seoTitle: string;
  seoDescription: string;
  siteUrl: string;
};
export const MetaTags: React.FC<Props> = ({
  seoTitle,
  seoDescription,
  siteUrl
}) => {
  const {
    asPath
  } = useRouter();
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const title = seoTitle || formatMessage({
    id: 'opengraph.title'
  });
  const description = seoDescription || formatMessage({
    id: 'opengraph.description'
  });
  const siteName = formatMessage({
    id: 'opengraph.siteName'
  });
  let [path] = asPath.split('?');
  if (path.startsWith('/search/')) {
    path = '/search';
  }
  const match = path.match(productListRegex);
  if (match?.groups?.path) {
    path = match.groups.path;
  }
  const isExcluded = excludedPaths.some(excluded => path.includes(excluded));
  return <Head data-sentry-element="Head" data-sentry-component="MetaTags" data-sentry-source-file="metaTags.tsx">
      <title key="title">{title}</title>
      <meta name="description" content={description} key="description" data-sentry-element="meta" data-sentry-source-file="metaTags.tsx" />

      {!isExcluded && <>
          <meta property="og:site" content={siteUrl} key="og:site" />
          <meta property="og:site_name" content={siteName} key="og:site_name" />
          <meta property="og:title" content={title} key="og:title" />
          <meta property="og:description" content={description} key="og:description" />
          <meta property="og:type" content="website" key="og:type" />
          <meta property="og:url" content={siteUrl + path} key="og:url" />
          <meta property="og:image" content={`${siteUrl}/images/logo.png`} key="og:image" />
        </>}
    </Head>;
};
type PDPProps = {
  product: Product;
};
export const MetaPDP: React.FC<PDPProps> = ({
  product /*name, color*/
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const {
    productId,
    name,
    attributes
  } = product;
  const showColor = attributes?.BaseColor ? '' : '.nocolor';
  const values = {
    id: productId as string,
    name: name as string,
    color: attributes?.BaseColor,
    manufacturer: attributes?.DesignerName
  };
  const title = formatMessage({
    id: `opengraph.pdp${showColor}.title`,
    values
  });
  const description = formatMessage({
    id: `opengraph.pdp${showColor}.description`,
    values
  });
  return <Head data-sentry-element="Head" data-sentry-component="MetaPDP" data-sentry-source-file="metaTags.tsx">
      <title key="title">{title}</title>
      <meta name="description" content={description} key="description" data-sentry-element="meta" data-sentry-source-file="metaTags.tsx" />

      <meta property="og:title" content={title} key="og:title" data-sentry-element="meta" data-sentry-source-file="metaTags.tsx" />
      <meta property="og:description" content={description} key="og:description" data-sentry-element="meta" data-sentry-source-file="metaTags.tsx" />
      <meta property="og:type" content="product" key="og:type" data-sentry-element="meta" data-sentry-source-file="metaTags.tsx" />
      <meta property="og:image" content={product.variants[0].images?.[0]} key="og:image" data-sentry-element="meta" data-sentry-source-file="metaTags.tsx" />
    </Head>;
};
type ODPProps = {
  outfit: Outfit;
};
export const MetaODP: React.FC<ODPProps> = ({
  outfit
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const values = {
    name: outfit.name as string
  };
  const title = formatMessage({
    id: 'opengraph.odp.title',
    values
  });
  const description = formatMessage({
    id: 'opengraph.odp.description',
    values
  });
  return <Head data-sentry-element="Head" data-sentry-component="MetaODP" data-sentry-source-file="metaTags.tsx">
      <title key="title">{title}</title>
      <meta name="description" content={description} key="description" data-sentry-element="meta" data-sentry-source-file="metaTags.tsx" />

      <meta property="og:title" content={title} key="og:title" data-sentry-element="meta" data-sentry-source-file="metaTags.tsx" />
      <meta property="og:description" content={description} key="og:description" data-sentry-element="meta" data-sentry-source-file="metaTags.tsx" />
      <meta property="og:type" content="product" key="og:type" data-sentry-element="meta" data-sentry-source-file="metaTags.tsx" />
      <meta property="og:image" content={outfit.variants[0].images?.[0]} key="og:image" data-sentry-element="meta" data-sentry-source-file="metaTags.tsx" />
    </Head>;
};