import { SuggestedPage } from '@frontastic-engbers/types/engbers-custom';
import { HitType } from '@frontastic-engbers/types/product/Hit';

export class PageMapper {
  private static readonly BREADCRUMB_NAME_BLACKLIST = ['startseite'];
  private static readonly BREADCRUMB_LINK_BLACKLIST = ['/'];

  public static algoliaHitToSuggestedPage(hit: HitType): SuggestedPage {
    return {
      name: hit.name ?? '',
      link: hit.link ?? '',
      breadcrumbs: PageMapper.algoliaHitToBreadcrumbs(hit),
    } as SuggestedPage;
  }

  private static algoliaHitToBreadcrumbs(hit: HitType): SuggestedPage[] | undefined {
    if (!hit.breadcrumbs || !Array.isArray(hit.breadcrumbs)) {
      return undefined;
    }

    const breadcrumbs: SuggestedPage[] = [];
    for (const { name, link } of hit.breadcrumbs) {
      const breadcrumb = {
        name: name ?? '',
        link: link ?? '',
      } as SuggestedPage;

      if (PageMapper.isValidBreadcrumb(breadcrumb)) {
        breadcrumbs.unshift(breadcrumb);
      }
    }

    return breadcrumbs.length > 0 ? breadcrumbs : undefined;
  }
  
  private static isValidBreadcrumb(breadcrumb: SuggestedPage): boolean {
    return breadcrumb.name.length > 0
      && !PageMapper.BREADCRUMB_NAME_BLACKLIST.includes(breadcrumb.name.toLowerCase())
      && !PageMapper.BREADCRUMB_LINK_BLACKLIST.includes(breadcrumb.link.toLowerCase());
  }
}
