import React from 'react';
import { markdown } from 'markdown';
import { InputCheckbox } from '@engbers/components/input-checkbox';
import { MailingListGroup } from '@engbers/components/online-shops/newsletter-resubscription/types';
import { GroupSelectionState } from '../../types';
import styles from './newsletter-resubscription-group.module.scss';
interface INewsletterResubscriptionGroupComponent {
  group: MailingListGroup;
  inputName?: string;
  currentState?: GroupSelectionState;
  updateGroupSelection: (groupId: string, listSelection: Array<string | number>) => void;
}
export const NewsletterResubscriptionGroup: React.FC<INewsletterResubscriptionGroupComponent> = ({
  group,
  inputName,
  currentState,
  updateGroupSelection
}) => {
  const showMailingLists = (currentState?.isSelected ?? false) && group.mailingLists.length > 1;
  const handleGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateGroupSelection(group.type, currentState.selectedLists ?? []);
  };
  const handleMailingListSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selection = currentState.selectedLists ?? [];
    const listId = event.target.id.substring(13);
    if (selection.includes(listId)) {
      updateGroupSelection(group.type, selection.filter(selectionId => listId !== selectionId));
    } else {
      updateGroupSelection(group.type, [...selection, listId]);
    }
  };
  return <div className={styles.newsletterResubscriptionGroup} data-sentry-component="NewsletterResubscriptionGroup" data-sentry-source-file="index.tsx">
      <div className={styles.selectionGroup}>
        <input type="radio" id={`mailing-list-group-${group.type}`} name={inputName ?? 'mailing-list-group'} value={group.type} onChange={handleGroupChange} className={styles.selectionMailingGroup} />
        <label className={styles.label} htmlFor={`mailing-list-group-${group.type}`} dangerouslySetInnerHTML={{
        __html: markdown.toHTML(group.groupLabel)
      }} />
      </div>
      {showMailingLists && <div className={styles.mailingListOptions}>
          {group.mailingLists.map(mailingList => <div key={`mailing-list-${mailingList.mailingListId}`} className={styles.selectionGroup}>
              <InputCheckbox id={`mailing-list-${mailingList.mailingListId}`} name={`${inputName || 'mailing-list'}-${mailingList.mailingListId}`} label={mailingList.label || `Mailing list ${mailingList.mailingListId}`} labelIsMarkdown checked={currentState?.selectedLists?.includes(mailingList.mailingListId)} onChange={handleMailingListSelection} hasHoverEffect={true} />
            </div>)}
        </div>}
    </div>;
};