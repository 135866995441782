import styles from './my-newsletter-benefits.module.css';
import { IconCustom } from '@engbers/components';
type AccountMyNewsletterBenefitsProps = {
  benefits: string[];
  benefitsTitle?: string;
  benefitsColor?: string;
};
export const AccountMyNewsletterBenefits: React.FC<AccountMyNewsletterBenefitsProps> = ({
  benefits,
  benefitsTitle,
  benefitsColor
}) => {
  return <div style={{
    color: benefitsColor
  }} data-sentry-component="AccountMyNewsletterBenefits" data-sentry-source-file="index.tsx">
      {benefitsTitle && <h4 className={styles.benefitsHeadline}>{benefitsTitle}</h4>}
      <ul className={styles.benefitsWrap}>
        {benefits.map((benefit: string, index: number) => <li key={`benefit-${index}`} className={styles.benefit}>
            <IconCustom width={16} color={'primary'} icon="CheckMarkThin" />
            {benefit}
          </li>)}
      </ul>
    </div>;
};