import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useCart } from '@frontastic-engbers/lib';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { mapCosts } from '@frontastic-engbers/helpers/utils/mapCosts';
import { cartHasFreeShippingItemsOnly } from '@frontastic-engbers/helpers/utils/cartHasFreeShippingItemsOnly';
import { getSelectedShippingAddress } from '@frontastic-engbers/helpers/utils/getShippingInfo';
import { Discount } from '@frontastic-engbers/types/cart/Discount';
import { CheckoutData } from '@frontastic-engbers/types/engbers-custom';
import { Textarea } from '@engbers/components';
import Price from '../../../../price';
import styles from './total-summary-overview.module.scss';
export interface ITotalSummaryOverview {
  commentOnOrderLabel: string;
  shippingCostsNote: string;
  showCommentOnOrderLabel: boolean;
  updateFormInput: (propName: string, newValue: string) => void;
  removeDiscountCode: (discount: Discount) => void;
  checkoutData: CheckoutData;
}
export const TotalSummaryOverview: React.FC<ITotalSummaryOverview> = ({
  commentOnOrderLabel,
  shippingCostsNote,
  showCommentOnOrderLabel,
  updateFormInput,
  removeDiscountCode,
  checkoutData
}) => {
  const {
    removeItem,
    data: cart
  } = useCart();
  const {
    formatMessage
  } = useFormat({
    name: 'checkout'
  });
  const {
    formatMessage: formatCartMessage
  } = useFormat({
    name: 'cart'
  });
  const costs = useMemo(() => {
    return mapCosts(cart, getSelectedShippingAddress(checkoutData)?.country);
  }, [cart, cart?.shippingInfo?.price?.centAmount]);
  return <div className={styles.summaryWrap} data-sentry-component="TotalSummaryOverview" data-sentry-source-file="index.tsx">
      <div className={styles.summaryBlockWrap}>
        {showCommentOnOrderLabel && <>
            <label htmlFor="commentOnOrder" className={styles.commentOnOrderLabel}>
              {commentOnOrderLabel}
            </label>
            <Textarea id="commentOnOrder" rows={2} style="white" value={checkoutData.commentOnOrder} onChange={e => {
          updateFormInput('commentOnOrder', e.target.value);
        }} />
          </>}
      </div>
      <div className={styles.summaryBlockWrap}>
        <div className={styles.totalRow}>
          <span>
            {formatMessage({
            id: 'subtotal',
            defaultMessage: 'Zwischensumme'
          })}
          </span>
          <Price price={costs.subtotal || {}} className={styles.price} showCurrencySymbol data-sentry-element="Price" data-sentry-source-file="index.tsx" />
        </div>
        {!cartHasFreeShippingItemsOnly(cart) && <div className={styles.totalRow}>
            <span>
              {formatMessage({
            id: 'shippingCosts',
            defaultMessage: 'Klimaneutraler Versand'
          })}
            </span>
            {costs.shipping.centAmount == null ? <div style={{
          fontSize: '12px'
        }}>
                {formatMessage({
            id: 'chooseShippingMethod',
            defaultMessage: 'bitte Versandart wählen'
          })}
              </div> : costs.shipping.centAmount === 0 ? <div className="text-success text-sm-bold">
                {formatMessage({
            id: 'freeShipping',
            defaultMessage: 'kostenlos'
          })}
              </div> : <Price price={costs.shipping} className={styles.price} showCurrencySymbol />}
          </div>}
        {cart?.discountCodes?.map(discountCode => <div className={styles.totalRow} key={`discountCode-${discountCode.code}`}>
            <span>
              <button className={styles.removeDiscountBtn} onClick={() => removeDiscountCode(discountCode)}>
                x
              </button>
              <span>{discountCode.description ?? discountCode.name}</span>
            </span>
          </div>)}
        {cart?.customLineItems?.map(lineItem => <div className={styles.totalRow} key={`discountCode-${lineItem.lineItemId}`}>
            <span>
              <button className={styles.removeDiscountBtn} onClick={() => removeItem(lineItem.lineItemId, true)}>
                x
              </button>
              <span>
                {lineItem.type?.length > 0 ? lineItem.type : formatCartMessage({
              id: 'voucher',
              defaultMessage: 'Gutschein'
            })}
              </span>
            </span>
            {lineItem.totalPrice.centAmount !== 0 && <Price price={lineItem.totalPrice || {}} className={styles.price} showCurrencySymbol />}
          </div>)}
        <div className={styles.totalRow}>
          <span className={styles.totalPrice}>
            {formatMessage({
            id: 'total',
            defaultMessage: 'GESAMTSUMME'
          })}
          </span>
          <Price price={costs.total || {}} isBold className={classNames(styles.price, styles.totalPrice)} showCurrencySymbol data-sentry-element="Price" data-sentry-source-file="index.tsx" />
        </div>
        {costs.savedPrice?.centAmount > 0 && <div className={styles.totalRow}>
            <span className={styles.savedPrice}>
              {formatMessage({
            id: 'youSaved',
            defaultMessage: 'Sie sparen'
          })}
            </span>
            <Price price={costs.savedPrice || {}} className={classNames(styles.price, styles.savedPrice)} showCurrencySymbol />
          </div>}
        <div className={styles.shippingCostsNote}>{shippingCostsNote}</div>
      </div>
    </div>;
};