import React, { useEffect, useRef, useState } from 'react';
import { Reference, ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { MediaType, TMediaItem } from '@frontastic-engbers/types/engbers-custom';
import { Button, CustomMarkdown, Image } from '@engbers/components';
import styles from './image-extended.module.scss';
interface IButton {
  btnActive: boolean;
  buttonText?: string;
  buttonIcon?: MediaType;
  buttonIconWidth?: number;
  buttonWidth?: number;
  buttonTextColor?: string;
  buttonBackgroundColor?: string;
  buttonAlignment?: 'flex-start' | 'center' | 'flex-end';
}
interface IText {
  textActive: boolean;
  markdownText?: string;
  textColor?: string;
  textAlignment?: 'left' | 'center' | 'right';
}
interface IImage {
  media?: TMediaItem;
  width?: string | number;
  link?: Reference;
  alt?: string;
  isFullWidth?: boolean;
  hasMargin?: boolean;
  allElementsRedirect?: boolean;
  containerWidth?: number;
  containerBackgroundColor?: string;
  verticalAlignment?: 'flex-start' | 'center' | 'flex-end';
  horizontalAlignment?: 'left' | 'center' | 'right';
  imgPadding?: number;
  containerPadding?: number;
  button?: IButton;
  text?: IText;
  priority?: boolean;
}
export const ImageExtended: React.FC<IImage> = ({
  media,
  link,
  alt,
  isFullWidth,
  allElementsRedirect,
  containerWidth,
  containerBackgroundColor,
  verticalAlignment,
  horizontalAlignment,
  imgPadding,
  containerPadding,
  button,
  text,
  priority = false
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const [lineCount, setLineCount] = useState<number>(100);
  const textPaddingBottom = 4;
  const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  function getAverageLineHeight(ref: React.MutableRefObject<HTMLDivElement>): number {
    if (!ref.current) {
      return 0;
    }
    const children = Array.from(ref.current?.children[0]?.children ?? []);
    if (children.length === 0) {
      return 0;
    }
    const lineHeights = children.map(child => {
      const computedStyle = window.getComputedStyle(child);
      return parseFloat(computedStyle.lineHeight);
    });
    const sum = lineHeights.reduce((acc, height) => acc + height, 0);
    return sum / children.length;
  }
  useEffect(() => {
    if (containerRef.current && imageRef.current && buttonRef.current) {
      const containerHeight = containerRef.current.offsetHeight;
      const imageHeight = imageRef.current.offsetHeight + imgPadding;
      const btnHeight = buttonRef.current.offsetHeight;
      const lineHeight = getAverageLineHeight(textRef);
      const freeSpaceImg = imageHeight - containerHeight > lineHeight ? imageHeight - containerHeight : 0;
      if (lineCount == 100) {
        setLineCount(Math.floor(freeSpaceImg / lineCount));
      }
      if (freeSpaceImg <= 0) {
        setLineCount(Math.max(1, Math.floor((containerHeight - btnHeight) / lineHeight)));
      }
      if (freeSpaceImg > 0) {
        setLineCount(prevState => {
          return prevState + Math.floor(freeSpaceImg / lineHeight);
        });
      }
    }
  }, [innerWidth, containerRef.current?.clientWidth]);
  const imageText = text?.textActive && text?.markdownText ? <div ref={textRef} className={styles.text} style={{
    color: text?.textColor || 'black',
    textAlign: text?.textAlignment || 'unset',
    paddingBottom: `${textPaddingBottom}px`,
    WebkitLineClamp: lineCount
  }}>
        <CustomMarkdown text={text?.markdownText} />
      </div> : null;
  const imageBtn = button?.btnActive ? <div className="inline-flex h-fit w-full" style={{
    justifyContent: button?.buttonAlignment || 'unset'
  }}>
      <div style={{
      width: `${button?.buttonWidth}%` || 0
    }} ref={buttonRef}>
        <Button label={button?.buttonText || ''} textColor={button?.buttonTextColor || 'unset'} color={button?.buttonBackgroundColor || 'unset'} customIconURL={button?.buttonIcon?.media?.file || ''} customIconWidth={`${button?.buttonIconWidth}`} hasIcon={!!button?.buttonIcon?.media?.file} link={link} />
      </div>
    </div> : null;
  const imageElement = <div className="relative">
      {media?.file ? <Image media={media} link={link} alt={alt} isFullWidth={isFullWidth} priority={priority} /> : <div style={{
      height: `${textRef.current?.offsetHeight + parseInt(containerRef.current?.style?.padding)}px`,
      width: `100%`
    }} />}

      <div className="absolute top-0 flex h-full w-full ">
        <div ref={imageRef} className="flex h-full w-full overflow-hidden" style={{
        padding: `${imgPadding}px` || 0,
        justifyContent: horizontalAlignment || 'unset',
        alignItems: verticalAlignment || 'unset'
      }}>
          <div ref={containerRef} style={{
          width: containerWidth > 0 ? `${containerWidth}%` : 'fit-content',
          backgroundColor: containerBackgroundColor || 'unset',
          padding: `${containerPadding}px` || 0
        }} className="absolute h-fit max-h-full">
            {imageText}
            {imageBtn}
          </div>
        </div>
      </div>
    </div>;
  if (link && allElementsRedirect) {
    return <ReferenceLink target={link}>{imageElement}</ReferenceLink>;
  }
  return imageElement;
};