import { useAccount } from '@frontastic-engbers/lib';
import { CustomMarkdown } from '@engbers/components';
import styles from './text.module.scss';
import classNames from 'classnames';
export const Text: React.FC<any> = ({
  text,
  backgroundColor,
  alignment,
  textColor,
  padding
}) => {
  const {
    account
  } = useAccount();
  let textWithReplacements = '';
  if (account) {
    textWithReplacements = text.replace('{firstName}', account.firstName).replace('{lastName}', account.lastName).replace('{email}', account.email).replace('{birthday}', account.birthday?.toLocaleDateString('de-DE')).replace('{address.city}', account.addresses?.[0]?.city).replace('{address.phone}', account.addresses?.[0]?.phone).replace('{address.postalCode}', account.addresses?.[0]?.postalCode).replace('{address.streetName}', account.addresses?.[0]?.streetName).replace('{address.streetNumber}', account.addresses?.[0]?.streetNumber).replace('{cardId}', account.cardId);
  } else {
    textWithReplacements = text;
  }
  return <div className={classNames(styles.text, {
    [styles[`alignment--${alignment}`]]: !!alignment,
    [`p-${padding}`]: !!padding
  })} style={{
    color: textColor,
    backgroundColor: backgroundColor
  }} data-sentry-component="Text" data-sentry-source-file="index.tsx">
      <CustomMarkdown text={textWithReplacements} data-sentry-element="CustomMarkdown" data-sentry-source-file="index.tsx" />
    </div>;
};