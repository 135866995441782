import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { getProjectInfo } from '@frontastic-engbers/lib';
import { CategoryImageText } from '@engbers/components/categroy-image-text';
export const EngbersCategoryImageTextTastic = ({
  data
}) => {
  const isEmilio = getProjectInfo()?.projectId === 'ea';
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>
        <CategoryImageText categoriesImageText={data.categoryImageText} isEmilio={isEmilio} />
      </FullPageWidthWrapper>;
  }
  return <CategoryImageText categoriesImageText={data.categoryImageText} isEmilio={isEmilio} data-sentry-element="CategoryImageText" data-sentry-component="EngbersCategoryImageTextTastic" data-sentry-source-file="index.tsx" />;
};