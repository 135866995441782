import React from 'react';
import { AutocompletePlugin, getAlgoliaResults } from '@algolia/autocomplete-js';
import { SearchResponse } from '@algolia/client-search';
import { SearchClient } from 'algoliasearch/dist/algoliasearch-lite';
import { PageMapper } from '@frontastic-engbers/lib/lib/mappers/pageMapper';
import { HitType } from '@frontastic-engbers/types/product/Hit';
import { SuggestedCategory } from '../suggested-category';
export const categoriesPlugin = (searchClient: SearchClient, algoliaConfig: any, suggestedCategoriesLimit: number, onClick: () => void) => {
  const categoryIndexName = algoliaConfig.dataSource.indexName.replace('products_', 'pages_');
  return {
    getSources({
      setQuery,
      refresh
    }) {
      return [{
        sourceId: 'categories',
        getItems({
          query,
          setContext,
          state
        }) {
          if (query.length < 3) {
            return [];
          }
          return getAlgoliaResults({
            searchClient,
            queries: [{
              indexName: categoryIndexName,
              query,
              params: {
                attributesToRetrieve: ['name', 'link', 'breadcrumbs'],
                hitsPerPage: state.context.showAllCategories ? suggestedCategoriesLimit : 3
              }
            }],
            transformResponse({
              hits,
              results
            }) {
              setContext({
                nbCategories: (results[0] as SearchResponse).nbHits
              });
              return hits;
            }
          });
        },
        onSelect() {
          onClick();
          setQuery('');
          refresh();
        },
        templates: {
          item({
            item,
            state
          }) {
            const category = PageMapper.algoliaHitToSuggestedPage(item);
            return <SuggestedCategory key={`suggested-category-${item.objectID}`} category={category} query={state.query} />;
          }
        }
      }];
    }
  } as AutocompletePlugin<HitType, {}>;
};