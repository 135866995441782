import { userExists } from '@frontastic-engbers/lib/actions/account';

export class EmailValidatorHelper {
  static ruleDefinitions = async (value: string, isRegistrationForm = false) => {
    const emailRegex = new RegExp(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9-]{2,}(?:\.[a-zA-Z]{2,})+$/, 'gm');
    const numbersOnlyRegex = new RegExp(/^(?!^[0-9._+-]+$)[a-zA-Z0-9._+-]+$/, 'gm');

    if (value === '') {
      return 'empty';
    }

    if (!emailRegex.test(value) || !numbersOnlyRegex.test(value.split('@')[0])) {
      return 'pattern';
    }

    if (isRegistrationForm && (await userExists(value))) {
      return 'used';
    }

    return null;
  };
}
