import React, { useEffect, useRef, useState } from 'react';
import { NewsletterData } from '@engbers/webservice/generated/service/definitions/NewsletterData';
import { IFTLinkReference, IFTPageFolderReference, RaffleData, RaffleFormData } from '@frontastic-engbers/types/engbers-custom';
import { Block } from '../block';
import { InputRadioGroup } from '../shopmacher-ui/input-radio-group';
import { useMediaQuery } from 'react-responsive';
import { sendRaffleRequest } from '@frontastic-engbers/lib/actions/service';
import styles from './raffle.module.scss';
import { InputText } from '../shopmacher-ui/input-text';
import ReCAPTCHA from 'react-google-recaptcha';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { CountrySelect } from '../country-select';
import { validatePostalCode } from '@frontastic-engbers/helpers/utils/validatePostalCode';
import { validateStreetName } from '@frontastic-engbers/helpers/utils/validateStreetName';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { InputDate } from '../shopmacher-ui/input-date';
import { EmailValidatorHelper } from '@frontastic-engbers/helpers/emailValidatorHelper';
import { InputCheckbox } from '../input-checkbox';
import { Button } from '../button';
import { Markdown } from '../markdown';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import { useRouter } from 'next/router';
import { getProjectInfo } from '@frontastic-engbers/lib';
import isoAlpha2Data from '@frontastic-engbers/helpers/utils/iso-alpha2-data';
interface IRaffleForm {
  raffleId: string;
  raffleName: string;
  newsletterOption: boolean;
  thankYouUrl: IFTLinkReference | IFTPageFolderReference;
  newsletter: NewsletterData[]; //@TODO für spätere Anpassung schonmal hinugefügt;
  reCaptchaKey: string;
  labels: {
    radioMister: string;
    radioMs: string;
    labelTitel: string;
    labelFirstName: string;
    labelLastName: string;
    labelStreetName: string;
    labelStreetNumber: string;
    labelAdditionalInfo: string;
    labelZip: string;
    labelCity: string;
    labelCountry: string;
    labelTelephone: string;
    labelMobilephone: string;
    labelBirthdate: string;
    labelEmail: string;
    labelRecaptcha: string;
    labelNewsletterOption?: string;
    labelRaffleOriginInfos: string;
    labelMobileBtnSubmit: string;
    labelDesktopBtnSubmit: string;
    labelTextTos: string;
  };
}
type StreetNamePostalCodeErrors = {
  streetName: boolean;
  postalCode: boolean;
};
type ValidationErrors = {
  salutation?: boolean;
  firstName: boolean;
  lastName: boolean;
  streetName: boolean;
  streetNumber: boolean;
  zipCode: boolean;
  city: boolean;
  country: boolean;
  birthdayDay: boolean;
  birthdayMonth: boolean;
  birthdayYear: boolean;
  email: boolean;
  acceptReCaptcha: boolean;
  acceptTos: boolean;
};
export const RaffleForm: React.FC<IRaffleForm> = ({
  raffleId,
  raffleName,
  newsletterOption,
  thankYouUrl,
  newsletter,
  reCaptchaKey,
  labels
}) => {
  const router = useRouter();
  const {
    pushNotification
  } = useToastNotificationsActions();
  const {
    formatMessage: formatAccountMessage
  } = useFormat({
    name: 'account'
  });
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const [errors, setErrors] = useState<Partial<ValidationErrors>>({});
  const [loading, setLoading] = useState(false);
  const [emailErrors, setEmailErrors] = useState<string | null>(null);
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const [initialSend, setInitialSend] = useState(false);
  const [streetNamePostalCodeErrors, setStreetNamePostalCodeErrors] = useState<Partial<StreetNamePostalCodeErrors>>({});
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  const recaptchaRef = useRef();
  const projectInfo = getProjectInfo();
  const isPwa = projectInfo['projectId'] === 'Pwa';
  const defaultCountry = Object.keys(isoAlpha2Data).find(key => isoAlpha2Data[key] === isoAlpha2Data.DE);
  const [formData, setFormData] = useState<RaffleFormData>({
    salutation: null,
    title: '',
    firstName: '',
    lastName: '',
    streetName: '',
    streetNumber: '',
    additionalInfo: '',
    city: '',
    zipCode: '',
    country: isPwa ? '' : defaultCountry,
    phoneNumber: '',
    mobileNumber: '',
    birthdayDay: '',
    birthdayMonth: '',
    birthdayYear: '',
    email: '',
    acceptTos: false,
    partnerConditions: false,
    wantsNewsletter: false
  });
  const mailMessage = formatErrorMessage({
    id: 'email.wrong',
    defaultMessage: 'E-Mail Adresse format ist invalid.'
  });
  const mailErrorMessage = formatAccountMessage({
    id: 'email.enter.again',
    defaultMessage: 'Please enter your e-mail address.'
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const validate = () => {
    const validationErrors: ValidationErrors = {
      salutation: formData.salutation !== null,
      firstName: formData.firstName !== '',
      lastName: formData.lastName !== '',
      zipCode: formData.zipCode !== '',
      city: formData.city !== '',
      email: formData.email !== '',
      acceptTos: formData.acceptTos,
      streetName: formData.streetName !== '',
      streetNumber: formData.streetNumber !== '',
      country: formData.country !== '',
      birthdayDay: formData.birthdayDay !== '',
      birthdayMonth: formData.birthdayMonth !== '',
      birthdayYear: formData.birthdayYear !== '',
      acceptReCaptcha: (recaptchaRef.current as any)?.getValue() !== ''
    };
    setErrors(validationErrors);
    return !Object.values(validationErrors).includes(false);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setInitialSend(true);
    setLoading(true);
    const validEmail = await validateEmail();
    if (!validEmail || !validate()) {
      setLoading(false);
      return;
    }
    const raffleData: RaffleData = {
      raffleId,
      formData
    };
    const response = await sendRaffleRequest(raffleData);
    if (response.code === 'SUCCESS' || response.code === 'MAIL DUPLICATED') {
      router.push(getReferenceTarget(thankYouUrl));
    } else {
      pushNotification(formatErrorMessage({
        id: 'wentWrong',
        defaultMessage: 'Es tut uns leid'
      }), 'error');
      setLoading(false);
    }
  };
  const validatePostalCodeField = (value: string, country: string = null) => {
    const isCountryGermany = country ? country.toLowerCase() === 'de' : formData.country.toLowerCase() === 'de';
    if (isCountryGermany) {
      setStreetNamePostalCodeErrors(state => ({
        ...state,
        postalCode: !validatePostalCode(value)
      }));
    }
    setStreetNamePostalCodeErrors(state => ({
      ...state,
      postalCode: isCountryGermany ? !validatePostalCode(value) : false
    }));
  };
  const validateEmail = async (): Promise<boolean> => {
    const ruleDefinitions: string | null = await EmailValidatorHelper.ruleDefinitions(formData.email);
    if (!ruleDefinitions) {
      setEmailErrors(null);
      setErrors(prevErrors => ({
        ...prevErrors,
        email: true
      }));
      return true;
    }
    setEmailErrors(ruleDefinitions);
    setErrors(prevErrors => ({
      ...prevErrors,
      email: false
    }));
    return false;
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.checked
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      acceptTos: e.target.checked
    }));
  };
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  if (isMinWidthMedium) {
    return <>
        <Markdown text={raffleName} className={styles.headline} />
        <form onSubmit={handleSubmit}>
          <Block marginBottom={8}>
            <Block marginBottom={4}>
              <InputRadioGroup onChange={id => {
              setFormData(prev => ({
                ...prev,
                salutation: id as any
              }));
              if (id) {
                setErrors({
                  ...errors,
                  salutation: true
                });
              }
            }} options={[{
              label: `${labels.radioMister ?? 'MR'}`,
              id: `${labels.radioMister ?? 'MR'}`
            }, {
              label: `${labels.radioMs ?? 'MRS'}`,
              id: `${labels.radioMs ?? 'MRS'}`
            }]} activeId={formData.salutation} errorMessage={errors.salutation === false && initialSend ? formatMessage({
              id: 'salutation'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : undefined} />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="title">
                {labels.labelTitel ?? formatMessage({
                id: 'firstName',
                defaultMessage: 'Forename'
              })}
              </label>
              <InputText style={'grey'} id="title" name="title" type="text" onChange={handleChange} placeholder={labels.labelTitel ?? formatMessage({
              id: 'title',
              defaultMessage: 'title'
            })} value={formData.title} />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="firstName">
                {labels.labelFirstName ?? formatMessage({
                id: 'firstName',
                defaultMessage: 'Forename'
              })}
              </label>
              <InputText style={'grey'} id="firstName" errorMessage={!errors.firstName && initialSend && formatErrorMessage({
              id: 'fillInput'
            })} name="firstName" type="text" onChange={handleChange} onBlur={() => setErrors(prevErrors => ({
              ...prevErrors,
              firstName: formData.firstName !== ''
            }))} placeholder={labels.labelFirstName ?? formatMessage({
              id: 'firstName',
              defaultMessage: 'Forename'
            })} required value={formData.firstName} />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="lastName">
                {labels.labelLastName ?? formatMessage({
                id: 'lastName',
                defaultMessage: 'Surname'
              })}
              </label>
              <InputText style={'grey'} errorMessage={!errors.lastName && initialSend && formatErrorMessage({
              id: 'fillInput'
            })} id="lastName" name="lastName" type="text" onChange={handleChange} onBlur={() => setErrors(prevErrors => ({
              ...prevErrors,
              lastName: formData.lastName !== ''
            }))} placeholder={labels.labelLastName ?? formatMessage({
              id: 'lastName',
              defaultMessage: 'Surname'
            })} required value={formData.lastName} />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="streetName">
                {labels.labelStreetName ?? formatMessage({
                id: 'street.name',
                defaultMessage: 'street.name'
              })}
              </label>
              <InputText style={'grey'} id="streetName" name="streetName" type="text" required value={formData.streetName} onChange={e => {
              handleChange(e);
              setStreetNamePostalCodeErrors(state => ({
                ...state,
                streetName: !validateStreetName(e.target.value)
              }));
            }} onBlur={e => {
              setErrors(prevErrors => ({
                ...prevErrors,
                zipCode: formData.streetName !== ''
              }));
              setStreetNamePostalCodeErrors(state => ({
                ...state,
                streetName: !validateStreetName(e.target.value)
              }));
            }} placeholder={labels.labelStreetName ?? formatMessage({
              id: 'street.name',
              defaultMessage: 'street.name'
            })} errorMessage={!errors.streetName && initialSend ? formatMessage({
              id: 'street.name'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : streetNamePostalCodeErrors.streetName ? formatErrorMessage({
              id: 'streetNameCharacters',
              defaultMessage: 'Straße darf keine Zahlen enthalten!'
            }) : undefined} />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="streetNumber">
                {labels.labelStreetNumber ?? formatMessage({
                id: 'street.number',
                defaultMessage: 'street.number'
              })}
              </label>
              <InputText style={'grey'} id="streetNumber" name="streetNumber" type="number" required value={formData.streetNumber} onChange={handleChange} onBlur={() => setErrors(prevErrors => ({
              ...prevErrors,
              city: formData.streetNumber !== ''
            }))} placeholder={labels.labelStreetNumber ?? formatMessage({
              id: 'street.number',
              defaultMessage: 'street.number'
            })} errorMessage={!errors.city && initialSend ? formatMessage({
              id: 'street.number'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : undefined} />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="additionalInfo">
                {labels.labelAdditionalInfo ?? formatMessage({
                id: 'additionalInfo',
                defaultMessage: 'additionalInfo'
              })}
              </label>
              <InputText style={'grey'} id="additionalInfo" name="additionalInfo" type="text" onChange={handleChange} placeholder={labels.labelAdditionalInfo ?? formatMessage({
              id: 'additionalInfo',
              defaultMessage: 'additionalInfo'
            })} value={formData.additionalInfo} />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="zipCode">
                {labels.labelZip ?? formatMessage({
                id: 'zipCode',
                defaultMessage: 'zipCode'
              })}
              </label>
              <InputText style={'grey'} id="zipCode" name="zipCode" type="number" autoComplete="zipCode" required value={formData.zipCode} onChange={handleChange} onBlur={() => setErrors(prevErrors => ({
              ...prevErrors,
              zipCode: formData.zipCode !== ''
            }))} placeholder={labels.labelZip ?? formatMessage({
              id: 'zipCode',
              defaultMessage: 'zipCode'
            })} errorMessage={!errors.zipCode && initialSend ? formatMessage({
              id: 'zipCode'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : undefined} />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="city">
                {labels.labelCity ?? formatMessage({
                id: 'place',
                defaultMessage: 'place'
              })}
              </label>
              <InputText style={'grey'} id="city" name="city" type="text" autoComplete="city" required value={formData.city} onChange={handleChange} onBlur={() => setErrors(prevErrors => ({
              ...prevErrors,
              city: formData.city !== ''
            }))} placeholder={labels.labelCity ?? formatMessage({
              id: 'place',
              defaultMessage: 'place'
            })} errorMessage={!errors.city && initialSend ? formatMessage({
              id: 'place'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : undefined} />
            </Block>
            <Block marginBottom={4}>
              <CountrySelect id="address_country" placeholder={labels.labelCountry} name="country" errorMessage={errors.country === false ? formatMessage({
              id: 'country'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : undefined} autoComplete="" required value={formData.country} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const isCountryIreland = e.target.value.toLowerCase() === 'ie';
              setFormData(prev => ({
                ...prev,
                country: e.target.value
              }));
              validatePostalCodeField(formData.zipCode, e.target.value);
              setErrors({
                ...errors,
                zipCode: isCountryIreland || formData.zipCode !== ''
              });
            }} wrapperCustomStyle={{
              width: '100%'
            }} />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="phone">
                {labels.labelTelephone ?? formatMessage({
                id: 'phone',
                defaultMessage: 'phone'
              })}
              </label>
              <InputText style="grey" id="phone" name="phoneNumber" type="number" onChange={handleChange} placeholder={labels.labelTelephone ?? formatMessage({
              id: 'phone',
              defaultMessage: 'phone'
            })} value={formData.phoneNumber} />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="mobilephone">
                {labels.labelMobilephone ?? formatMessage({
                id: 'phoneMobile',
                defaultMessage: 'phoneMobile'
              })}
              </label>
              <InputText style="grey" id="mobilephone" name="mobileNumber" type="number" onChange={handleChange} placeholder={labels.labelMobilephone ?? formatMessage({
              id: 'phoneMobile',
              defaultMessage: 'phoneMobile'
            })} value={formData.mobileNumber} />
            </Block>
            <Block marginBottom={4}>
              <InputDate label={labels.labelBirthdate} required disableValidatioOnBlur onChange={value => setFormData(prev => ({
              ...prev,
              ...value
            }))} isFormatValid={valid => setErrors({
              ...errors,
              birthdayDay: valid,
              birthdayMonth: valid,
              birthdayYear: valid
            })} stillHasError={errors && (errors.birthdayDay === false || errors.birthdayMonth === false || errors.birthdayYear === false)} birthdayDay={formData.birthdayDay} birthdayMonth={formData.birthdayMonth} birthdayYear={formData.birthdayYear} />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only" htmlFor="email">
                {labels.labelEmail ?? formatMessage({
                id: 'email',
                defaultMessage: 'Email'
              })}
              </label>
              <InputText style="grey" id="email" errorMessage={!emailErrors ? undefined : emailErrors === 'empty' ? mailErrorMessage : mailMessage} name="email" type="email" onChange={handleChange} onBlur={validateEmail} placeholder={labels.labelEmail ?? formatMessage({
              id: 'email',
              defaultMessage: 'Email'
            })} required value={formData.email} />
            </Block>
            <Block marginBottom={4}>
              <label className="sr-only">
                {labels.labelRecaptcha ?? formatMessage({
                id: 'reCaptchaSecurityCode',
                defaultMessage: 'accept ReCaptcha'
              })}
              </label>
              <ReCAPTCHA ref={recaptchaRef} sitekey={reCaptchaKey} onChange={v => setErrors(prevErrors => ({
              ...prevErrors,
              acceptReCaptcha: v !== ''
            }))} />
              <span className={styles.errorMsg}>
                {!errors.acceptReCaptcha && initialSend && formatErrorMessage({
                id: 'fillInput',
                defaultMessage: 'fillInput'
              })}
              </span>
            </Block>
            <Block marginBottom={4}>
              <InputCheckbox id="accept_tos" name="acceptTos" label={<span className={errors.acceptTos === false ? styles.errorMsgColor : ''}>
                    <Markdown linkToNewTab text={labels.labelTextTos} className={styles.markdownGuidelines} />
                  </span>} onChange={handleCheckboxChange} errorMessage={!errors.acceptTos && initialSend ? formatErrorMessage({
              id: 'acceptConditionsOfParticipation'
            }) : undefined} hasHoverEffect />
            </Block>
            {newsletterOption && <Block marginBottom={4}>
                <InputCheckbox id="newsletterAcceptions" name="wantsNewsletter" label={labels.labelNewsletterOption} onChange={handleCheckboxChange} />
              </Block>}
            <Block marginBottom={4}>
              <InputCheckbox id="partnerConditions" name="partnerConditions" label={labels.labelRaffleOriginInfos} onChange={handleCheckboxChange} />
            </Block>
            <Button onClick={handleSubmit} isLoading={loading} size="large" label={labels.labelMobileBtnSubmit ?? formatMessage({
            id: 'submit',
            defaultMessage: 'Submit'
          })} />
          </Block>
        </form>
      </>;
  }
  return <>
      <Markdown text={raffleName} className={styles.headline} data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
      <form onSubmit={handleSubmit}>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="salutation">
            {formatMessage({
            id: 'salutation',
            defaultMessage: 'salutation'
          }) + ' *'}
          </label>
          <InputRadioGroup onChange={id => {
          setFormData(prev => ({
            ...prev,
            salutation: id as any
          }));
          if (id) {
            setErrors({
              ...errors,
              salutation: true
            });
          }
        }} options={[{
          label: `${labels.radioMister ?? 'MR'}`,
          id: `${labels.radioMister ?? 'MR'}`
        }, {
          label: `${labels.radioMs ?? 'MS'}`,
          id: `${labels.radioMs ?? 'MS'}`
        }]} activeId={formData.salutation} errorMessage={errors.salutation === false && initialSend ? formatMessage({
          id: 'salutation'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} data-sentry-element="InputRadioGroup" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="title">
            {labels.labelTitel ? `${labels.labelTitel}` : formatMessage({
            id: 'title',
            defaultMessage: 'title'
          })}
          </label>
          <InputText style="white" name="title" type="text" value={formData.title} onChange={handleChange} wrapperCustomStyle={{
          width: '75%'
        }} inputCustomStyle={{
          height: '28px',
          padding: '0 8px'
        }} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="firstName">
            {labels.labelFirstName ? `${labels.labelFirstName} *` : formatMessage({
            id: 'firstName',
            defaultMessage: 'Forename'
          })}
          </label>
          <InputText style="white" name="firstName" type="text" autoComplete="given-name" required value={formData.firstName} onChange={handleChange} onBlur={() => setErrors(prevErrors => ({
          ...prevErrors,
          firstName: formData.firstName !== ''
        }))} errorMessage={!errors.firstName && initialSend ? formatMessage({
          id: 'firstName'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} wrapperCustomStyle={{
          width: '75%'
        }} inputCustomStyle={{
          height: '28px',
          padding: '0 8px'
        }} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="lastName">
            {labels.labelLastName ? `${labels.labelLastName} *` : formatMessage({
            id: 'lastName',
            defaultMessage: 'Surname'
          })}
          </label>
          <InputText style="white" id="last_name" name="lastName" type="text" autoComplete="family-name" required value={formData.lastName} onChange={handleChange} onBlur={() => setErrors(prevErrors => ({
          ...prevErrors,
          lastName: formData.lastName !== ''
        }))} errorMessage={!errors.lastName && initialSend ? formatMessage({
          id: 'lastName'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} wrapperCustomStyle={{
          width: '75%'
        }} inputCustomStyle={{
          height: '28px',
          padding: '0 8px'
        }} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="street_nr">
            {labels.labelStreetName && labels.labelStreetNumber ? `${labels.labelStreetName}, ${labels.labelStreetNumber} *` : `${formatMessage({
            id: 'street.name',
            defaultMessage: 'street.name'
          })}, ${formatMessage({
            id: 'street.number',
            defaultMessage: 'street.number'
          })}`}
          </label>
          <div className="inline-flex w-3/4">
            <Block maxWidth="80%" customStyle={{
            width: '100%'
          }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
              <InputText style="white" id="streetName" name="streetName" type="text" required value={formData.streetName} onChange={handleChange} onBlur={() => setErrors(prevErrors => ({
              ...prevErrors,
              streetName: formData.streetName !== ''
            }))} errorMessage={!errors.streetName && initialSend ? formatMessage({
              id: 'street.name'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : undefined} wrapperCustomStyle={{
              paddingRight: '15px'
            }} inputCustomStyle={{
              height: '28px',
              padding: '0 8px'
            }} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
            </Block>
            <Block maxWidth="20%" customStyle={{
            width: '100%'
          }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
              <InputText style="white" id="streetNumber" name="streetNumber" type="number" required value={formData.streetNumber} onChange={handleChange} onBlur={() => setErrors(prevErrors => ({
              ...prevErrors,
              streetNumber: formData.streetNumber !== ''
            }))} errorMessage={!errors.streetNumber && initialSend ? formatMessage({
              id: 'street.number'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : undefined} wrapperCustomStyle={{
              paddingLeft: '15px'
            }} inputCustomStyle={{
              height: '28px',
              padding: '0 8px'
            }} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
            </Block>
          </div>
        </Block>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="additionalInfo">
            {labels.labelAdditionalInfo ? `${labels.labelAdditionalInfo}` : formatMessage({
            id: 'additionalInfo',
            defaultMessage: 'additionalInfo'
          })}
          </label>
          <InputText style="white" id="additionalInfo" name="additionalInfo" type="text" required value={formData.additionalInfo} onChange={handleChange} wrapperCustomStyle={{
          width: '75%'
        }} inputCustomStyle={{
          height: '28px',
          padding: '0 8px'
        }} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="zipCode">
            {labels.labelZip && labels.labelCity ? `${labels.labelZip}, ${labels.labelCity} *` : `${formatMessage({
            id: 'zipCodeShort',
            defaultMessage: 'zipcode'
          })}, ${formatMessage({
            id: 'place',
            defaultMessage: 'place'
          })}`}
          </label>
          <div className="inline-flex w-3/4">
            <Block maxWidth="30%" customStyle={{
            width: '100%'
          }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
              <InputText style="white" id="zipCode" name="zipCode" type="number" required value={formData.zipCode} onChange={handleChange} onBlur={() => setErrors(prevErrors => ({
              ...prevErrors,
              zipCode: formData.zipCode !== ''
            }))} errorMessage={!errors.zipCode && initialSend ? formatMessage({
              id: 'zipCode'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : undefined} wrapperCustomStyle={{
              paddingRight: '15px'
            }} inputCustomStyle={{
              height: '28px',
              padding: '0 8px'
            }} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
            </Block>
            <Block maxWidth="70%" customStyle={{
            width: '100%'
          }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
              <InputText style="white" id="city" name="city" type="text" required value={formData.city} onChange={handleChange} onBlur={() => setErrors(prevErrors => ({
              ...prevErrors,
              city: formData.city !== ''
            }))} errorMessage={!errors.city && initialSend ? formatMessage({
              id: 'place'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : undefined} wrapperCustomStyle={{
              paddingLeft: '15px'
            }} inputCustomStyle={{
              height: '28px',
              padding: '0 8px'
            }} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
            </Block>
          </div>
        </Block>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="country">
            {labels.labelCountry ?? formatMessage({
            id: 'country',
            defaultMessage: 'country'
          })}
          </label>
          <CountrySelect id="country" name="country" errorMessage={errors.country === false ? formatMessage({
          id: 'country'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} autoComplete="" required value={formData.country} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const isCountryIreland = e.target.value.toLowerCase() === 'ie';
          setFormData(prev => ({
            ...prev,
            country: e.target.value
          }));
          validatePostalCodeField(formData.zipCode, e.target.value);
          setErrors({
            ...errors,
            zipCode: isCountryIreland || formData.zipCode !== ''
          });
        }} wrapperCustomStyle={{
          width: '75%'
        }} data-sentry-element="CountrySelect" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="phone">
            {labels.labelTelephone ?? formatMessage({
            id: 'phone',
            defaultMessage: 'enterPhoneNumber'
          })}
          </label>
          <InputText style="white" id="phone" name="phoneNumber" type="number" autoComplete="phone" onChange={handleChange} value={formData.phoneNumber} wrapperCustomStyle={{
          width: '75%'
        }} inputCustomStyle={{
          height: '28px',
          padding: '0 8px'
        }} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="mobileNumber">
            {labels.labelMobilephone ?? formatMessage({
            id: 'phoneMobile',
            defaultMessage: 'phoneMobile'
          })}
          </label>
          <InputText style="white" id="mobileNumber" name="mobileNumber" type="number" onChange={handleChange} value={formData.mobileNumber} wrapperCustomStyle={{
          width: '75%'
        }} inputCustomStyle={{
          height: '28px',
          padding: '0 8px'
        }} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="birthdate">
            {labels.labelBirthdate ? `${labels.labelBirthdate} *` : formatMessage({
            id: 'birthday',
            defaultMessage: 'birthday'
          })}
          </label>
          <div className={styles.birthdayWidth}>
            <InputDate required disableValidatioOnBlur onChange={value => setFormData(prev => ({
            ...prev,
            ...value
          }))} isFormatValid={valid => setErrors({
            ...errors,
            birthdayDay: valid,
            birthdayMonth: valid,
            birthdayYear: valid
          })} stillHasError={errors && (errors.birthdayDay === false || errors.birthdayMonth === false || errors.birthdayYear === false)} birthdayDay={formData.birthdayDay} birthdayMonth={formData.birthdayMonth} birthdayYear={formData.birthdayYear} data-sentry-element="InputDate" data-sentry-source-file="index.tsx" />
          </div>
        </Block>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="email">
            {labels.labelEmail ? `${labels.labelEmail} *` : formatMessage({
            id: 'email',
            defaultMessage: 'enterMail'
          })}
          </label>
          <InputText style="white" id="email" name="email" type="email" autoComplete="email" required errorMessage={!emailErrors ? undefined : emailErrors === 'empty' ? mailErrorMessage : mailMessage} onBlur={validateEmail} onChange={handleChange} value={formData.email} wrapperCustomStyle={{
          width: '75%'
        }} inputCustomStyle={{
          height: '28px',
          padding: '0 8px'
        }} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm">
            {labels.labelRecaptcha ? `${labels.labelRecaptcha} *` : formatMessage({
            id: 'fieldIsRequired',
            defaultMessage: 'acceptTos'
          })}
          </label>
          <span className="w-3/4">
            <ReCAPTCHA ref={recaptchaRef} sitekey={reCaptchaKey} onChange={v => setErrors(prevErrors => ({
            ...prevErrors,
            acceptReCaptcha: v !== ''
          }))} data-sentry-element="ReCAPTCHA" data-sentry-source-file="index.tsx" />
            <span className={styles.errorMsg}>
              {!errors.acceptReCaptcha && initialSend && formatErrorMessage({
              id: 'fillCaptcha',
              defaultMessage: 'fillCaptcha'
            })}
            </span>
          </span>
        </Block>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <span className="w-1/4"></span>
          <span className="w-3/4">
            <InputCheckbox id="accept_tos" name="acceptTos" label={<span className={styles.acceptTosWrap}>
                  <span className={errors.acceptTos === false ? styles.errorMsgColor : ''}>
                    <Markdown linkToNewTab text={labels.labelTextTos} className={styles.markdownGuidelines} />
                  </span>
                </span>} onChange={handleCheckboxChange} errorMessage={!errors.acceptTos && initialSend && formatErrorMessage({
            id: 'acceptConditionsOfParticipation'
          })} hasHoverEffect data-sentry-element="InputCheckbox" data-sentry-source-file="index.tsx" />
          </span>
        </Block>
        {newsletterOption && <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }}>
            <span className="w-1/4"></span>
            <span className="w-3/4">
              <InputCheckbox id="newsletterAcceptions" name="wantsNewsletter" label={<Markdown text={labels.labelNewsletterOption} className={styles.markdownGuidelines} />} onChange={handleCheckboxChange} />
            </span>
          </Block>}
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <span className="w-1/4"></span>
          <span className="w-3/4">
            <InputCheckbox id="partnerConditions" name="partnerConditions" label={<Markdown text={labels.labelRaffleOriginInfos} className={styles.markdownGuidelines} />} onChange={handleCheckboxChange} data-sentry-element="InputCheckbox" data-sentry-source-file="index.tsx" />
          </span>
        </Block>
        <Block marginBottom={4} customStyle={{
        width: '100%',
        display: 'inline-flex'
      }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm">{formatMessage({
            id: 'mandatoryFields'
          })}</label>
          <Button size="large" label={labels.labelDesktopBtnSubmit} isLoading={loading} customStyle={{
          padding: '14px 30px',
          width: 'fit-content',
          fontSize: '17.5px'
        }} onClick={handleSubmit} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
        </Block>
      </form>
    </>;
};