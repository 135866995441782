import { memo } from 'react';
import { useConfigure } from 'react-instantsearch';
import { PlainSearchParameters } from 'algoliasearch-helper';
type Props = {
  params: PlainSearchParameters;
};
export const sentryBlacklist = ['data-sentry-source-file', 'data-sentry-component'];

// Sentry adds data attributes to all components if reactComponentAnnotation is enabled resulting in an algolia
// "unknown parameter" - this is a known bug (sentry), as workaround we simply use our own customized algolia
// configuration removing all relevant sentry data attributes from the components props ...
export const CustomConfigure: React.FC<Props> = memo(function CustomConfigure({
  params
}) {
  const searchParams = {
    ...params
  };
  for (const blacklisted of sentryBlacklist) {
    if (searchParams.hasOwnProperty(blacklisted)) {
      delete searchParams[blacklisted];
    }
  }
  useConfigure(searchParams);
  return null;
});