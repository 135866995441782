import React from 'react';
import { motion } from 'framer-motion';
import classnames from 'classnames';
import { IconCustom, Link } from '@engbers/components';
import Price from '@engbers/components/online-shops/price';
import { Product } from '@frontastic-engbers/types/product/Product';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import styles from './suggested-product.module.scss';
export interface ISuggestedProduct {
  product: Product;
  query: string;
  fallbackImage?: MediaType;
}
export const SuggestedProduct: React.FC<ISuggestedProduct> = ({
  product,
  query,
  fallbackImage
}) => {
  const highlightTerm = (text: string) => {
    return text.replace(new RegExp('(' + query + ')', 'gi'), '<span class="text-xs-bold">$1</span>');
  };
  const mainVariant = product.variants[0];
  if (mainVariant === undefined) {
    return null;
  }
  return <motion.div style={{
    overflow: 'hidden'
  }} initial={{
    height: 'auto'
  }} animate={{
    height: 'auto'
  }} exit={{
    height: 0
  }} transition={{
    duration: 0.2
  }} data-sentry-element="unknown" data-sentry-component="SuggestedProduct" data-sentry-source-file="index.tsx">
      <Link onClick={() => {
      (document.activeElement as HTMLElement)?.blur();
    }} href={product.url} className={classnames(styles.link, styles.productBox)} data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <div className={styles.image}>
          <img src={mainVariant.images[0] || fallbackImage?.media?.file} alt={product.name} title={product.name} />
        </div>
        <div className={styles.details}>
          <span dangerouslySetInnerHTML={{
          __html: highlightTerm(product.name)
        }} />
          {product.attributes?.DesignerName && <span className="text-gray-400">{product.attributes.DesignerName}</span>}
        </div>
        <Price className={styles.price} price={mainVariant.price} discountedPrice={mainVariant.discountedPrice} isBold={true} showCurrencySymbol={true} data-sentry-element="Price" data-sentry-source-file="index.tsx" />
        <IconCustom icon="ChevronRightIcon" width={18} className={styles.chevron} data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
      </Link>
    </motion.div>;
};