import { RefObject, useEffect } from 'react';

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: () => void,
  specificHandler?: (e: MouseEvent | TouchEvent) => void,
) {
  useEffect(() => {
    const handleMouseDown = (event: MouseEvent | TouchEvent) => {
      if (typeof specificHandler === 'function') {
        specificHandler(event);
        return;
      }

      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler();
      }
    };

    function handleKeyUp(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        handler();
      }
    }

    document.addEventListener('mouseup', handleMouseDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
