import React from 'react';
const StyleGuideColors: React.FC = () => <div className="dark:bg-encom-900 mb-10 border border-solid border-slate-300 dark:text-white" data-sentry-component="StyleGuideColors" data-sentry-source-file="style-guide-colors.tsx">
    <h2 className="mx-6 my-4 dark:text-white">Colors</h2>
    <div className="my-3 flex flex-wrap justify-start border-t border-solid border-slate-300 p-6 align-middle">
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-primary h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--primary | #0f202f (EN) | #6a6a6a (EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-primaryLight h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--primaryLight | #3c4a56 (EN) | #8a8a8a (EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-gray50 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--gray50 | #f0f2f2 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-gray100 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--gray100 | #e1e4e5 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-gray200 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--gray200 | #c3c8cb (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-gray300 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--gray300 | #a5acb1 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-gray400 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--gray400 | #879097 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-gray500 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--gray500 | #69747d (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-gray600 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--gray600 | #818181 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-lightGrey h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--lightGrey | #E2E7EB (EN) | #dadada (EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-darkGrey h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--darkGrey | #4b4b4b (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-lightBlue h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--lightBlue | #ebf3fa (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-darkBlue h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--darkBlue | #0f202f (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-orange h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--orange | #ec7724 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-lightRed h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--lightRed | #f3cfc6 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-greenNeon h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--greenNeon | #cfd724 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="h-[60px] w-[100%] rounded border border-solid border-slate-300 bg-white"></div>
        <span className="pt-3 text-xs">--white | #FFFFFF (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="h-[60px] w-[100%] rounded bg-black"></div>
        <span className="pt-3 text-xs">--black | #000000 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-success h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--success | #127c12 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-info h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--info | #009bcc (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-warning h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--warning | #fbbf24 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-danger h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--danger | #b70808 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-btnIconHover h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--btnIconHover | #699cc9 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-fontColor h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--fontColor | #0f202f (EN) | #3c3c3b (EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-fontColorGray h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--fontColorGray | #6a6a6a (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-footerFontColor h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--footerFontColor | #0f202f (EN) | #9D9D9C (EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-linkHover h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--linkHover | #23527c (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-discountedPrice h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--discountedPrice | #e30613 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-toTopBgColor h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--toTopBgColor | #818181 (EN) | #6a6a6a (EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-flags-sale h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--flags-sale | #e30613 (EN/EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-flags-new h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--flags-new | #6a6a6a (EN) | #3c3c3b (EA)</span>
      </div>
      <div className="m-3 flex w-24 flex-col items-center">
        <div className="bg-inputBackground h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--inputBackground | #f4f4f4 (EN/EA)</span>
      </div>
    </div>

    <div>
      <h4 className="m-6 dark:text-white">Usage:</h4>
      <ol className="mx-10 mb-6 list-inside list-decimal">
        <li className="pb-2">
          In SCSS:
          <code className="ml-3 mt-1 block bg-gray-100 p-1">{'color: theme("colors.primary");'}</code>
        </li>
        <li className="pb-2">
          Using class names.
          <div className="ml-3 mt-1">
            For background:
            <code className="ml-3 mt-1 block bg-gray-100 p-1">{'<div class="bg-primary">Content</div>'}</code>
          </div>
          <div className="ml-3 mt-1">
            For font color:
            <code className="ml-3 mt-1 block bg-gray-100 p-1">{'<div class="text-primary">Content</div>'}</code>
          </div>
        </li>
      </ol>
    </div>
  </div>;
export default StyleGuideColors;