import React, { useEffect } from 'react';
import { useCurrentShopMapped, useStructuredBreadcrumb } from '@frontastic-engbers/lib';
import { TBreadcrumb } from '@frontastic-engbers/types/engbers-custom';
import Link from 'next/link';
import styles from './breadcrumb.module.scss';
import classNames from 'classnames';
interface IBreadcrumb {
  breadCrumbs: TBreadcrumb[];
  paddingBottomInPix?: number;
  isLandingPage?: boolean;
}
export const Breadcrumb: React.FC<IBreadcrumb> = ({
  breadCrumbs,
  paddingBottomInPix = null,
  isLandingPage = false
}) => {
  const currentShop = useCurrentShopMapped();
  const [, setStructuredBreadcrumb] = useStructuredBreadcrumb();
  useEffect(() => {
    setStructuredBreadcrumb(breadCrumbs);
    return () => {
      setStructuredBreadcrumb([]);
    };
  }, [setStructuredBreadcrumb, breadCrumbs]);
  if (isLandingPage) {
    return <ul style={{
      paddingBottom: `${paddingBottomInPix}px`
    }}>
        <li className={styles.listElement}>
          <Link key={currentShop} href="/">
            <a>
              <span>{currentShop}</span>
            </a>
          </Link>
        </li>
        {breadCrumbs.map(breadcrumb => !breadcrumb.active ? <li className={styles.listElement} key={breadcrumb.name}>
              <Link key={breadcrumb.name} href={breadcrumb.link}>
                <a>
                  <span>{breadcrumb.name}</span>
                </a>
              </Link>
            </li> : <li className={classNames(styles.active, styles.listElement)} key={breadcrumb.name}>
              <span>{breadcrumb.name}</span>
            </li>)}
      </ul>;
  }
  return <ul style={{
    paddingBottom: `${paddingBottomInPix}px`
  }} data-sentry-component="Breadcrumb" data-sentry-source-file="index.tsx">
      {breadCrumbs.map(breadcrumb => !breadcrumb.active ? <li className={styles.listElement} key={breadcrumb.name}>
            <Link key={breadcrumb.name} href={breadcrumb.link}>
              <a>
                <span>{breadcrumb.name === 'Default' ? currentShop : breadcrumb.name}</span>
              </a>
            </Link>
          </li> : <li className={classNames(styles.active, styles.listElement)} key={breadcrumb.name}>
            <span>{breadcrumb.name}</span>
          </li>)}
    </ul>;
};