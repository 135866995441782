import classNames from 'classnames';
import { ArrowRightIcon } from './assets/arrow-right';
import { ArrowBackCurvedIcon } from './assets/arrow-back-curved';
import { ChevronRightIcon } from './assets/chevron-right';
import { ChevronLeftIcon } from './assets/chevron-left';
import { ChevronUpIcon } from './assets/chevron-up';
import { ChevronDownIcon } from './assets/chevron-down';
import { InboxIcon } from './assets/inbox';
import { InboxClosedIcon } from './assets/inbox-closed';
import { InfoIcon } from './assets/info';
import { LogoIcon } from './assets/logo';
import { MapMarkerIcon } from './assets/map-marker';
import { MapMarkerFilledIcon } from './assets/map-marker-filled';
import { MapMarkerThinIcon } from './assets/map-marker-thin';
import { MapMarkerFatIcon } from './assets/map-marker-fat';
import { MapMarkerPwaIcon } from './assets/map-marker-pwa';
import { CartIcon } from './assets/cart';
import { CartAddIcon } from './assets/cart-add';
import { CartFilledIcon } from './assets/cart-filled';
import { UserCrownIcon } from './assets/user-crown';
import { UserIcon } from './assets/user';
import { UserFilledIcon } from './assets/user-filled';
import { UserAddIcon } from './assets/user-add';
import { ArrowThinLeftIcon } from './assets/arrow-thin-left';
import { CardIcon } from './assets/card';
import { CardsIcon } from './assets/cards';
import { CardsFilledIcon } from './assets/cards-filled';
import { CardsOpenIcon } from './assets/cards-open';
import { ClockIcon } from './assets/clock';
import { SearchIcon } from './assets/search';
import { HeartIcon } from './assets/heart';
import { HeartFilledIcon } from './assets/heart-filled';
import { TrashBasketIcon } from './assets/trash-basket';
import { PinchMagnifierIcon } from './assets/pinch-magnifier';
import { PinchHandIcon } from './assets/pinch-hand';
import { PinchCloseIcon } from './assets/pinch-close';
import { CloseIcon } from './assets/close';
import { BookIcon } from './assets/book';
import { CalendarIcon } from './assets/calendar';
import { CashIcon } from './assets/cash';
import { CheckMarkIcon } from './assets/check-mark';
import { CheckMarkThinIcon } from './assets/check-mark-thin';
import { CompanyIcon } from './assets/company';
import { CompanyFilledIcon } from './assets/company-filled';
import { EditIcon } from './assets/edit';
import { EyeOpenIcon } from './assets/eye-open';
import { FilterIcon } from './assets/filter';
import { HeadphoneIcon } from './assets/headphone';
import { HomeIcon } from './assets/home';
import { LockIcon } from './assets/lock';
import { LockOpenIcon } from './assets/lock-open';
import { MenuIcon } from './assets/menu';
import { NewsletterIcon } from './assets/newsletter';
import { PhoneIcon } from './assets/phone';
import { PrintIcon } from './assets/print';
import { ShirtIcon } from './assets/shirt';
import { StoreIcon } from './assets/store';
import { TrustIcon } from './assets/trust';
import { ShoppingBagIcon } from './assets/shopping-bag';
import { SizeInfoIcon } from './assets/sizeinfo';
import { EngbersGermanyLogoFlagIcon } from './assets/engbers-germany-logo-flag';
import styles from './icon-custom.module.scss';
export const ICON = {
  Inbox: InboxIcon,
  InboxClosed: InboxClosedIcon,
  Logo: LogoIcon,
  MapMarker: MapMarkerIcon,
  MapMarkerFilled: MapMarkerFilledIcon,
  MapMarkerThin: MapMarkerThinIcon,
  MapMarkerFat: MapMarkerFatIcon,
  MapMarkerPwa: MapMarkerPwaIcon,
  Cart: CartIcon,
  CartAdd: CartAddIcon,
  CartFilled: CartFilledIcon,
  User: UserIcon,
  UserFilled: UserFilledIcon,
  UserAdd: UserAddIcon,
  UserCrown: UserCrownIcon,
  ChevronRightIcon: ChevronRightIcon,
  ChevronLeftIcon: ChevronLeftIcon,
  ChevronUpIcon: ChevronUpIcon,
  ChevronDownIcon: ChevronDownIcon,
  ArrowRight: ArrowRightIcon,
  ArrowBackCurved: ArrowBackCurvedIcon,
  ArrowThinLeft: ArrowThinLeftIcon,
  Info: InfoIcon,
  Card: CardIcon,
  Cards: CardsIcon,
  CardsFilled: CardsFilledIcon,
  CardsOpen: CardsOpenIcon,
  Clock: ClockIcon,
  Search: SearchIcon,
  Heart: HeartIcon,
  HeartFilled: HeartFilledIcon,
  TrashBasket: TrashBasketIcon,
  PinchMagnifier: PinchMagnifierIcon,
  PinchHand: PinchHandIcon,
  PinchClose: PinchCloseIcon,
  Close: CloseIcon,
  Book: BookIcon,
  Calendar: CalendarIcon,
  Cash: CashIcon,
  CheckMark: CheckMarkIcon,
  CheckMarkThin: CheckMarkThinIcon,
  Company: CompanyIcon,
  CompanyFilled: CompanyFilledIcon,
  Edit: EditIcon,
  EyeOpen: EyeOpenIcon,
  Filter: FilterIcon,
  Headphone: HeadphoneIcon,
  Home: HomeIcon,
  Lock: LockIcon,
  LockOpen: LockOpenIcon,
  Menu: MenuIcon,
  Newsletter: NewsletterIcon,
  Phone: PhoneIcon,
  Print: PrintIcon,
  Shirt: ShirtIcon,
  Store: StoreIcon,
  Trust: TrustIcon,
  ShoppingBag: ShoppingBagIcon,
  SizeInfo: SizeInfoIcon,
  EngbersGermanyLogoFlag: EngbersGermanyLogoFlagIcon
};
export type TIconId = keyof typeof ICON;
export interface IIconCustom {
  icon: TIconId;
  color?: 'white' | 'primary' | 'darkBlue' | 'green-neon' | 'custom' | 'green' | 'danger';
  width?: number;
  className?: string;
  customColor?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
export const IconCustom: React.FC<IIconCustom> = ({
  icon,
  color,
  width = 32,
  className,
  customColor,
  onClick
}) => {
  const CurrentIcon = ICON[icon];
  return <div className={classNames(styles.iconWrap, {
    [styles[`iconWrap--color-${color}`]]: color
  }, className)} style={{
    width: width,
    color: customColor
  }} onClick={onClick} data-sentry-component="IconCustom" data-sentry-source-file="index.tsx">
      <CurrentIcon data-sentry-element="CurrentIcon" data-sentry-source-file="index.tsx" />
    </div>;
};