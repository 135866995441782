import React from 'react';
import { AutocompletePlugin, getAlgoliaResults } from '@algolia/autocomplete-js';
import { SearchResponse } from '@algolia/client-search';
import { SearchClient } from 'algoliasearch/dist/algoliasearch-lite';
import { ProductMapper } from '@frontastic-engbers/lib/lib/mappers/productMapper';
import { Product } from '@frontastic-engbers/types/product/Product';
import { HitType } from '@frontastic-engbers/types/product/Hit';
import { SuggestedProduct } from '../suggested-product';
export const productsPlugin = (searchClient: SearchClient, algoliaConfig: any, suggestedProductsLimit: number, onClick: () => void) => {
  return {
    getSources() {
      return [{
        sourceId: 'products',
        getItems({
          query,
          setContext,
          state
        }) {
          if (query.length < 3) {
            return [];
          }
          return getAlgoliaResults({
            searchClient,
            queries: [{
              indexName: algoliaConfig.dataSource.indexName,
              query,
              params: {
                attributesToRetrieve: ['attributes', 'name', 'slug', 'variants', 'displayPrice'],
                filters: 'productType: "General Product"',
                hitsPerPage: state.context.showAllProducts ? suggestedProductsLimit : 3
              }
            }],
            transformResponse({
              hits,
              results
            }) {
              setContext({
                nbProducts: (results[0] as SearchResponse).nbHits
              });
              return hits;
            }
          });
        },
        onSelect() {
          onClick();
        },
        templates: {
          item({
            item,
            state
          }) {
            const product: Product = ProductMapper.algoliaHitToProduct(item);
            return <SuggestedProduct key={`suggested-product-${item.objectID}`} product={product} query={state.query} fallbackImage={algoliaConfig.dataSource.fallBackImage} />;
          }
        }
      }];
    }
  } as AutocompletePlugin<HitType, {}>;
};