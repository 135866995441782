import React from 'react';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { Reference } from '@frontastic-engbers/helpers/reference';
import { ImageExtended } from '@engbers/components/image/image-extended';
import styles from './multi-section-container.module.scss';
export interface Props {
  sectionDivision: '100' | '50;50' | '33;66' | '66;33' | '16;66;16' | '33;33;33';
  spaceBetweenImg: number;
  sections: {
    image?: MediaType;
    link?: Reference;
    alt?: string;
    allElementsRedirect?: boolean;
    containerWidth?: number;
    containerBackgroundColor?: string;
    verticalAlignment?: 'flex-start' | 'center' | 'flex-end';
    horizontalAlignment?: 'left' | 'center' | 'right';
    imgPadding?: number;
    containerPadding?: number;
    btnActive: boolean;
    buttonText?: string;
    buttonIcon?: MediaType;
    buttonIconWidth?: number;
    buttonWidth?: number;
    buttonTextColor?: string;
    buttonBackgroundColor?: string;
    buttonAlignment?: 'flex-start' | 'center' | 'flex-end';
    textActive: boolean;
    text?: string;
    textColor?: string;
    textAlignment?: 'left' | 'center' | 'right';
  }[];
}
export const MultiSectionContainer: React.FC<Props> = ({
  sectionDivision,
  spaceBetweenImg,
  sections
}) => {
  const widthValues = (values: string) => values.split(';').map(Number);
  return <div className="flex w-full items-center justify-center" data-sentry-component="MultiSectionContainer" data-sentry-source-file="index.tsx">
      {sections.map((section, index) => <div className={styles.imgSectionWrap} style={{
      width: `${widthValues(sectionDivision)[index]}%`,
      margin: `${sections.length === 2 ? index === 0 ? `0 ${spaceBetweenImg}px 0 0` : '0' : sections.length === 3 && index === 1 ? `0 ${spaceBetweenImg}px 0 ${spaceBetweenImg}px` : '0'}`
    }} key={`section-division-${index}`}>
          <ImageExtended media={section?.image?.media} link={section?.link} alt={section?.image?.media?.name} allElementsRedirect={section?.allElementsRedirect} containerWidth={section?.containerWidth} containerBackgroundColor={section?.containerBackgroundColor} verticalAlignment={section?.verticalAlignment} horizontalAlignment={section?.horizontalAlignment} imgPadding={section?.imgPadding} containerPadding={section?.containerPadding} button={{
        btnActive: section?.btnActive,
        buttonText: section?.buttonText,
        buttonIcon: section?.buttonIcon,
        buttonIconWidth: section?.buttonIconWidth,
        buttonWidth: section?.buttonWidth,
        buttonTextColor: section?.buttonTextColor,
        buttonBackgroundColor: section?.buttonBackgroundColor,
        buttonAlignment: section?.buttonAlignment
      }} text={{
        textActive: section?.textActive,
        markdownText: section?.text,
        textColor: section?.textColor,
        textAlignment: section?.textAlignment
      }} />
        </div>)}
    </div>;
};