import React, { useEffect } from 'react';
type Props = {
  src: string;
  async?: boolean;
  defer?: boolean;
  refCallback?: (script: HTMLScriptElement) => void;
  onLoad?: () => void;
};
const createScript = ({
  src,
  async = true,
  defer = true,
  onLoad
}) => {
  const script = document.createElement('script');
  script.src = `https://widgets.trustedshops.com/${src}`;
  script.async = async;
  script.defer = defer;
  script.onload = onLoad;
  return script;
};
export const TrustedShopsScript: React.FC<Props> = ({
  src,
  async,
  defer,
  refCallback,
  onLoad
}) => {
  useEffect(() => {
    const script = createScript({
      src,
      async,
      defer,
      onLoad
    });
    refCallback?.(script);
    document.body.appendChild(script);
    return () => {
      script?.remove();
    };
  }, [src]);
  return null;
};