import React, { useEffect, useState } from 'react';
export const CustomMarkdown = ({
  text
}) => {
  const [formatedText, setFormatedText] = useState<React.ReactNode>(null);
  const parseText = async () => {
    const Unified = await import('@frontastic-engbers/lib/lib/utils/markdown').then(module => module.default);
    return Unified.parse(text);
  };
  useEffect(() => {
    let ignore = false;
    parseText().then(res => {
      if (!ignore) {
        setFormatedText(res);
      }
    });
    return () => {
      ignore = true;
    };
  }, []);
  if (!formatedText) {
    return <></>;
  }
  return <>{formatedText}</>;
};