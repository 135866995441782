import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { Address } from '@frontastic-engbers/types/account/Address';
import { Order } from '@frontastic-engbers/types/cart/Order';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import isoAlpha2Data from '@frontastic-engbers/helpers/utils/iso-alpha2-data';
import { mapCosts } from '@frontastic-engbers/helpers/utils/mapCosts';
import Price from '../../price';
import { Image, Markdown } from '@engbers/components';
import styles from './order-confirmation.module.scss';
interface IOrderConfirmationModal {
  order: Order;
  deliveryTimeDE: string;
  deliveryTimeEU: string;
  orderModalImage: MediaType;
  orderModalEmailText: string;
  orderModalLastText: string;
  orderModalPrepaymentHint: string;
  orderModalPrepaymentAccountData: {
    payee: string;
    iban: string;
    bic: string;
    bank: string;
  };
}
export const OrderConfirmationModal: React.FC<IOrderConfirmationModal> = ({
  order,
  deliveryTimeDE,
  deliveryTimeEU,
  orderModalImage,
  orderModalEmailText,
  orderModalLastText,
  orderModalPrepaymentHint,
  orderModalPrepaymentAccountData
}) => {
  const {
    getTitle
  } = useImageSEO();
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const {
    formatMessage: formatCartMessage
  } = useFormat({
    name: 'cart'
  });
  const {
    formatMessage: formatCheckoutMessage
  } = useFormat({
    name: 'checkout'
  });
  const costs = useMemo(() => mapCosts(order), [order]);
  const addressesMatch = () => {
    delete order.billingAddress?.addressId;
    delete order.shippingAddress?.addressId;
    return JSON.stringify(order.billingAddress) === JSON.stringify(order.shippingAddress);
  };
  const addressContent = (address: Address, addressType: string) => {
    return <div data-sentry-component="addressContent" data-sentry-source-file="index.tsx">
        <strong>
          {addressType === 'billing' ? formatCheckoutMessage({
          id: 'billingAddress',
          defaultMessage: 'Rechnungsadresse'
        }) + ':' : formatCheckoutMessage({
          id: 'shippingAddress',
          defaultMessage: 'Lieferadresse'
        }) + ':'}
        </strong>
        <br />
        {addressesMatch() && addressType === 'shipping' ? <span>
            {formatCheckoutMessage({
          id: 'sameAsBillingAddress',
          defaultMessage: 'Wie Rechnungsadresse'
        })}
          </span> : <>
            {address.company && <>
                <span>{address.company}</span>
                <br />
              </>}
            <span>
              {address.salutation.toLowerCase() === 'mr' ? formatMessage({
            id: 'gender.male',
            defaultMessage: 'Herr'
          }) : formatMessage({
            id: 'gender.female',
            defaultMessage: 'Frau'
          })}
            </span>
            {!address.company ? <br /> : <>&nbsp;</>}
            <span>
              {address.firstName} {address.lastName} {address.pOBox}
            </span>
            <br />
            <span>
              {address.streetName} {address.streetNumber}
            </span>
            <br />
            <span>
              {address.postalCode} {address.city}
            </span>
            <br />
            <span>{isoAlpha2Data[address.country] || address.country}</span>
          </>}
      </div>;
  };
  return <div className={styles.orderModal} data-sentry-component="OrderConfirmationModal" data-sentry-source-file="index.tsx">
      <Image src={orderModalImage.media.file} alt={getTitle(orderModalImage)} title={getTitle(orderModalImage)} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
      <Markdown text={orderModalEmailText} variables={{
      name: `${order.billingAddress?.salutation.toLowerCase() === 'mr' ? formatMessage({
        id: 'gender.male',
        defaultMessage: 'Herr'
      }) : formatMessage({
        id: 'gender.female',
        defaultMessage: 'Frau'
      })} ${order.billingAddress?.lastName}`,
      orderNumber: order.orderId,
      deliveryTime: `${order.shippingAddress?.country.toLowerCase() === 'de' ? deliveryTimeDE : deliveryTimeEU}`
    }} className={styles.markdown} data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />

      <div className={styles.order}>
        <strong>
          {formatCheckoutMessage({
          id: 'orderConfirmationOverviewMessage',
          defaultMessage: 'Ihre Bestellung in der Übersicht:'
        })}
        </strong>
        <div className={styles.orderAddresses}>
          {addressContent(order.billingAddress, 'billing')}
          {addressContent(order.shippingAddress, 'shipping')}
        </div>
        <div>
          {formatCheckoutMessage({
          id: 'orderPaymentMethod',
          defaultMessage: 'Zahlungsart:'
        })}
          &nbsp;
          {order?.usedPayment?.paymentMethodName}
        </div>
        <div className={styles.orderInfo}>
          <strong>
            {formatCheckoutMessage({
            id: 'orderConfirmationItemsMessage',
            defaultMessage: 'Ihre bestellten Artikel'
          })}
          </strong>
          <div className={styles.articleWrap}>
            <div>
              {formatCartMessage({
              id: 'article',
              defaultMessage: 'Artikel'
            })}
            </div>
            <div>
              {formatCartMessage({
              id: 'description',
              defaultMessage: 'Beschreibung'
            })}
            </div>
            <div className="text-center">
              {formatCartMessage({
              id: 'quantity',
              defaultMessage: 'Anzahl'
            })}
            </div>
            <div className="text-end">
              {formatCartMessage({
              id: 'orderTotal',
              defaultMessage: 'Gesamt'
            })}
            </div>
          </div>
          <div>
            {order.lineItems?.map((lineItem, index) => <div className={styles.articleWrap} key={`line-item-${index}`}>
                {lineItem.variant?.images && <Image src={lineItem.variant.images[0]} width={100} alt={lineItem.name} title={lineItem.name} />}
                <div className="flex flex-col">
                  <strong>
                    {lineItem.name}, {lineItem.variant.attributes?.Sizing}
                  </strong>
                  <span>
                    {formatCartMessage({
                  id: 'articleNumber',
                  defaultMessage: 'Art. Nr.'
                })}{' '}
                    {lineItem.variant.sku}
                  </span>
                  <span className="flex items-center">
                    (
                    {formatCheckoutMessage({
                  id: 'orderConfirmationOnePiece',
                  defaultMessage: '1Stk. ='
                })}
                    <Price price={lineItem.price} discountedPrice={lineItem.discountedPrice} className={styles.price} isBold showCurrencySymbol />
                    )
                  </span>
                  <div className={styles.itemDiscountDescriptionWrap}>
                    {lineItem.discountTexts.map(discountDescription => <span key={discountDescription} className={styles.itemDiscountDescription}>
                        {discountDescription}
                      </span>)}
                  </div>
                </div>
                <div className="text-center">{lineItem.count}</div>
                <Price price={lineItem.price} discountedPrice={lineItem.totalPrice} count={lineItem.count} className={classNames(styles.price, styles.priceInfo)} isBold showCurrencySymbol />
              </div>)}
          </div>
          <div className={styles.orderSubTotal}>
            <div className={styles.orderSum}>
              <div className={styles.summaryRow}>
                <strong className={styles.summaryLabel}>
                  {formatCheckoutMessage({
                  id: 'subtotal',
                  defaultMessage: 'Zwischensumme'
                })}
                </strong>
                <Price price={costs.subtotal} className={styles.price} isBold showCurrencySymbol data-sentry-element="Price" data-sentry-source-file="index.tsx" />
              </div>
            </div>
          </div>
          <div className={styles.orderSum}>
            <div className={styles.summaryRow}>
              <span className={styles.summaryLabel}>
                {formatCheckoutMessage({
                id: 'orderConfirmationShippingCost',
                defaultMessage: 'Service- & Versandkosten'
              })}
              </span>
              {costs.shipping.centAmount === 0 ? <span className="text-success text-sm-bold">
                  {formatCheckoutMessage({
                id: 'freeShipping',
                defaultMessage: 'kostenlos'
              })}
                </span> : <Price price={costs.shipping} className={styles.price} showCurrencySymbol />}
            </div>
            {order?.discountCodes?.map(discountCode => <div key={`discountCode-${discountCode.code}`} className={styles.summaryRow}>
                <span className={styles.summaryLabel}>{discountCode.description ?? discountCode.name}</span>
              </div>)}
            {order?.customLineItems?.map(lineItem => <div key={`discountCode-${lineItem.lineItemId}`} className={styles.summaryRow}>
                <span className={styles.summaryLabel}>
                  {lineItem.type?.length > 0 ? lineItem.type : formatCartMessage({
                id: 'voucher',
                defaultMessage: 'Gutschein'
              })}
                </span>
                {lineItem.totalPrice.centAmount !== 0 && <Price price={lineItem.totalPrice || {}} className={styles.price} showCurrencySymbol />}
              </div>)}
            <div className={styles.summaryRow}>
              <strong className={styles.summaryLabel}>
                {formatCartMessage({
                id: 'totalSum',
                defaultMessage: 'Gesamtsumme'
              })}
              </strong>
              <Price price={costs.total || {}} isBold className={styles.price} showCurrencySymbol data-sentry-element="Price" data-sentry-source-file="index.tsx" />
            </div>
            {costs.savedPrice?.centAmount > 0 && <div className={styles.summaryRow}>
                <span className={classNames(styles.savedPrice, styles.summaryLabel)}>
                  {formatMessage({
                id: 'youSaved',
                defaultMessage: 'Sie sparen'
              })}
                </span>
                <Price price={costs.savedPrice || {}} className={classNames(styles.price, styles.savedPrice)} showCurrencySymbol />
              </div>}
          </div>
          {formatCheckoutMessage({
          id: 'orderConfirmationShippingCostNote',
          defaultMessage: 'inkl. gesetzl. MwSt. und Service- & Versandkosten'
        })}
        </div>
      </div>

      {order.usedPayment?.paymentMethod === 'prepayment' && <>
          <Markdown text={orderModalPrepaymentHint} className={styles.markdown} />
          <div className={styles.bankAccountInformation}>
            <div className={styles.prepaymentDataRow}>
              <span>
                {formatMessage({
              id: 'payee',
              defaultMessage: 'Zahlungsempfänger'
            })}
                :
              </span>
              <span>{orderModalPrepaymentAccountData.payee}</span>
            </div>
            <div className={styles.prepaymentDataRow}>
              <span>
                {formatMessage({
              id: 'iban',
              defaultMessage: 'IBAN'
            })}
                :
              </span>
              <span>{orderModalPrepaymentAccountData.iban}</span>
            </div>
            <div className={styles.prepaymentDataRow}>
              <span>
                {formatMessage({
              id: 'bic',
              defaultMessage: 'BIC'
            })}
                :
              </span>
              <span>{orderModalPrepaymentAccountData.bic}</span>
            </div>
            <div className={styles.prepaymentDataRow}>
              <span>
                {formatMessage({
              id: 'bank',
              defaultMessage: 'Bank'
            })}
                :
              </span>
              <span>{orderModalPrepaymentAccountData.bank}</span>
            </div>
            <div className={styles.prepaymentDataRow}>
              <span>
                {formatMessage({
              id: 'reference',
              defaultMessage: 'Verwendungszweck'
            })}
                :
              </span>
              <span>{order.orderId}</span>
            </div>
          </div>
        </>}

      <Markdown text={orderModalLastText} className={styles.markdown} data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
    </div>;
};