import { useAccount } from '@frontastic-engbers/lib';
import { AccountMyData } from '@engbers/components/my-account/my-data';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
interface IMyAccountDataData {
  isFullWidth?: boolean;
  myDataHeadline?: string;
  textSize?: number;
  myDataIcon?: any;
  myDataSubheadline?: string;
  myDataAccessDataTitle?: string;
  myDataBillingAddressTitle?: string;
  myDataChangeEmailTitle?: string;
  myDataEmailTooltip?: string;
  doiNotificationLink?: IFTLinkReference | IFTPageFolderReference;
  myDataBirthdayMarkdown?: string;
}
interface IMyAccountDataTasticComponent {
  data: IMyAccountDataData;
}
export const MyAccountDataTastic: React.FC<IMyAccountDataTasticComponent> = ({
  data
}) => {
  const {
    loggedIn
  } = useAccount();
  if (!loggedIn) {
    return null;
  }
  const myDataProps = {
    myDataHeadline: data.myDataHeadline,
    textSize: data.textSize,
    myDataIcon: data.myDataIcon,
    myDataSubheadline: data.myDataSubheadline,
    myDataChangeEmailTitle: data.myDataChangeEmailTitle,
    myDataAccessDataTitle: data.myDataAccessDataTitle,
    myDataBillingAddressTitle: data.myDataBillingAddressTitle,
    myDataEmailTooltip: data.myDataEmailTooltip,
    myDataBirthdayMarkdown: data.myDataBirthdayMarkdown,
    doiNotificationLink: data.doiNotificationLink
  };
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>
        <AccountMyData props={myDataProps} />
      </FullPageWidthWrapper>;
  }
  return <AccountMyData props={myDataProps} data-sentry-element="AccountMyData" data-sentry-component="MyAccountDataTastic" data-sentry-source-file="index.tsx" />;
};