import { Account } from '@frontastic-engbers/types/account/Account';

const pwaUserPaths = ['/mein-konto/meine-gutscheine'];
export const accountAuthPaths = [
  '/mein-konto',
  '/mein-konto/meine-adressen',
  '/mein-konto/bestellhistorie',
  '/mein-konto/mein-newsletter',
  '/mein-konto/meine-newsletter',
  '/mein-konto/engberscard',
  '/mein-konto/mein-guthaben',
  ...pwaUserPaths,
];

export const isAccountAuthPath = (path: string) => accountAuthPaths.includes(path);
export const isPwaUserPath = (path: string) => pwaUserPaths.includes(path);
export const isPwaUser = (account: Account) => !!account?.custom?.fields?.PWAInstalled;
