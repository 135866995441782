import styles from './newsletter-signup-unsub-modal.module.css';
import { InputRadioGroup } from '@engbers/components/shopmacher-ui/input-radio-group';
import { useCallback, useMemo, useState } from 'react';
import { Button, Block } from '@engbers/components';
export const NewsletterSignupUnsubModal = ({
  onClose,
  updateSettings,
  identifier,
  frequencyOptions,
  labels
}) => {
  const [selectedItem, setSelectedItem] = useState('weekly');
  const options = useMemo(() => {
    const results = [];
    if (frequencyOptions.weekly) {
      results.push({
        label: labels.modalFrequencyButtonWeeklyLabel,
        id: 'weekly'
      });
    }
    if (frequencyOptions.monthly) {
      results.push({
        label: labels.modalFrequencyButtonMonthlyLabel,
        id: 'monthly'
      });
    }
    results.push({
      label: labels.modalFrequencyButtonUnsubscribeLabel,
      id: 'unsubscribe'
    });
    return results;
  }, [frequencyOptions, labels]);
  const onSubmit = useCallback(() => {
    if (selectedItem === 'weekly') {
      updateSettings({
        [identifier]: false,
        ...(frequencyOptions.monthly ? {
          [frequencyOptions.monthly]: false
        } : {}),
        ...(frequencyOptions.weekly ? {
          [frequencyOptions.weekly]: true
        } : {})
      });
    }
    if (selectedItem === 'monthly') {
      updateSettings({
        [identifier]: false,
        ...(frequencyOptions.monthly ? {
          [frequencyOptions.monthly]: true
        } : {}),
        ...(frequencyOptions.weekly ? {
          [frequencyOptions.weekly]: false
        } : {})
      });
    }
    if (selectedItem === 'unsubscribe') {
      updateSettings({
        ...(frequencyOptions.monthly ? {
          [frequencyOptions.monthly]: false
        } : {}),
        ...(frequencyOptions.weekly ? {
          [frequencyOptions.weekly]: false
        } : {}),
        [identifier]: false
      });
    }
    onClose();
  }, [onClose, updateSettings, identifier, frequencyOptions, selectedItem]);
  return <div data-sentry-component="NewsletterSignupUnsubModal" data-sentry-source-file="index.tsx">
      <Block data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <div className={styles.description}>{labels.modalFrequencyDescription}</div>
      </Block>
      <div className={styles.radioGroup}>
        <InputRadioGroup size="small" activeId={selectedItem} onChange={id => {
        setSelectedItem(id);
      }} options={options} data-sentry-element="InputRadioGroup" data-sentry-source-file="index.tsx" />
      </div>

      <div className={styles.buttonRow}>
        <Button hasIcon={false} size="small" type="cta" onClick={onSubmit} label={labels.modalFrequencyButtonConfirmLabel} data-sentry-element="Button" data-sentry-source-file="index.tsx" />

        <Button hasIcon={false} size="small" textColor="#879097" color="#879097" type="primary" onClick={onClose} label={labels.modalFrequencyButtonCancelLabel} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
      </div>
    </div>;
};