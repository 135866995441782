import classNames from "classnames";
import styles from "./typography.module.scss";
import { TColor } from "@frontastic-engbers/types/engbers-custom";
export interface ITypography {
  size?: "xs" | "base" | "l" | "xl" | "xxl";
  weight?: "regular" | "semi-bold" | "bold";
  color?: TColor;
  transform?: "uppercase" | "normal";
  tag?: "span" | "div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  // Please always try to use the variants instead of the custom size
  // Engbers specifically asked for these variants
  variant?: "text-xs" | "text-sm" | "text-base" | "input" | "link" | "heading-5" | "heading-4" | "heading-3" | "heading-2" | "heading-1";
}
export const Typography: React.FC<ITypography> = ({
  children,
  size = "base",
  weight = "regular",
  color,
  transform,
  tag,
  variant
}) => {
  const Tag = tag || `span`;
  return <Tag className={classNames(styles.base, {
    [styles[`size--${size}`]]: !!size,
    [styles[`weight--${weight}`]]: !!weight,
    [styles[`color--${color}`]]: !!color,
    [styles[`transform--${transform}`]]: !!transform,
    [styles[`variant--${variant}`]]: !!variant
  })} data-sentry-element="Tag" data-sentry-component="Typography" data-sentry-source-file="index.tsx">
            {children}
        </Tag>;
};