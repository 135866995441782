import React from 'react';
import classnames from 'classnames';
import { UseSortByProps } from 'react-instantsearch-core';
import { CustomDropdown } from '@engbers/components';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import styles from './facets.module.scss';
interface ISortFacet {
  resultsIndexName: string;
  className?: string;
  isMobile?: boolean;
  onClick?: () => void;
  currentRefinement: string;
  options: {
    label: string;
    value: string;
  }[];
  refine: (value: string) => void;
}
const SortFacet: React.FC<UseSortByProps & ISortFacet> = props => {
  const {
    resultsIndexName,
    className,
    isMobile,
    onClick,
    currentRefinement,
    options,
    refine
  } = props;
  const getCurrentRefinementLabel = options.find(option => option.value === currentRefinement)?.label || '';
  const handleSortSelection = (value: string) => {
    refine(value);
    new TagManager().customEvent('sorting', {
      value: value
    }).executePush();
    if (typeof onClick === 'function') {
      onClick();
    }
  };
  if (isMobile) {
    return <>
        {options.map(option => <button key={`value--${option.label}`} className={classnames(styles.sortByValue, option.value === currentRefinement ? styles.isActive : undefined)} onClick={() => handleSortSelection(option.value)}>
            {option.label}
          </button>)}
      </>;
  }
  return <div data-sentry-component="SortFacet" data-sentry-source-file="sort.tsx">
      <CustomDropdown className={className} items={options} defaultValue={getCurrentRefinementLabel} onClick={handleSortSelection} isFlyoutContent closeOnBackdrop isLoading={currentRefinement !== resultsIndexName} data-sentry-element="CustomDropdown" data-sentry-source-file="sort.tsx" />
    </div>;
};
export default SortFacet;